import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { User } from '../../../models/user';
import { Router, ActivatedRoute } from '@angular/router';
import { ROUTE_TRANSITION } from '../../../app.animation';
import { AuthService } from "../../../services/auth.service";
import { ApiService } from "../../../services/api.service";
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ForgotPasswordComponent } from '../../forgot-password/forgot-password.component';

@Component({
  selector: 'vr-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  showCoupon: boolean = false;
  couponExists: boolean = false;
  coupon: string;
  newUser: any; // current user
  isCurrentUser: boolean = false; // true if current detail is for current user
  errorPassword: boolean = false; // true if password set is wrong
  errorRegEx: boolean = false; // true if password needs to match
  loading: boolean = true; // true if script is loading something
  regEx: any;
  hide: boolean = true;
  accept: any = {
    privacy: false
  };

  constructor(
    private auth: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private api: ApiService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.newUser = {};
    this.activatedRoute.queryParams.subscribe(params => {
      let ref = params['referrer'];
      localStorage.setItem('referrer', ref);
    });
  }

  /**
   * Check inserted pwd
   */
  checkPasswords() {
    this.regEx = {
      charNum: false,
      lowChar: false,
      hiChar: false,
      number: false
    };


    var regexp = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/);
    var test = regexp.test(this.newUser.password);

    if (!test) {
      this.errorRegEx = true;
    } else {
      this.errorRegEx = false;
    }

    //regex for charNumber
    regexp = new RegExp(/^.{8,}/);
    this.regEx.charNum = regexp.test(this.newUser.password);

    //regex for low character
    regexp = new RegExp(/^(.*[a-z])/);
    this.regEx.lowChar = regexp.test(this.newUser.password);


    //regex for high character
    regexp = new RegExp(/^(.*[A-Z])/);
    this.regEx.hiChar = regexp.test(this.newUser.password);


    //regex for number
    regexp = new RegExp(/^(.*[0-9])/);
    this.regEx.number = regexp.test(this.newUser.password);

  }

  save() {
    if (localStorage.getItem("referrer")) {
      this.newUser.referrer = localStorage.getItem("referrer");
    }
    this.api.signUp(this.newUser).subscribe(
      data => {

        this.toastr.success('', 'Dati salvati correttamente');

        if (this.couponExists) {
          this.loginAndRedeem();
        } else {
          this.login();
        }
      },
      error => {
        switch (error.status) {
          case 500:
            this.toastr.error('<i>Se il problema persiste contattare il supporto.</i>', 'C\'è stato qualche problema');
            break;

          case 403:
            this.toastr.error('<i>Sembra che il tuo account non disponga dei permessi per effettuare questa operazione.</i>', 'Permessi insuffucenti');
            break;

          case 400:
            if (error.error.message == "email-already-exists") {

              this.toastr.error('<i>Indirizzo email appartiene ad un altro utente.</i>', 'Dati errati');
            } else {
              this.toastr.error('<i>I dati inseriti non sono validi.</i>', 'Dati errati');
            }
            break;

          default:
            break;
        }
      });
  }

  checkCoupon() {
    this.api.checkCoupon(this.coupon).subscribe(
      data => {
        this.couponExists = true;
        this.toastr.success('', 'Coupon valido!');
      }, error => {
        this.couponExists = false;
        this.toastr.error('', 'Coupon invalido!');
      });
  }

  login(): void {

    this.auth.login(this.newUser.email, this.newUser.password)
      .then((result) => {
        localStorage.setItem("User", JSON.stringify(result));
        this.auth.getToken()
          .then((token) => {
            this.toastr.success('', 'Accesso eseguito con successo');
            localStorage.setItem("JWT_TOKEN_ELEADS", String(token));
            this.router.navigate(['/home']);
          });
      })
      .catch((error) => {
        this.toastr.error('<i>Controlla i dati inseriti</i>', 'Email o password invalidi');
      });

  }


  loginAndRedeem(): void {

    this.auth.login(this.newUser.email, this.newUser.password)
      .then((result) => {
        localStorage.setItem("User", JSON.stringify(result));
        this.auth.getToken()
          .then((token) => {
            this.toastr.success('', 'Accesso eseguito con successo');
            localStorage.setItem("JWT_TOKEN_ELEADS", String(token));
            this.api.redeemCoupon(this.coupon).subscribe(
              data => {
                this.toastr.success('', 'Coupon riscattato!');

                this.router.navigate(['/home']);
              }, error => {
                this.toastr.error('', 'C\'è stato un errore nel riscattare il coupon');

                this.router.navigate(['/home']);
              }
            )
          });
      })
      .catch((error) => {
        this.toastr.error('<i>Controlla i dati inseriti</i>', 'Email o password invalidi');
      });

  }

}
