import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../../services/api.service'
import { ToastrService } from 'ngx-toastr';
import { Lead } from '../../../models/lead';
import { ErrorHandlerService } from '../../../services/error-handler.service';


@Component({
  selector: 'vr-nlt-sms-leads',
  templateUrl: './nlt-sms-leads.component.html',
  styleUrls: ['./nlt-sms-leads.component.scss']
})
export class NltSmsLeadsComponent implements OnInit {
  leads: Lead[];
  perPage = 100;
  pageNumber = 0;
  loading:boolean = true;
  params: any;

  constructor(
    private api: ApiService,
    private toastr: ToastrService,
    private ref: ChangeDetectorRef,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit() {
    this.params = {
      perPage: this.perPage,
      pageNumber: this.pageNumber
    }
    this.search(this.params);
  }

  search(leadsParams) {
    
    this.loading = true;
    this.api.getSMSLeadsNLT(leadsParams).subscribe(
      data => {
        this.leads = data as Lead[];
        this.loading = false;
        this.ref.detectChanges();
      },
      error => {
        this.errorHandler.handleError(error);
        this.loading = false;
      });

  }

  /**
   * Filter on filter passed
   */
   filter(action): void {
    this.params = {
      perPage: this.params.perPage,
      pageNumber: this.params.pageNumber
    }
    Object.assign(this.params,action.filters);
    this.search(this.params);
  }

  getAction(action) {
    
    switch (action) {
      case 'nextPage':
        this.params.pageNumber++;
        this.search(this.params);
        break;

      case 'prevPage':
        this.params.pageNumber--;
        this.search(this.params);
        break;
      case "showResults":
        this.search(this.params);
        break;
      default:
        
        break;
    }

    
  }

}
