import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api.service'
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { Lead } from '../../models/lead';
import { User } from '../../models/user';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'vr-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  User: User; // current user
  CurrentUser: User; // current user
  isCurrentUser: boolean = false; // true if current detail is for current user
  errorPassword: boolean = false; // true if password set is wrong
  errorRegEx: boolean = false; // true if password needs to match
  loading: boolean = true; // true if script is loading something
  password: any = { password: '', confirmPassword: '' };
  regEx: any;
  hide: boolean = true;
  pageNumber: number = 0;
  movement: any = [];
  showNext: boolean = true;
  showPrev: boolean = false;

  constructor(
    private api: ApiService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private errorHandler: ErrorHandlerService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.api.getUser()
      .subscribe(user => {
        this.CurrentUser = user;
        this.changeDetector.detectChanges();
        this.loadHistory();
      }, error => {
        this.loading = false;
        this.errorHandler.handleError(error);
        this.changeDetector.detectChanges();
      });
  }


  loadHistory() {
    var data = {
      perPage: 50,
      pageNumber: this.pageNumber
    }

    if (this.pageNumber > 0) {
      this.showPrev = true;
    } else {
      this.showPrev = false;
    }
    this.api.getHistory(this.CurrentUser.key, data)
      .subscribe(data => {
        this.movement = data;
        this.changeDetector.detectChanges();

        if (data.length == 50) {
          this.showNext = true;
        } else {
          this.showNext = false;
        }

      },
        error => {
          this.errorHandler.handleError(error);
        });
  }

  nextPage() {
    this.loadHistory();
    this.pageNumber++;
    this.loading = true;
  }

  prevPage() {
    this.loadHistory();
    this.pageNumber--;
    this.loading = true;
  }


  getAction(action) {
    switch (action) {
      case "nextPage":
        this.nextPage();
        break;

      case "prevPage":
        this.prevPage();
        break;

      default:
        break;
    }
  }

}
