import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service'
import { User } from '../../models/user';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ConfirmDialogComponent } from '../../core/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';

import { ErrorHandlerService } from '../../services/error-handler.service';

@Component({
  selector: 'vr-users',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {

  users: User[]; // the user list
  perPage = 50; // pp conf
  pageNumber = 0; // pn conf

  showNext = false; // true if we can go to next page
  showPrev = false; // true if we can go to the previous one
  query = '';
  order = 'asc';
  sort = 'account';

  loading: boolean = true; // true if the script loading something, set to true to show spinner
  params: any;

  constructor(
    private api: ApiService,
    private ref: ChangeDetectorRef,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private toastr: ToastrService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.params = {
      perPage: this.perPage,
      pageNumber: this.pageNumber,
      query: this.query
    }

    this.search(this.params);
  }

  /**
   * search for users
   * @param params the pagination params
   */
  search(params: any): void {
    this.loading = true; // we are loading
    this.ref.detectChanges();
    this.api.getUsers(params).subscribe(
      data => {
        this.users = data as User[];
        this.onAfterSearch();
      },
      error => {
        this.errorHandler.handleError(error);
        this.onAfterSearch();
      });
  }

  filter(){
    this.params = {
      perPage: this.perPage,
      pageNumber: this.pageNumber,
      query: this.query,
      sort: this.sort,
      order: this.order,
    }

    this.search(this.params);
  }

  /**
   * handle after search behaviours
   */
  onAfterSearch(): void {
    this.loading = false; // stop loading

    // if no users is returned
    if (!this.users || !this.users.length) {
      return;
    }

    // TODO: this behaviour have some bugs (e.g. user.length % perPage = 0)
    if (this.users.length < this.perPage) {
      this.showNext = false;
    } else {
      this.showNext = true;
    }

    // cannot go prev
    if (this.params.pageNumber === 0) {
      this.showPrev = false;
    } else {
      this.showPrev = true;
    }
    // let the graphic detect changes
    this.ref.detectChanges();
  }

  /**
   * Open a single user detail
   * @param user the single user
   */
  singleUser(user: User): void {
    if (!user.key) {
      return;
    }

    this.router.navigate(['home', 'user-profile', user.key]);
  }
  /**
   * Delete a user
   * @param user the user to delete
   */
  deleteUser(user: User): void {
    if (!user.key) {
      return;
    }
    this.loading = true;
    // if user key exists then delete it
    this.api.deleteUser(user.key)
      .subscribe(
        data => {
          this.toastr.success('', 'Utente eliminato correttamente');
          // let the graphic detect changes
          this.search(this.params);
        },
        error => {
          this.errorHandler.handleError(error);
          this.search(this.params);
        })
  }

  /**
   * Perform an action binded to another component
   * @param action the action to perform
   */
  getAction(action) {
    switch (action) {
      case "nextPage":
        if (this.loading) {
          return;
        }
        this.params.pageNumber++;
        this.search(this.params);
        break;

      case "prevPage":
        if (this.loading) {
          return;
        }
        this.params.pageNumber--;
        this.search(this.params);
        break;

      default:
        break;
    }

  }

  /**
   * Open an arbitrary confirm dialog according with button action data
   * @param $event the event triggered
   */
  openConfirmDialog($event: Event, user: User): void {
    const dialogConfig = new MatDialogConfig();
    let target: HTMLElement = $event.target as HTMLElement || new HTMLElement(); // the dialog target

    if (target.getAttribute('type') !== 'button' && target.parentElement.getAttribute('type') === 'button') {
      // if current target is not a button check for parent, the target MUST be a button
      target = target.parentElement;
    }

    const title: string = target.getAttribute('modal-title') || 'Conferma';
    const text: string = target.getAttribute('modal-text') || 'Si desidera procedere con l\' operazione?';

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    dialogConfig.data = { title, text };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          // perform action
          this.deleteUser(user);
        }
      });
  }

}
