import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef } from "@angular/material";
import { ToastrService } from 'ngx-toastr';
import { AuthService } from "../../services/auth.service";

@Component({
  selector: 'vr-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  email: string;

  constructor(
    private dialogRef: MatDialogRef<ForgotPasswordComponent>,
    private auth: AuthService,
    private toastr: ToastrService
  ) { }


  ngOnInit() { }

  /**
   * send pwd reset email and check for errors
   */
  sendEmail() {
    this.auth.sendPasswordResetEmail(this.email)
      .then(_ => {
        if (this.dialogRef) this.dialogRef.close(true); // email sended with success
      })
      .catch(error => { 
        // something went wrong
        if (error.code === 'auth/user-not-found') {
          this.toastr.error('Questa email non corrisponde ad alcun utente registrato.', 'Utente inesistente');
        } else {
          this.toastr.error('Si è verificato un errore', '');
          if (this.dialogRef) this.dialogRef.close(false);
        }
      })
  }
}
