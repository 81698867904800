import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, Input } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { LeadDecodeService } from '../../services/lead-decode.service';
import { ErrorHandlerService } from '../../services/error-handler.service';

@Component({
  selector: 'vr-lead-filters',
  templateUrl: './lead-filters.component.html',
  styleUrls: ['./lead-filters.component.scss']
})
export class LeadFiltersComponent implements OnInit {
  @Output() action = new EventEmitter<any>();
  @Input() finance: boolean = false;
  @Input() nlt: boolean = false;
  onFilter: boolean = false;
  affiliates: any = [];
  financeBuyers: any = [];
  filters = {
    query: '',
    sort: '',
    field: '',
    order: '',
    affiliate: '',
    dateStart: '',
    dateEnd: '',
    insurance: '',
    job: '',
    deleteMotivation: '',
    dateSel: ''
  }

  jobFinance: any = []
  jobInsurance: any = []


  constructor(
    private api: ApiService,
    private leadDecoder: LeadDecodeService,
    private cd: ChangeDetectorRef,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit() {

    this.jobFinance = this.leadDecoder.getJobsFinance();
    this.jobInsurance = this.leadDecoder.getJobs();
    this.api.getAffiliates()
      .subscribe(data => {
        this.affiliates = data;
        this.cd.detectChanges();
      }, error => {
        this.errorHandler.handleError(error);
      })

    if (this.finance) {
      this.api.getFinanceBuyer()
        .subscribe(data => {
          this.financeBuyers = data;
          this.cd.detectChanges();
        }, error => {
          this.errorHandler.handleError(error);
        })
    }

    if (this.nlt) {
      this.api.getNLTBuyer()
        .subscribe(data => {
          this.financeBuyers = data;
          this.cd.detectChanges();
        }, error => {
          this.errorHandler.handleError(error);
        })
    }
  }

  showFilters(): void {
    this.onFilter = true;
  }

  hideFilters(): void {
    this.onFilter = false;
  }

  applyFilters(): void {
    if (!this.action) {
      console.error('Cannot apply filters if any action is set!');
      return;
    }
    let tempParams = {};
    // use only valorized filters
    for (let f in this.filters) {
      // f is the filter name
      if (this.filters[f] != '') {
        if (f == "dateStart" || f == "dateEnd") {
          tempParams[f] = new Date(this.filters[f]).toLocaleDateString("it-IT").replace(/[\/]/ig, '-');
        } else {
          tempParams[f] = this.filters[f];
        }
      }
    }
    this.action.emit({ action: 'filtersApplied', filters: tempParams });
  }

  clearFilters(): void {
    this.filters = {
      query: '',
      sort: '',
      order: '',
      field: '',
      affiliate: '',
      dateStart: '',
      dateEnd: '',
      insurance: '',
      job: '',
      deleteMotivation: '',
      dateSel: ''
    }
    this.action.emit({ action: 'filtersApplied', filters: {} });
  }
}