import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ApiService } from '../../services/api.service'

@Component({
  selector: 'vr-modal-coupon',
  templateUrl: './modal-coupon.component.html',
  styleUrls: ['./modal-coupon.component.scss']
})
export class ModalCouponComponent implements OnInit {
  id: string;
  coupon: any;
  loading: boolean = true;
  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ModalCouponComponent>,
    private toastr: ToastrService,
    private errorHandler: ErrorHandlerService,
    private api: ApiService,
    @Inject(MAT_DIALOG_DATA) data) {
    if (data.id) {
      this.id = data.id;
    }
  }

  ngOnInit() {
    this.coupon = {};
    this.loading = false;
    if (this.id) {
      this.getCoupon();
    }
  }

  getCoupon() {
    this.api.getCoupon(this.id).subscribe(
      data => {
        this.coupon = data;
      }, error => {
        this.errorHandler.handleError(error);
      }
    )
  }

  save() {
    if (this.id) {
      this.updateCoupon();
    } else {
      this.saveCoupon();
    }
  }

  saveCoupon() {
    this.api.postCoupon(this.coupon).subscribe(
      data => {
        this.toastr.success("", "Dati salvati correttamente");
        this.dialogRef.close(true);
      }, error => {
        this.errorHandler.handleError(error);
      }
    )
  }

  updateCoupon() {
    this.api.putCoupon(this.coupon).subscribe(
      data => {
        this.toastr.success("", "Dati salvati correttamente");
        this.dialogRef.close(true);
      }, error => {
        this.errorHandler.handleError(error);
      }
    )
  }

  close() {
    this.dialogRef.close(false);
  }




}
