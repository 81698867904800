import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class CarLeadsCartService {
  cart: any = []
  cartChange: EventEmitter<Array<string>> = new EventEmitter();
  constructor() { }

  addLead(leadKey) {
    this.cart.push(leadKey);
    this.cartChange.emit(this.cart);
  }

  cartChangeEmitter() {
    return this.cartChange;
  }

  getCart() {
    return this.cart;
  }

  removeLead(leadKey) {
    var index = this.cart.indexOf(leadKey);
    this.cart.splice(index, 1);
    this.cartChange.emit(this.cart);
  }

  includedInChart(leadKey) {
    return this.cart.includes(leadKey);
  }

  cleanCart() {
    this.cart = [];
    this.cartChange.emit(this.cart);
  }

}
