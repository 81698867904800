import { ApiService } from '../../services/api.service'
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'vr-pricing-table',
  templateUrl: './pricing-table.component.html',
  styleUrls: ['./pricing-table.component.scss']
})
export class PricingTableComponent implements OnInit {

  user: any = {};
  price: any = {};



  constructor(
    private api: ApiService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.api.getUser()
      .subscribe(data => {
        this.user = data;

        if (this.user.userType == "affiliate") {
          this.price=this.polizzeAffiliato;
        } else {
          this.price=this.polizzeBroker;
        }
        this.cd.detectChanges();

      });
  }


  polizzeBroker = [
    {
      name: "Polizza rimborso spese sanitarie",
      price: 30
    },
    {
      name: "Polizza caso morte",
      price: 30
    },
    {
      name: "Polizza infortuni",
      price: 24
    },
    {
      name: "Polizza casa",
      price: 18
    },
    {
      name: "Polizza Long Term Care",
      price: 30
    },
    {
      name: "Polizza caso vita",
      price: 30
    },
    {
      name: "Polizza dentistica",
      price: 9
    },
    {
      name: "Polizza responsabilità civile",
      price: 12
    },
    {
      name: "Polizza pensione integrativa",
      price: 30
    },
    {
      name: "Polizza rc professionale",
      price: 20
    },
    {
      name: "Polizza tutela legale",
      price: 12
    },
    {
      name: "Polizza viaggio",
      price: 12
    },
    {
      name: "Polizza key-man",
      price: 45
    },
    {
      name: "Polizza RC Auto",
      price: 3
    },
    {
      name: "Polizza sanitaria cani e gatti",
      price: 14
    }
  ]

  polizzeAffiliato = [
    {
      name: "Polizza rimborso spese sanitarie",
      price: 6
    },
    {
      name: "Polizza infortuni",
      price: 6
    },
    {
      name: "Polizza caso morte",
      price: 6
    },
    {
      name: "Polizza casa",
      price: 4
    },
    {
      name: "Polizza Long Term Care",
      price: 6
    },
    {
      name: "Polizza caso vita",
      price: 6
    },
    {
      name: "Polizza dentistica",
      price: 2
    },
    {
      name: "Polizza responsabilità civile",
      price: 4
    },
    {
      name: "Polizza pensione integrativa",
      price: 6
    },
    {
      name: "Polizza rc professionale",
      price: 6
    },
    {
      name: "Polizza tutela legale",
      price: 3
    },
    {
      name: "Polizza viaggio",
      price:3
    },
    {
      name: "Polizza key-man",
      price: 6
    },
    {
      name: "Polizza RC Auto",
      price: 2
    },
    {
      name: "Polizza sanitaria cani e gatti",
      price: 3
    }
  ]
  

}
