import { Component, OnInit, ChangeDetectorRef,  Input } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ErrorHandlerService } from '../../services/error-handler.service';

@Component({
  selector: 'vr-preferences-map',
  templateUrl: './preferences-map.component.html',
  styleUrls: ['./preferences-map.component.scss']
})
export class PreferencesMapComponent implements OnInit {
  markers: any = [];
  @Input() lead?: any = {};
  brokers: any = [];
  constructor(
    private changeDetector: ChangeDetectorRef,
    private api: ApiService,
    private error: ErrorHandlerService
  ) { }

  ngOnInit() {
    this.getMarkers();
  }

  getMarkers() {
    var data = {
      latitude: this.lead.latitude,
      longitude: this.lead.longitude
    };
    
    this.api.getAllPreferences(data).subscribe(
      values => {
        this.markers=values;
        
      this.changeDetector.detectChanges();
      }, errors => {
        this.error.handleError(errors)
      });
  }

 

}
