import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ApiService } from '../../../services/api.service'
import { LeadDecodeService } from '../../../services/lead-decode.service'
import { ErrorHandlerService } from '../../../services/error-handler.service'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'vr-modal-buyer',
  templateUrl: './modal-buyer.component.html',
  styleUrls: ['./modal-buyer.component.scss']
})
export class ModalBuyerComponent implements OnInit {

  objectKeys = Object.keys;
  buyer: any = {

  };
  edit: boolean = false;

  jobs: any = [];
  affiliates: any = [];

  pricingCessione: any = [];
  pricingPrestito: any = [];

  months = [
    {
      value: 0,
      label: "Gennaio"
    },
    {
      value: 1,
      label: "Febbraio"
    },
    {
      value: 2,
      label: "Marzo"
    },
    {
      value: 3,
      label: "Aprile"
    },
    {
      value: 4,
      label: "Maggio"
    },
    {
      value: 5,
      label: "Giugno"
    },
    {
      value: 6,
      label: "Luglio"
    },
    {
      value: 7,
      label: "Agosto"
    },
    {
      value: 8,
      label: "Settembre"
    },
    {
      value: 9,
      label: "Ottobre"
    },
    {
      value: 10,
      label: "Novembre"
    },
    {
      value: 11,
      label: "Dicembre"
    }
  ];


  constructor(
    private api: ApiService,
    private leadDecode: LeadDecodeService,
    private errorHandler: ErrorHandlerService,
    private toaster: ToastrService,
    private dialogRef: MatDialogRef<ModalBuyerComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    if (data) {
      this.buyer = data;
      this.edit = true;
    }



    if (!this.buyer.includedAreas) {
      this.buyer.includedAreas = []
    }

    if (this.buyer.pricing) {
      Object.keys(this.buyer.pricing.cessione).map(key => {
        var o = {
          key,
          value: this.buyer.pricing.cessione[key]
        }

        this.pricingCessione.push(o);

      })
      
      Object.keys(this.buyer.pricing.finanziamento).map(key => {
        var o = {
          key,
          value: this.buyer.pricing.finanziamento[key]
        }

        this.pricingPrestito.push(o);

      })
    }

    if (!this.buyer.privacies) {
      this.buyer.privacies = []
    }

    if (!this.buyer.excludedJobs) {
      this.buyer.excludedJobs = {
        cessione: [],
        finanziamento: [],
      }
    }

    this.api.getAffiliates().subscribe(data => {
      this.affiliates = data;
    })

  }

  add(prop, type?) {

    switch (prop) {
      case 'includedAreas':

        if (!this.buyer.includedAreas) {
          this.buyer.includedAreas = [''];
        } else {
          this.buyer.includedAreas.push('');
        }
        break;


      case 'excludedAffiliates':
        if (!this.buyer.excludedAffiliates) {
          this.buyer.excludedAffiliates = [''];
        } else {
          this.buyer.excludedAffiliates.push('');
        }
        break;

      case 'pricing':

        switch (type) {
          case 'finanziamento':
            this.pricingPrestito.push({ key: '', value: 0 })
            break;

          case 'cessione':
            this.pricingCessione.push({ key: '', value: 0 })
            break;
        }

        break;

      case 'privacies':
        if (!this.buyer.privacies) {
          this.buyer.privacies = [{}]
        } else {
          this.buyer.privacies.push({})
        }
        break;

      case 'campaigns':
        if (!this.buyer.campaigns) {
          this.buyer.campaigns = [{}]
        } else {
          this.buyer.campaigns.push({})
        }
        break;

      case 'excludedJobs':
        if (!this.buyer.excludedJobs[type]) {
          this.buyer.excludedJobs[type] = [''];
        } else {
          this.buyer.excludedJobs[type].push('');
        }
        break;
    }

  }



  remove(prop, type, index) {

    switch (prop) {
      case 'includedAreas':
        this.buyer.includedAreas.splice(index, 1);
        break;


      case 'excludedAffiliates':
        this.buyer.excludedAffiliates.splice(index, 1);
        break;

        case 'pricing':

        switch (type) {
          case 'finanziamento':
            this.pricingPrestito.splice(index, 1);
            break;

          case 'cessione':
            this.pricingCessione.splice(index, 1);
            break;
        }

        break;


      case 'privacies':
        this.buyer.includedAreas[type].splice(index, 1);
        break;

      case 'campaigns':
        this.buyer.campaigns.splice(index, 1);
        break;

      case 'excludedJobs':
        this.buyer.excludedJobs[type].splice(index, 1);
        break;
    }

  }

  ngOnInit() {
    this.jobs = this.leadDecode.getJobsFinanceInfo();
  }

  close() {
    this.dialogRef.close();
  }

  save() {

    this.pricingCessione.map( o => {

      this.buyer.pricing.cessione[o.key] = o.value;

    })

    this.pricingPrestito.map( o => {

      this.buyer.pricing.finanziamento[o.key] = o.value;

    })


    if (this.edit) {
      this.api.putFinanceBuyer(this.buyer.key, this.buyer)
        .subscribe(data => {
          this.toaster.success("Buyer modificato con successo");
          this.dialogRef.close(true);
        }, error => {
          this.errorHandler.handleError(error);
        })
    } else {
      this.api.postFinanceBuyer(this.buyer)
        .subscribe(data => {
          this.toaster.success("Buyer creato con successo");
          this.dialogRef.close(true);
        }, error => {
          this.errorHandler.handleError(error);
        })
    }
  }


  trackByFn(index: any, item: any) {
    return index;
  }

}
