import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { environment } from '../../environments/environment';

import { Observable } from 'rxjs';

@Injectable()
export class AuthService {
  user: Observable<firebase.User>;
  token: string;

  constructor(private firebaseAuth: AngularFireAuth) {
    this.user = firebaseAuth.authState;

  }

  isAuthenticated() {
    return new Promise((resolve, reject) => {
      this.firebaseAuth.authState.subscribe(res => {
        if (res && res.uid) {
          resolve(true)
        } else {
          resolve(false);
        }
      })
    })
  }

  login(email: string, password: string) {
    return this.firebaseAuth.auth.signInWithEmailAndPassword(email, password);
  }

  logout() {
    return this.firebaseAuth.auth.signOut();
  }

  getToken(reload: boolean = false) {
    return this.firebaseAuth.auth.currentUser.getIdToken(reload);
  }

  getUser(): any {
    return new Promise((resolve, reject) => {

      this.user = this.firebaseAuth.authState;
      this.user.subscribe(user => {
        resolve(user);
      });
    });
  }

  sendPasswordResetEmail(to: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.firebaseAuth.auth.sendPasswordResetEmail(to)
        .then(() => {
          resolve(true);
        }).catch((e) => {
          reject(e);
        })
    });
  }
}
