import { Component, OnInit, Input, Output, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorHandlerService } from '../../services/error-handler.service';

@Component({
  selector: 'vr-user-subscription',
  templateUrl: './user-subscription.component.html',
  styleUrls: ['./user-subscription.component.scss']
})
export class UserSubscriptionComponent implements OnInit {
  @Input() user: any;
  
  @Output() action = new EventEmitter<string>();

  premium: boolean = false;
  loading: boolean = true;

  constructor(
    private api: ApiService,
    private toastr: ToastrService,
    private errorHandler: ErrorHandlerService,
    private cd:ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.loading= false;
  }

  activate(){
    this.loading= true;
    this.api.addSubscription({type: 'premium-discounts'}).subscribe(
      data => {
        this.toastr.success('', 'Servizio Premium attivato!');
        this.user.plan="premium-discounts";
        this.loading= false;
        this.action.emit('activate');
        this.cd.detectChanges();
      }, error => {
        this.errorHandler.handleError(error);
        this.loading= false;
        this.cd.detectChanges();
      }
    )

   
  }

  discativate(){
    this.loading= true;
    this.api.deleteSubscriptions().subscribe(
      data => {
        this.toastr.warning('', 'Servizio Premium disattivato');
        this.user.plan="";
        this.loading= false;
        this.action.emit('deactivate');
        this.cd.detectChanges();
      }, error => {
        this.errorHandler.handleError(error);
        this.loading= false;
        this.cd.detectChanges();
      }
    )
  }

}
