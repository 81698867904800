import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ApiService} from '../../services/api.service';
import { Router} from '@angular/router';

@Component({
  selector: 'vr-lead-search',
  templateUrl: './lead-search.component.html',
  styleUrls: ['./lead-search.component.scss']
})
export class LeadSearchComponent implements OnInit {
  // @Output() setLeads = new EventEmitter<Lead>();
  leads: any;
  showData = false;
  setLeads;

  constructor(private apiService: ApiService,
              private router: Router) {
    this.leads = {
      perPage: 20,
      insurance: [],
      area: [
        {
          zipcode: '',
          distance: 0
        }
      ],
      job: [],
      age: [],
      gender: []
    }
  }

  ngOnInit() {
  }

  add() {
    this.leads.area.push({
      zipcode: '',
      distance: 0
    });
  }

  remove(index) {
    this.leads.area.splice(index, 1);
  }

  save() {
    this.apiService.setLeadSearch(this.leads)
      .subscribe(
        data => {
          this.showData = true;
            this.setLeads = data;
          //  this.setLeads.emit(data);
          //  this.router.navigate(['home', 'advanceSearch']);
        },
        error => {
          alert(error.message);
        })

  }


}
