import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { AuthService } from './services/auth.service';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(public auth: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    if (request.url.includes('signUp') || request.url.includes('coupon/check')) {
      return next.handle(request);
    } else {
      return from(this.auth.getToken()).pipe(mergeMap((token) => {

        if (token) {
          let JWT_TOKEN_ELEADS = String(token);
          request = request.clone({
            setHeaders: {
              token: JWT_TOKEN_ELEADS,
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache'
            }
          });
          return next.handle(request);
        }
        /*  else {
           this.auth.getToken(true)
             .then((token) => {
               this.auth.getToken()
                 .then((token) => {
                   let JWT_TOKEN_ELEADS = token;
                   localStorage.setItem("JWT_TOKEN_ELEADS", token);
                   request = request.clone({
                     setHeaders: {
                       token: JWT_TOKEN_ELEADS
                     }
                   });
                   return next.handle(request);
                 })
             })
             .catch(() => {
               localStorage.removeItem('JWT_TOKEN_ELEADS');
               //window.location.reload();
             })
         } */

      }));
    }
  }

}
;