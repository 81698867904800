import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ApiService } from '../../services/api.service'

@Component({
  selector: 'vr-modal-discount',
  templateUrl: './modal-discount.component.html',
  styleUrls: ['./modal-discount.component.scss']
})
export class ModalDiscountComponent implements OnInit {

  id: string;
  discount: any;
  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ModalDiscountComponent>,
    private toastr: ToastrService,
    private errorHandler: ErrorHandlerService,
    private api: ApiService,
    @Inject(MAT_DIALOG_DATA) data) {
    if (data.id) {
      this.id = data.id;
    }
  }

  ngOnInit() {
    this.discount = {};
    this.discount.price = {};
    if (this.id) {
      this.getDiscount();
    }
  }

  getDiscount() {
    this.api.getDiscount(this.id).subscribe(
      data => {
        this.discount = data;

        this.discount.dateStart = new Date(this.discount.dateStart);
        this.discount.dateEnd = new Date(this.discount.dateEnd)
      }, error => {
        this.errorHandler.handleError(error);
      }
    )
  }

  save() {
    if (this.id) {
      this.updateDiscount();
    } else {
      this.saveDiscount();
    }
  }

  saveDiscount() {
    this.discount.dateStart = new Date(this.discount.dateStart).toLocaleDateString("it-IT").replace(/[\/]/ig, '-');
    this.discount.dateEnd = new Date(this.discount.dateEnd).toLocaleDateString("it-IT").replace(/[\/]/ig, '-');
    this.api.postDiscount(this.discount).subscribe(
      data => {
        this.toastr.success("", "Dati salvati correttamente");
        this.dialogRef.close(true);
      }, error => {
        this.errorHandler.handleError(error);
      }
    )
  }

  updateDiscount() {
    this.discount.dateStart = new Date(this.discount.dateStart).toLocaleDateString("it-IT").replace(/[\/]/ig, '-');
    this.discount.dateEnd = new Date(this.discount.dateEnd).toLocaleDateString("it-IT").replace(/[\/]/ig, '-');
    this.api.putDiscount(this.discount, this.id).subscribe(
      data => {
        this.toastr.success("", "Dati salvati correttamente");
        this.dialogRef.close(true);
      }, error => {
        this.errorHandler.handleError(error);
      }
    )
  }

  close() {
    this.dialogRef.close(false);
  }

  insuranceOptions: any = [
    { price: 30, description: 'Polizza rimborso spese sanitarie', value: 'rimborso-spese-sanitarie' },
    { price: 24, description: 'Polizza infortuni', value: 'infortuni' },
    { price: 30, description: 'Polizza sulla vita', value: 'vita' },
    { price: 24, description: 'Polizza casa', value: 'casa' },
    { price: 9, description: 'Polizza dentistica', value: 'dentistica' },
    { price: 12, description: 'Polizza responsabilità capo famiglia', value: 'responsabilita-civile' },
    { price: 30, description: 'Pensione integrativa', value: 'pensione-integrativa' },
    { price: 20, description: 'Polizza rc professionale', value: 'rc-professionale' },
    { price: 12, description: 'Polizza tutela legale', value: 'tutela-legale' },
    { price: 12, description: 'Polizza viaggio', value: 'viaggio' },
    { price: 145, description: 'Polizza uomo chiave', value: 'key-man' },
    { price: 30, description: 'Polizza LTC', value: 'ltc' },
    { price: 14, description: 'Polizza cani e gatti', value: 'cani-gatti' },
    { price: 20, description: 'Polizza caso vita', value: 'caso-vita' }
  ];
}
