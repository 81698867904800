// https://alligator.io/angular/stripe-elements/

import {
  Component,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../services/api.service'
import { User } from '../../models/user';
import { Card } from '../../models/card';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ChargeCreditCardComponent } from '../charge-credit-card/charge-credit-card.component';

import { UserService } from '../../services/user.service';

declare var Stripe: any;

// [TODO] salvataggio carta ad hoc, mostrare dettaglio in modo semplice e dare la possibilità di modificarla, aggiungerla
@Component({
  selector: 'vr-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent implements AfterViewInit, OnDestroy {
  @ViewChild('cardInfo') cardInfo: ElementRef;

  @Output() HasCC = new EventEmitter();
  @Input() checkout: boolean; // true if component is used to checkout something
  @Input() user: User; // current user obj
  @Input() showChargeModal?: boolean = false; // true if component needs to show automatically the charge modal0
  @Input() hideControls?: boolean = false; // true if no controls needs to show up (controls : {input, button})

  card: Card;
  cardIsSet: boolean = false;
  cardElement: any;
  cardHandler = this.onChange.bind(this);
  error: string;
  editCard: boolean = false;

  stripe: any;
  elements: any;

  wait: boolean = true;

  cardNumberElement: any;
  cardExpiryElement: any;
  cardCvcElement: any;

  loading: boolean = true;

  constructor(
    private cd: ChangeDetectorRef,
    private api: ApiService,
    private errorHandler: ErrorHandlerService,
    private dialog: MatDialog,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.card = new Card(); // create an empty card element
    this.stripe = Stripe(environment.stripeToken); // use your test publishable key
    this.elements = this.stripe.elements(); // get stripe elements
    this.loadUserCreditCard(); // then load user credit card if exists

  }

  ngAfterViewInit() {
    if (this.checkout) {
      this.prepareCheckout(); // prepare component for checkout
    }
  }

  /**
   * Prepare component for checkout
   * Show a stripe form component instead of user credit card component
   * 
   * [TODO] Define how to pass the product to buy
   */
  prepareCheckout() {
    // (Note that this demo uses a wider set of styles than the guide below.)
    var style = {
      base: {
        color: '#32325d',
        lineHeight: '18px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    // Create an instance of the card Element.
    if (this.cardElement) {
      this.cardElement.unmount();
      this.cardElement.destroy();
    }

    this.cardElement = this.elements.create('card', { style: style, hidePostalCode: true });
    // Add an instance of the card Element into the `card-element` <div>.
    this.cardElement.mount('#card-element');

    // Handle real-time validation errors from the card Element.
    this.cardElement.addEventListener('change', function (event) {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    this.wait = false;
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    if (this.cardElement) {
      this.cardElement.removeEventListener('change', this.cardHandler);
      this.cardElement.destroy();
    }
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
  }

  loadUserCreditCard() {
    this.wait = true;
    this.cd.detectChanges();
    this.api.getUserCard().subscribe(
      data => {
        // empty data === no card set
        if (Object.keys(data).length == 0) {
          this.card = null;
          this.HasCC.emit(false);
        } else {
          this.card = data as Card;
          this.HasCC.emit(true);
        }

        this.wait = false;
        this.cd.detectChanges();
        // if openAddCredit is set to true than show the modal
        if (this.showChargeModal) {
          this.chargeCard();
        }
      },
      error => {
        this.errorHandler.handleError(error);
        this.card = null;
        this.wait = false;
        this.cd.detectChanges();
      });
  }

  save() {
    if (this.wait) {
      return;
    }
    this.wait = true;
    this.cd.detectChanges();
    //------------ new stripe integration 


    this.api.getUserCardIntent().subscribe(intent => {
      this.stripe.handleCardSetup(
        intent.client_secret,
        this.cardElement
      ).then((data) => {
        console.log("DATA",data);
        if (data.error) {
          this.errorHandler.handleError(data.error);
          this.wait = false;
          this.cd.detectChanges();
        } else if (data.setupIntent) {
          if (!this.card) {
            this.api.createUserCard(data.setupIntent.payment_method).subscribe(
              data => {
                this.onAfterSave(data);
                this.HasCC.emit(true);
              },
              error => this.errorHandler.handleError(error));
            this.editCard = false;
          } else {
            this.api.updateUserCard(data.setupIntent.payment_method).subscribe(
              data => {
                this.onAfterSave(data);
                this.HasCC.emit(true);
              },
              error => this.errorHandler.handleError(error));
          }
        }
        /* if (error) {
          this.errorHandler.handleError(error);
          this.wait = false;
          this.cd.detectChanges();
        } else if (token) {
          if (!this.card) {
            this.api.createUserCard().subscribe(
              data => {
                this.onAfterSave(data);
                this.HasCC.emit(true);
              },
              error => this.errorHandler.handleError(error));
            this.editCard = false;
          } else {
            this.api.updateUserCard().subscribe(
              data => {
                this.onAfterSave(data);
                this.HasCC.emit(true);
              },
              error => this.errorHandler.handleError(error));
          }
        } */
      });
    },
      error => this.errorHandler.handleError(error))






/*  -------------------------------- OLD STRIPE INTEGRATION

    this.stripe.createToken(this.cardElement).then(({ error, token }) => {
      if (error) {
        this.errorHandler.handleError(error);
        this.wait = false;
        this.cd.detectChanges();
      } else if (token) {
        if (!this.card) {
          this.api.createUserCard(token.id).subscribe(
            data => {
              this.onAfterSave(data);
              this.HasCC.emit(true);
            },
            error => this.errorHandler.handleError(error));
          this.editCard = false;
        } else {
          this.api.updateUserCard(token.id).subscribe(
            data => {
              this.onAfterSave(data);
              this.HasCC.emit(true);
            },
            error => this.errorHandler.handleError(error));
        }
      }
    });
 */  }

  abort() {
    this.wait = false;
    this.editCard = false;
    this.cd.detectChanges();
  }

  onAfterSave(data): void {
    this.editCard = false;
    this.wait = false;
    this.cd.detectChanges();
    this.loadUserCreditCard();
  }

  newCard(): void {
    this.editCard = true;
    this.wait = true;
    this.cd.detectChanges();
    this.prepareCheckout();
  }

  updateCard(): void {
    this.editCard = true;
    this.wait = true;
    this.cd.detectChanges();
    this.prepareCheckout();
  }

  chargeCard(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';

    dialogConfig.data = { stripe: this.stripe, card: this.cardElement };
    const dialogRef = this.dialog.open(ChargeCreditCardComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      // notify user service than something has changed
      if (data && data !== this.user.wallet) {
        this.user.wallet = this.user.wallet ? this.user.wallet + data : data;
      }

      this.userService.onChange(this.user);
    });
  }
}