import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarUserButtonComponent } from './toolbar-user-button/toolbar-user-button.component';
import { ToolbarBetaComponent } from './toolbar-beta/toolbar-beta.component';
import { MatButtonModule, MatIconModule, MatInputModule, MatMenuModule, MatRippleModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ScrollbarModule } from '../scrollbar/scrollbar.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UtilsModule } from '../utils/utils.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    ScrollbarModule,
    MatInputModule,
    MatMenuModule,
    UtilsModule,
    MatRippleModule
  ],
  declarations: [
    ToolbarUserButtonComponent,
    ToolbarBetaComponent
  ],
  exports: [
    ToolbarUserButtonComponent,
    ToolbarBetaComponent
  ]
})
export class ToolbarModule { }
