import { AfterViewInit, Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../services/auth.service'

@Component({
  selector: 'vr-toolbar-user-button',
  templateUrl: './toolbar-user-button.component.html',
  styleUrls: ['./toolbar-user-button.component.scss']
})
export class ToolbarUserButtonComponent implements OnInit, AfterViewInit {

  @Input() user:any;
  isOpen: boolean;

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.user = { name: "" };
  }

  ngAfterViewInit() {
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

  Clicked() {
    this.isOpen = false;
    
  }

  logout() {
    this.auth.logout().then(
      success => {
        localStorage.removeItem('JWT_TOKEN_ELEADS');
        localStorage.removeItem('UserType');
        this.router.navigate(['/']);

        //window.location.reload();
      }
    )
  }
}
