import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../models/user';
import { Lead } from 'app/models/lead';
@Injectable()
export class ApiService {
  api: string;
  apiForm: string;
  header: any;
  constructor(private http: HttpClient) {
    this.api = environment.apiUrl;
    this.apiForm = environment.formUrl;
  }

  // --------------------------------------------------- ALL USER ENDPOINTS

  addSubscription(data): any {
    return this.http.post(this.api + 'users/subscriptions/', data);
  }

  deleteSubscriptions(): any {
    return this.http.delete(this.api + 'users/subscriptions/');
  }

  getHistory(id: string, data): any {
    return this.http.post(this.api + 'users/history/' + id, data);
  }

  getUser(id?: string): any {
    return this.http.get(this.api + 'user/' + (id || ''));
  }

  getUserCard(id?: string): any {
    return this.http.get(this.api + 'user/card/');
  }

  getUserCardIntent(): any {
    return this.http.get(this.api + 'user/card/intent');
  }

  createUserCard(token: string): any {
    return this.http.post(this.api + 'user/card/', { token });
  }

  updateUserCard(token: string): any {
    return this.http.put(this.api + 'user/card/', { token });
  }

  getUsers(data): any {
    return this.http.post(this.api + 'users/', data);
  }

  acceptContract(): any {
    return this.http.post(this.api + 'users/contract', {});
  }

  deleteUser(id: string) {
    return this.http.delete(this.api + 'user/' + id);
  }

  putUser(user: User, id?: string): any {
    return this.http.put(this.api + 'user/' + (id || ''), user);
  }

  addUser(data) {
    return this.http.post(this.api + 'user/', data);
  }

  setPassword(password) {
    return this.http.post(this.api + 'user/password', { password });
  }

  getPreferences(id?: string): any {
    return this.http.get(this.api + 'user/preferences/' + (id || ''));
  }

  putPreferences(data, id?: string): any {
    return this.http.put(this.api + 'user/preferences/' + (id || ''), data);
  }

  updateWallet(data) {
    return this.http.post(this.api + 'user/wallet', data);
  }

  paymentIntent(data) {
    return this.http.post(this.api + 'user/wallet/intent', data);
  }

  getUsersStatistics(filters) {
    return this.http.post(this.api + 'satatistics/user', filters);
  }

  signUp(data) {
    return this.http.post(this.api + 'signUp/', data);
  }

  getMatches(id) {
    return this.http.get(this.api + 'leads/preference/' + id);
  }

  // --------------------------------------------------- ALL LEAD ENDPOINTS

  getLeadPDF(id): any {
    return this.http.get(this.api + 'leads/print/' + id, { responseType: 'arraybuffer' });
  }

  editLead(data) {
    return this.http.put(this.api + 'leads/', data);
  }


  adminRefundLead(data) {
    return this.http.put(this.api + 'leads/adminRefund', data);
  }

  editDeal(data, id) {
    return this.http.put(this.api + 'leads/deal/' + id, data);
  }

  setLeadSearch(data) {
    return this.http.post(this.api + 'leads/search/', data);
  }

  getAllLeads(data) {
    return this.http.post(this.api + 'leads/all/', data);
  }

  getInvalidLeads(data) {
    return this.getAllLeads(Object.assign({ status: 'invalid' }, data));
  }

  getDeletedLeads(data) {
    return this.getAllLeads(Object.assign({ status: 'delete' }, data));
  }

  getSellingLeads(data) {
    return this.getAllLeads(Object.assign({ status: 'selling' }, data));
  }

  getVerifyLeads(data) {
    return this.getAllLeads(Object.assign({ status: 'formVerify' }, data));
  }

  getNotVerifyLeads(data) {
    return this.getAllLeads(Object.assign({ status: 'formNotVerify' }, data));
  }

  getSoldLeads(data) {
    return this.getAllLeads(Object.assign({ status: 'sold' }, data));
  }

  getBoughtLeads(data, id?: string) {
    return this.http.post(this.api + 'leads/bought/' + (id || ''), data);
  }

  getBoughtLeadsCSV() {
    // TODO: need to set a content-tipe:text/csv to the request
    return this.http.get(this.api + 'leads/CSV/', { responseType: 'text' });
  }

  getSingleLeadsCSV(id) {
    // TODO: need to set a content-tipe:text/csv to the request
    return this.http.get(this.api + 'leads/CSV/' + id, { responseType: 'text' });
  }

  getLead(id): any {
    return this.http.get(this.api + 'leads/' + id + '/');
  }

  buyLead(id): any {
    return this.http.get(this.api + 'leads/buy/' + id);
  }


  buyLeadsCar(preferences): any {
    return this.http.post(this.api + 'leads/auto/buy/', preferences);
  }

  refundLead(id, data): any {
    return this.http.post(this.api + 'leads/refund/' + id, data);
  }

  userRefundLeads(data): any {
    return this.http.post(this.api + 'leads/refund/', data);
  }

  checkLeadsCar(preferences): any {
    return this.http.post(this.api + 'leads/auto/search/', preferences);
  }

  //-------------------------------------------- ALL COUPONS ENDPOINTS

  getCoupon(id?: string): any {
    return this.http.get(this.api + 'coupon/' + (id || ''));
  }

  checkCoupon(id: string): any {
    return this.http.get(this.api + 'coupon/check/' + id);
  }

  postCoupon(data): any {
    return this.http.post(this.api + 'coupon/', data);
  }

  putCoupon(data): any {
    return this.http.put(this.api + 'coupon/', data);
  }

  delteCoupon(id: string): any {
    return this.http.delete(this.api + 'coupon/' + id);
  }

  redeemCoupon(id: string): any {
    return this.http.get(this.api + 'coupon/redeem/' + id);
  }

  //--------------------------------------------- ALL PROMOTION ENDPOINT

  getPromotion(id?: string): any {
    return this.http.get(this.api + 'promotion/' + (id || ''));
  }

  activePromotion(): any {
    return this.http.get(this.api + 'promotion/active/');
  }

  postPromotion(data): any {
    return this.http.post(this.api + 'promotion/', data);
  }

  putPromotion(data, id): any {
    return this.http.put(this.api + 'promotion/' + id, data);
  }

  deletePromotion(id: string): any {
    return this.http.delete(this.api + 'promotion/' + id);
  }


  //--------------------------------------------- ALL DISCOUNT ENDPOINT

  getDiscount(id?: string): any {
    return this.http.get(this.api + 'discount/' + (id || ''));
  }

  activeDiscount(): any {
    return this.http.get(this.api + 'discount/active/');
  }

  postDiscount(data): any {
    return this.http.post(this.api + 'discount/', data);
  }

  putDiscount(data, id): any {
    return this.http.put(this.api + 'discount/' + id, data);
  }

  deleteDiscount(id: string): any {
    return this.http.delete(this.api + 'discount/' + id);
  }


  //-------------------------------------------- ALL SETTINGS ENDPOINTS

  getInvoices(params): any {
    return this.http.post(this.api + 'invoices', params);
  }

  getInvoicePDF(id): any {
    return this.http.get(this.api + 'invoices/print/' + id, { responseType: 'arraybuffer' });
  }

  getInvoiceXML(id): any {
    return this.http.get(this.api + 'invoices/XML/' + id, { responseType: 'arraybuffer' });
  }

  //----------------------------------------------- AFFILIATE STUFF

  getAffiliateLeads(filters, id?: string) {
    return this.http.post(this.api + 'affiliate/leads/' + (id || ''), filters);
  }

  getAffiliateUnsold(filters) {
    return this.http.post(this.api + 'affiliate/unsold/', filters);
  }

  getAffiliateUnsoldCSV() {
    // TODO: need to set a content-tipe:text/csv to the request
    return this.http.get(this.api + 'affiliate/CSV/', { responseType: 'text' });
  }

  //------------------------------------------------ OTHER SERVICES

  getStaticValiables(): any {
    return this.http.get(this.api + 'static');
  }

  putStaticValiables(data): any {
    return this.http.put(this.api + 'static', data);
  }

  getAllPreferences(data?): any {
    return this.http.post(this.api + 'user/preferences/all', (data || {}));
  }

  getDistrictPreferences(data): any {
    return this.http.post(this.api + 'user/preferences/districts/', data);
  }

  getChartData(filters): any {
    return this.http.post(this.api + 'affiliate/chart/', filters);
  }

  getProperyData(filters): any {
    return this.http.post(this.api + 'affiliate/property/', filters);
  }

  getLocation(query): any {
    return this.http.get(this.api + 'location/' + query);
  }

  getAffiliates(): any {
    return this.http.get(this.api + 'affiliates/');
  }

  sendFeedback(data): any {
    return this.http.post(this.api + 'feedback/', data);
  }

  generatePayouts(date?: any) {
    return this.http.post(this.api + 'generate/payout/', (date || {}));
  }

  getPayouts(data) {
    return this.http.post(this.api + 'payouts/', data);
  }

  getReferred(id?: any) {
    return this.http.get(this.api + 'referred/' + (id || ''));
  }

  editPayouts(data) {
    return this.http.put(this.api + 'payout/' + data.id, data);
  }

  addPayouts(data) {
    return this.http.put(this.api + 'payout/', data);
  }

  //------------------------- FINANCE ENDPOINTS

  putLeadFinance(lead: any) {
    return this.http.put(this.api + 'finance/leads', lead);
  }

  getUsersStatisticsFinance(filters) {
    return this.http.post(this.api + 'finance/satatistics/user', filters);
  }


  getAffiliateLeadsFinance(filters, id?: string) {
    return this.http.post(this.api + 'finance/affiliate/leads/' + (id || ''), filters);
  }


  getAllLeadsFinance(data) {
    return this.http.post(this.api + 'finance/leads/all/', data);
  }

  getSMSLeadsFinance(data) {
    return this.getAllLeadsFinance(Object.assign({ status: 'formVerify' }, data));
  }

  getSoldLeadsFinance(data) {
    return this.getAllLeadsFinance(Object.assign({ status: 'sold' }, data));
  }

  getPrivacyLeadsFinance(data) {
    return this.getAllLeadsFinance(Object.assign({ status: 'privacyVerify' }, data));
  }


  getInvalidLeadsFinance(data) {
    return this.getAllLeadsFinance(Object.assign({ status: 'delete' }, data));
  }


  
  //------------------------- FINANCE ENDPOINTS

  putLeadOther(lead: any) {
    return this.http.put(this.api + 'other/leads', lead);
  }

  getUsersStatisticsOther(filters) {
    return this.http.post(this.api + 'other/satatistics/user', filters);
  }


  getAffiliateLeadsOther(filters, id?: string) {
    return this.http.post(this.api + 'other/affiliate/leads/' + (id || ''), filters);
  }


  getAllLeadsOther(data) {
    return this.http.post(this.api + 'other/leads/all/', data);
  }




  //------------------------- NLT ENDPOINTS

  putLeadNLT(lead: any) {
    return this.http.put(this.api + 'NLT/leads', lead);
  }

  getUsersStatisticsNLT(filters) {
    return this.http.post(this.api + 'NLT/satatistics/user', filters);
  }


  getAffiliateLeadsNLT(filters, id?: string) {
    return this.http.post(this.api + 'NLT/affiliate/leads/' + (id || ''), filters);
  }


  getAllLeadsNLT(data) {
    return this.http.post(this.api + 'NLT/leads/all/', data);
  }

  getSMSLeadsNLT(data) {
    return this.getAllLeadsNLT(Object.assign({ status: 'formVerify' }, data));
  }

  getSoldLeadsNLT(data) {
    return this.getAllLeadsNLT(Object.assign({ status: 'sold' }, data));
  }

  getPrivacyLeadsNLT(data) {
    return this.getAllLeadsNLT(Object.assign({ status: 'privacyVerify' }, data));
  }


  getInvalidLeadsNLT(data) {
    return this.getAllLeadsNLT(Object.assign({ status: 'delete' }, data));
  }



  //------------------------- LOAN ENDPOINTS

  putLeadLoan(lead: any) {
    return this.http.put(this.api + 'loan/leads', lead);
  }

  getUsersStatisticsLoan(filters) {
    return this.http.post(this.api + 'loan/satatistics/user', filters);
  }


  getAffiliateLeadsLoan(filters, id?: string) {
    return this.http.post(this.api + 'loan/affiliate/leads/' + (id || ''), filters);
  }


  getAllLeadsLoan(data) {
    return this.http.post(this.api + 'loan/leads/all/', data);
  }

  getSMSLeadsLoan(data) {
    return this.getAllLeadsLoan(Object.assign({ status: 'formVerify' }, data));
  }

  getSoldLeadsLoan(data) {
    return this.getAllLeadsLoan(Object.assign({ status: 'sold' }, data));
  }

  getPrivacyLeadsLoan(data) {
    return this.getAllLeadsLoan(Object.assign({ status: 'privacyVerify' }, data));
  }


  getInvalidLeadsLoan(data) {
    return this.getAllLeadsLoan(Object.assign({ status: 'delete' }, data));
  }

  //------------------------- PACKAGE ENDPOINTS

  getPackages(uid?) {
    return this.http.get(this.api + 'packages/' + (uid || ''));
  }

  getPackage(id) {
    return this.http.get(this.api + 'package/' + id);
  }


  getPackageCSV(id) {
    return this.http.get(this.api + 'package/CSV/' + id, { responseType: 'text' });
  }

  //----------------------- POST NEW LEAD

  postLead(lead) {
    return this.http.post(this.apiForm + 'leads/', lead);
  }

  //------------------------- FINANCE BUYER ENDPOINTS

  getAffiliateGrouping(uid?) {
    return this.http.get(this.api + 'affiliate/grouping/' + (uid || ''));
  }

  getFinanceBuyer(uid?) {
    return this.http.get(this.api + 'finance/buyers/' + (uid || ''));
  }

  postFinanceBuyer(data) {
    return this.http.post(this.api + 'finance/buyers/', data);
  }

  putFinanceBuyer(id, data) {
    return this.http.put(this.api + 'finance/buyers/' + id, data);
  }


  uploadFileUser(id, file) {
    const options = {
      reportProgress: true,
    }
    return this.http.post(this.api + 'finance/file/' + id, file, options);
  }

  //------------------------- NLT BUYER ENDPOINTS

  getAffiliateGroupingNLT(uid?) {
    return this.http.get(this.api + 'NLT/affiliate/grouping/' + (uid || ''));
  }

  getNLTBuyer(uid?) {
    return this.http.get(this.api + 'NLT/buyers/' + (uid || ''));
  }

  postNLTBuyer(data) {
    return this.http.post(this.api + 'NLT/buyers/', data);
  }

  putNLTBuyer(id, data) {
    return this.http.put(this.api + 'NLT/buyers/' + id, data);
  }

  //------------------------- LOAN BUYER ENDPOINTS

  getAffiliateGroupingLoan(uid?) {
    return this.http.get(this.api + 'loan/affiliate/grouping/' + (uid || ''));
  }

  getLoanBuyer(uid?) {
    return this.http.get(this.api + 'loan/buyers/' + (uid || ''));
  }

  postLoanBuyer(data) {
    return this.http.post(this.api + 'loan/buyers/', data);
  }

  putLoanBuyer(id, data) {
    return this.http.put(this.api + 'loan/buyers/' + id, data);
  }

}
