import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { ApiService } from '../../services/api.service'

import { ErrorHandlerService } from '../../services/error-handler.service';

@Component({
  selector: 'vr-selling-leads',
  templateUrl: './selling-leads.component.html',
  styleUrls: ['./selling-leads.component.scss']
})
export class SellingLeadsComponent implements OnInit {


  leads: any;
  perPage = 100;
  pageNumber = 0;
  loading:boolean = false;
  params: any;

  constructor(
    private api: ApiService,
    private errorHandler: ErrorHandlerService,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.params = {
      perPage: this.perPage,
      pageNumber: this.pageNumber
    }
    this.search(this.params);
  }

  search(leadsParams) {
    
    this.loading = true;
    this.api.getSellingLeads(leadsParams).subscribe(
      data => {
        this.leads = data;
        
        this.loading = false;
        this.ref.detectChanges();
      },
      error => {
        this.errorHandler.handleError(error);
        this.loading = false;
      });

  }
  /**
   * Filter on filter passed
   */
  filter(action): void {
    this.params = {
      perPage: this.params.perPage,
      pageNumber: this.params.pageNumber
    }
    Object.assign(this.params,action.filters);
    this.search(this.params);
  }

  getAction(action) {
    
    switch (action) {
      case 'nextPage':
        this.params.pageNumber++;
        this.search(this.params);
        break;

      case 'prevPage':
        this.params.pageNumber--;
        this.search(this.params);
        break;

      case 'update':
        this.search(this.params);
        break;

      default:
        
        break;
    }

    
  }

}
