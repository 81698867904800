import { Injectable, EventEmitter, Output } from '@angular/core';
import { User } from '../models/user';
import { ApiService } from './api.service';

@Injectable()
export class UserService {

  @Output() change: EventEmitter<User> = new EventEmitter();

  constructor(
    private api: ApiService
  ) { }

  onChange(user: User) {
    this.change.emit(user);
  }

  updateWallet() {
    this.api.getUser().subscribe(
      data => {
        this.change.emit(data);
      }
    )

  }
}
