import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { MessagingService } from '../../../services/messaging.service';
import { ErrorHandlerService } from '../../../services/error-handler.service';
import { User } from '../../../models/user';
import { Card } from '../../../models/card';
import { Router, NavigationExtras } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { FirstTimeGuideComponent } from '../../first-time-guide/first-time-guide.component';
import { UserService } from '../../../services/user.service';
import { Subscription } from 'rxjs';
import { PreferencesMapComponent } from '../../../core/preferences-map/preferences-map.component';
import { ConfirmDialogComponent } from '../../../core/confirm-dialog/confirm-dialog.component';
import { ModalContractComponent } from '../../../core/modal-contract/modal-contract.component';
import { StaticVariablesComponent } from '../../../core/static-variables/static-variables.component';
@Component({
  selector: 'vr-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  user: User;
  card: Card;

  loading: boolean = true;
  reloadComponents: boolean = false;
  needToSetPrefs: boolean = false;
  showWalletModal: boolean = false;
  notification: boolean = true;
  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private errorHandler: ErrorHandlerService,
    private api: ApiService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private notifications: MessagingService,
    private userService: UserService // mantain this for subscribers
  ) { }

  ngOnInit() {
    this.getUser();
    if (localStorage.getItem('notifications')) {
      this.notification = false;
    }
    this.subscriptions.push(this.userService.change.subscribe(_user => {
      this.onAfterChargeCreditCard(_user as User);
    }));
  }

  /**
   * Handle credit card charge, update frontend elements
   * @param _user the new user data
   */
  onAfterChargeCreditCard(_user: User) {
    this.user = Object.assign({}, _user);
    this.showWalletModal = false;
    this.cd.detectChanges();
  }

  /**
   * get user
   */
  getUser(): void {
    this.subscriptions.push(this.api.getUser()
      .subscribe(data => {
        this.user = data;
        // get user card
        this.subscriptions.push(this.api.getUserCard().subscribe(
          data => {
            // empty data === no card set
            if (Object.keys(data).length == 0) {
              this.card = null;
            } else {
              this.card = data as Card;
            }

            this.api.getPreferences().subscribe(
              data => {
                this.needToSetPrefs = !data || data.length == 0;

                if (this.needToSetPrefs && this.user.userType == 'user') {
                  this.openPreferenceModal()
                }

                this.loading = false;
                this.cd.detectChanges();
              },
              error => {
                this.needToSetPrefs = false;
                this.loading = false;
                this.cd.detectChanges();
              }
            )
          },
          error => {
            this.errorHandler.handleError(error);
            this.card = null;
            this.loading = false;
            this.cd.detectChanges();
          }));
      }, error => {
        this.errorHandler.handleError(error);
        this.user = null;
        this.loading = false;
        this.cd.detectChanges();
      }));
  }

  setNotifications() {
    this.notifications.requestPermission(this.user.key);
  }
  /**
   * Charge user wallet using CreditCardComponent
   */
  chargeWallet(): void {
    this.showWalletModal = true;
    this.cd.detectChanges();
  }

  openChageWallet() {
    if (this.user.contract.accepted) {
      this.chargeWallet()
    } else {
      this.openContractDialog();
    }
  }

  /**
   * Route to a path
   * 
   * @param to an array to pass to perform a route change
   */
  go(to: Array<string>, queryParams?: NavigationExtras) {
    this.router.navigate(to, queryParams || {});
  }

  /**
   * Shows a brief introduction to a new user
   */
  startGuideTour(): void {
    window.open("https://www.e-leads.it/pdf/Manuale_operativo.pdf", "_blank");
  }

  /**
 * Shows a brief introduction to a new user
 */
  ethicManual(): void {
    window.open("https://www.e-leads.it/pdf/Linee_guida.pdf", "_blank");
  }

  /**
 * Shows a brief introduction to a new user
 */
  openPreferenceModal(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '75%';
    dialogConfig.data = { text: "Per prima cosa imposta le tue preferenze. Altrimenti non potrai ricevere notifiche ogni volta che si genera un nuovo lead." };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      if (data == true) {
        this.router.navigate(['home', 'user-preferences'], { queryParams: { ft: 1 } });
      }
    });
  }

  /**
   * Shows a brief introduction to a new user
   */
  openContractDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '80%';
    dialogConfig.data = this.user;
    const dialogRef = this.dialog.open(ModalContractComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.chargeWallet();
        }
      }
    );
  }

  reload() {
    this.reloadComponents = true;
    setTimeout(() => { this.reloadComponents = false; this.cd.detectChanges(); }, 100);
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}


