import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'vr-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Input() id: string;
  @Output() fileUploaded = new EventEmitter();
  files: string[] = [];
  formData: FormData;
  loading: boolean = false;
  text = "Trascina qui i files da caricare";

  constructor(
    private api: ApiService,
    private errorHandelr: ErrorHandlerService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.formData = new FormData();
  }

  onDrop(event) {
    event.preventDefault();

    var files = event.dataTransfer.files;

    for (var i = 0, f; f = files[i]; i++) {
      this.files.push(f);
      this.formData.append('file', f, f.name);
    }
    this.text = "Trascina qui i files da caricare"
  }

  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
    this.text = "Rilascia i files"

  }

  onDragLeave(event) {
    event.stopPropagation();
    event.preventDefault();
    this.text = "Trascina qui i files da caricare"

  }

  importFile(event) {
    var files = event.target.files;

    for (var i = 0, f; f = files[i]; i++) {
      this.files.push(f);
      this.formData.append('file', f, f.name);
    }
  }

  remove(index) {
    this.files.splice(index, 1);
  }

  upload() {
    this.loading = true;
    this.api.uploadFileUser(this.id, this.formData)
      .subscribe(data => {
        var files: any = data;
        this.loading = false;
        this.fileUploaded.emit(files.files);

        this.files = [];
        this.formData = new FormData();
        
        this.toastr.success("File caricati con successo");
      }, error => {
        this.errorHandelr.handleError(error);
        this.loading = false;
      });
  }

}
