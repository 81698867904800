import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../services/api.service';
import { LeadDecodeService } from '../../services/lead-decode.service';

@Component({
    selector: 'vr-edit-lead',
    templateUrl: './edit-lead.component.html',
    styleUrls: ['./edit-lead.component.scss']
})
export class EditLeadComponent implements OnInit {

    lead: any;
    type: string;
    apiUrl: string;
    objectKeys = Object.keys;
    insurances: any = [];
    jobs: any = [];
    buyers: any = [];
    ages: any = [];
    owner: any;

    constructor(
        private dialogRef: MatDialogRef<EditLeadComponent>,
        private toastr: ToastrService,
        private api: ApiService,
        private leadDecode: LeadDecodeService,
        @Inject(MAT_DIALOG_DATA) data) {

        this.lead = data.lead;
        this.type = data.type;
        switch (this.type) {
            case 'finance':
                if(!this.lead.hometown){
                    this.lead.hometown = {
                        zipcode: "",
                        name: ""
                    }
                }
                this.jobs = this.leadDecode.getJobsFinance();
                this.api.getFinanceBuyer().subscribe(
                    data => {
                        this.buyers = data;
                    })
                this.api.getUser(this.lead.affiliate_id).subscribe(
                    data => {
                        this.owner = data;
                    }
                )
                break;


            case 'NLT':
                this.api.getNLTBuyer().subscribe(
                    data => {
                        this.buyers = data;
                    })
                this.api.getUser(this.lead.affiliate_id).subscribe(
                    data => {
                        this.owner = data;
                    }
                )
                break;

            case 'Loan':
                this.api.getLoanBuyer().subscribe(
                    data => {
                        this.buyers = data;
                    })
                this.api.getUser(this.lead.affiliate_id).subscribe(
                    data => {
                        this.owner = data;
                    }
                )
                break;

            default:
                this.lead.insurance = this.leadDecode.getCodeInsurance(this.lead.insurance);
                this.lead.job = this.leadDecode.getCodeJob(this.lead.job);
                this.lead.age = this.leadDecode.getCodeAge(this.lead.age);


                this.insurances = this.leadDecode.getInsurances();
                this.jobs = this.leadDecode.getJobs();
                this.ages = this.leadDecode.getAges();
                break;
        }


    }

    saveFinance() {
        this.api.putLeadFinance(this.lead)
            .subscribe(data => {
                this.toastr.success("", "Dati aggiornati");

                this.dialogRef.close();
            }, error => {
                switch (error.status) {
                    case 500:
                        this.toastr.error('<i>Se il problema persiste contattare il supporto.</i>', 'C\'è stato qualche problema');
                        break;

                    case 403:
                        this.toastr.error('<i>Sembra che il tuo account non disponga dei permessi per effettuare questa operazione.</i>', 'Permessi insuffucenti');
                        break;

                    case 400:
                        this.toastr.error('<i>I dati inseriti non sono validi.</i>', 'Dati errati');
                        break;

                    default:
                        break;
                }
                this.dialogRef.close();
            });
    }


    saveNLT() {
        this.api.putLeadNLT(this.lead)
            .subscribe(data => {
                this.toastr.success("", "Dati aggiornati");

                this.dialogRef.close();
            }, error => {
                switch (error.status) {
                    case 500:
                        this.toastr.error('<i>Se il problema persiste contattare il supporto.</i>', 'C\'è stato qualche problema');
                        break;

                    case 403:
                        this.toastr.error('<i>Sembra che il tuo account non disponga dei permessi per effettuare questa operazione.</i>', 'Permessi insuffucenti');
                        break;

                    case 400:
                        this.toastr.error('<i>I dati inseriti non sono validi.</i>', 'Dati errati');
                        break;

                    default:
                        break;
                }
                this.dialogRef.close();
            });
    }


    saveLoan() {
        this.api.putLeadLoan(this.lead)
            .subscribe(data => {
                this.toastr.success("", "Dati aggiornati");

                this.dialogRef.close();
            }, error => {
                switch (error.status) {
                    case 500:
                        this.toastr.error('<i>Se il problema persiste contattare il supporto.</i>', 'C\'è stato qualche problema');
                        break;

                    case 403:
                        this.toastr.error('<i>Sembra che il tuo account non disponga dei permessi per effettuare questa operazione.</i>', 'Permessi insuffucenti');
                        break;

                    case 400:
                        this.toastr.error('<i>I dati inseriti non sono validi.</i>', 'Dati errati');
                        break;

                    default:
                        break;
                }
                this.dialogRef.close();
            });
    }

    save() {
        this.api.editLead(this.lead)
            .subscribe(data => {


                this.lead.insurance = this.leadDecode.decodeInsurance(this.lead.insurance);
                this.lead.job = this.leadDecode.decodeJob(this.lead.job);
                this.lead.age = this.leadDecode.decodeAge(this.lead.age);

                this.toastr.success("", "Dati aggiornati");

                this.dialogRef.close();
            }, error => {
                switch (error.status) {
                    case 500:
                        this.toastr.error('<i>Se il problema persiste contattare il supporto.</i>', 'C\'è stato qualche problema');
                        break;

                    case 403:
                        this.toastr.error('<i>Sembra che il tuo account non disponga dei permessi per effettuare questa operazione.</i>', 'Permessi insuffucenti');
                        break;

                    case 400:
                        this.toastr.error('<i>I dati inseriti non sono validi.</i>', 'Dati errati');
                        break;

                    default:
                        break;
                }
                this.dialogRef.close();
            });
    }

    refund(type) {
        this.lead.refund = type;
        this.api.adminRefundLead(this.lead)
            .subscribe(data => {

                this.toastr.success("", "Dati aggiornati");

                this.dialogRef.close();
            }, error => {
                switch (error.status) {
                    case 500:
                        this.toastr.error('<i>Se il problema persiste contattare il supporto.</i>', 'C\'è stato qualche problema');
                        break;

                    case 403:
                        this.toastr.error('<i>Sembra che il tuo account non disponga dei permessi per effettuare questa operazione.</i>', 'Permessi insuffucenti');
                        break;

                    case 400:
                        this.toastr.error('<i>I dati inseriti non sono validi.</i>', 'Dati errati');
                        break;

                    default:
                        break;
                }

                this.lead.insurance = this.leadDecode.decodeInsurance(this.lead.insurance);
                this.lead.job = this.leadDecode.decodeJob(this.lead.job);
                this.lead.age = this.leadDecode.decodeAge(this.lead.age);
                this.dialogRef.close();
            });
    }

    fileUploaded(e) {
        if (!this.lead.files) {
            this.lead.files = {}
        }
        Object.keys(e).map(key => {
            this.lead.files[key] = e[key];
        })
    }

    removeFile(key) {
        delete this.lead.files[key];
    }

    close() {

        this.lead.insurance = this.leadDecode.decodeInsurance(this.lead.insurance);
        this.lead.job = this.leadDecode.decodeJob(this.lead.job);
        this.lead.age = this.leadDecode.decodeAge(this.lead.age);
        this.dialogRef.close();

    }



    ngOnInit() {
    }

}
