import { Component, OnInit, Inject  } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'vr-edit-payout',
  templateUrl: './edit-payout.component.html',
  styleUrls: ['./edit-payout.component.scss']
})
export class EditPayoutComponent implements OnInit {

  payout: any;

  constructor(
    private dialogRef: MatDialogRef<EditPayoutComponent>,
    private toastr: ToastrService,
    private api: ApiService,
    @Inject(MAT_DIALOG_DATA) data) {
      this.payout = data;
  }

  ngOnInit() {
  }

  save(){
    this.api.editPayouts(this.payout)
    .subscribe( data => {

      this.toastr.success("","Dati aggiornati");

      this.dialogRef.close();
    }, error => {
      switch (error.status) {
        case 500:
          this.toastr.error('<i>Se il problema persiste contattare il supporto.</i>', 'C\'è stato qualche problema');
          break;

        case 403:
          this.toastr.error('<i>Sembra che il tuo account non disponga dei permessi per effettuare questa operazione.</i>', 'Permessi insuffucenti');
          break;

        case 400:
          this.toastr.error('<i>I dati inseriti non sono validi.</i>', 'Dati errati');
          break;

        default:
          break;
      }
      this.dialogRef.close();
    });
  }

  close() {
    this.dialogRef.close();
  }

}
