import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../services/api.service'
import { ErrorHandlerService } from '../../services/error-handler.service';
import { Lead } from '../../models/lead';
import { Router } from '@angular/router';
@Component({
  selector: 'vr-refund-list',
  templateUrl: './refund-list.component.html',
  styleUrls: ['./refund-list.component.scss']
})
export class RefundListComponent implements OnInit {

  leads: Lead[];
  perPage: number = 30;
  pageNumber: number = 0;
  loading: boolean = false;
  isUserList: boolean = false;
  params: any;

  constructor(
    private api: ApiService,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.params = {
      perPage: this.perPage,
      pageNumber: this.pageNumber,
      status: "refunding"
    }
    this.search(this.params);
  }

  search(leadsParams) {
    
    this.loading = true;
    if (this.router.url.indexOf('refunding') > -1) {
      this.api.getAllLeads(leadsParams).subscribe(
        data => {
          this.onAfterSearch(data as Lead[]);
        },
        error => {
          this.errorHandler.handleError(error);
          this.onAfterSearch([]);
        });
    } else {
      this.isUserList = true;
      this.api.userRefundLeads(leadsParams).subscribe(
        data => {
          this.onAfterSearch(data as Lead[]);
        },
        error => {
          this.errorHandler.handleError(error);
          this.onAfterSearch([]);
        });
    }
  }

  onAfterSearch(data: Lead[]): void {
    this.leads = data;
    this.loading = false;
    this.ref.detectChanges();
  }

  getAction(action) {
    switch (action) {
      case "nextPage":
        this.params.pageNumber++;
        this.search(this.params);
        break;

      case "prevPage":
        this.params.pageNumber--;
        this.search(this.params);
        break;

      case 'update':
        this.search(this.params);
        break;

      default:
        break;
    }
  }

}
