import { Component, EventEmitter, OnInit, Output, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Lead } from "../../models/lead";
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../services/api.service'
import { AgmMap } from '@agm/core'

@Component({
  selector: 'vr-param-form',
  templateUrl: './param-form.component.html',
  styleUrls: ['./param-form.component.scss']
})
export class ParamFormComponent implements OnInit {

  @ViewChild(AgmMap) myMap: AgmMap;
  @Output() setLeads = new EventEmitter<Lead>();
  @Input() ButtonText: string;
  @Input() SearchText: string;
  @Input() car: boolean = false;
  @Input() leads: any = {
    area: [
      {
        zipcode: "",
        distance: 0
      }
    ]
  };

  options: any = ['1'];
  loactionLoader: any;

  insuranceOptions: any = [
    { id: 1, description: 'Polizza rimborso spese sanitarie', value: 'rimborso-spese-sanitarie' },
    { id: 2, description: 'Polizza infortuni', value: 'infortuni' },
    { id: 3, description: 'Polizza sulla vita', value: 'vita' },
    { id: 4, description: 'Polizza casa', value: 'casa' },
    { id: 11, description: 'Polizza dentistica', value: 'dentistica' },
    { id: 5, description: 'Polizza responsabilità capo famiglia', value: 'responsabilita-civile' },
    { id: 6, description: 'Pensione integrativa', value: 'pensione-integrativa' },
    { id: 7, description: 'Polizza rc professionale', value: 'rc-professionale' },
    { id: 8, description: 'Polizza tutela legale', value: 'tutela-legale' },
    { id: 9, description: 'Polizza viaggio', value: 'viaggio' },
    { id: 10, description: 'Polizza uomo chiave', value: 'key-man' },
    { id: 12, description: 'Polizza LTC', value: 'ltc' },
    { id: 13, description: 'Polizza caso vita', value: 'caso-vita' },
    { id: 13, description: 'Polizza sanitaria cane e gatto', value: 'cani-gatti' }
  ];

  ageOptions: any = [
    { id: 1, description: 'Meno di 20 anni', value: '20' },
    { id: 2, description: 'Da 20 a 25 anni', value: '20-25' },
    { id: 3, description: 'Da 25 a 30 anni', value: '25-30' },
    { id: 4, description: 'Da 30 a 35 anni', value: '30-35' },
    { id: 5, description: 'Da 35 a 40 anni', value: '35-40' },
    { id: 6, description: 'Da 40 a 45 anni', value: '40-45' },
    { id: 7, description: 'Da 45 a 50 anni', value: '45-50' },
    { id: 8, description: 'Da 50 a 55 anni', value: '50-55' },
    { id: 9, description: 'Da 55 a 60 anni', value: '55-60' },
    { id: 10, description: 'Da 60 a 65 anni', value: '60-65' },
    { id: 11, description: 'Da 65 a 70 anni', value: '65-70' },
    { id: 12, description: 'Da 70 a 75 anni', value: '70-75' },
    { id: 13, description: 'Da 75 a 80 anni', value: '75-80' },
    { id: 14, description: 'Oltre 80', value: '80' }
  ];

  jobOptions: any = [
    { id: 1, description: 'Agente immobiliare', value: 'agente-immobiliare' },
    { id: 2, description: 'Agricoltore', value: 'agricoltore' },
    { id: 3, description: 'Architetto', value: 'architetto' },
    { id: 4, description: 'Artigiano', value: 'artigiano' },
    { id: 5, description: 'Avvocato', value: 'avvocato' },
    { id: 6, description: 'Commerciante', value: 'commerciante' },
    { id: 6, description: 'Casalinga/o', value: 'casalinga' },
    { id: 7, description: 'Commercialista', value: 'commercialista' },
    { id: 8, description: 'Dirigente d\'azienda', value: 'dirigente-azienda' },
    { id: 9, description: 'In cerca di occupazione', value: 'disoccupato' },
    { id: 10, description: 'Geometra', value: 'geometra' },
    { id: 11, description: 'Ingegnere', value: 'ingegnere' },
    { id: 12, description: 'Lavoratore dipendente (settore pubblico)', value: 'dipendente-pubblico' },
    { id: 13, description: 'Lavoratore dipendente (settore privato)', value: 'dipendente-privato' },
    { id: 14, description: 'Libero professionista', value: 'libero-professionista' },
    { id: 15, description: 'Medico', value: 'medico' },
    { id: 16, description: 'Pensionato', value: 'pensionato' },
    { id: 17, description: 'Studente', value: 'studente' },
    { id: 18, description: 'Titolare d\'Azienda', value: 'titolare' },
    { id: 19, description: 'Mamma', value: 'mum' }
  ]

  constructor(
    private changeDetector: ChangeDetectorRef,
    private toastr: ToastrService,
    private api: ApiService
  ) { }

  ngOnInit() {
    if (this.leads == undefined) {
      this.leads = {
        area: [
          {
            zipcode: "",
            distance: 0,
          }
        ]
      };

      if (this.car) {
        this.leads.days = 30;
      }

    } else {
      this.myMap.triggerResize();
    }
  }

  add() {
    this.leads.area.push({
      zipcode: "",
      distance: 0,
    });
  }

  remove(index) {
    this.leads.area.splice(index, 1);
  }

  save() {
    const areas = [];

    this.leads.area.map((element) => {
      if (element.zipcode != '' && element.distance != 0) {
        areas.push(element);
      }
    });

    if ((!this.leads.insurance || this.leads.insurance.length == 0) && !this.car) {
      this.toastr.error('<i>Inserire almeno  un tipo di assicurazione </i>', 'I dati inseriti non sono validi');
      return;
    }

    if (areas.length > 0 && areas[0].zipcode) {
      this.leads.area = areas
      this.setLeads.emit(this.leads);
    } else {
      this.toastr.error('<i>Inserire almeno un\'area </i>', 'I dati inseriti non sono validi');
    }


  }

  optionSelected(object, index) {
    this.leads.area[index] = {
      zipcode: object.city + ' ' + ((object.zipcode) ? object.zipcode : '') + ' (' + object.district + '), ' + object.region,
      showName: object.city + ((object.zipcode) ? ' ' + object.zipcode : ''),
      latitude: object.latitude,
      longitude: object.longitude,
      distance: (this.leads.area[index].distance) ? this.leads.area[index].distance : 20
    }

  }

  loadOptions(value) {
    if (this.loactionLoader) {
      this.loactionLoader.unsubscribe();
    }
    if (value) {
      this.loactionLoader = this.api.getLocation(value)
        .subscribe(success => {
          this.options = success;
          this.changeDetector.detectChanges();
        });
    }
    else {
      this.options = [];
      this.changeDetector.detectChanges();
    }
  }

  selectAll(select: NgModel, values) {
    const temp = [];
    values.map((e) => { temp.push(e.value) });
    select.update.emit(temp);
    this.changeDetector.detectChanges();
  }

  deselectAll(select: NgModel) {
    select.update.emit([]);
  }

}
