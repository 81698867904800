import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { User } from '../../models/user';
import { ErrorHandlerService } from '../../services/error-handler.service'
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'vr-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss']
})
export class ReferralComponent implements OnInit {
  public user: User;
  public url: string = "https://app.e-leads.it/sign-up?referrer="
  public number: any;
  constructor(
    private api: ApiService,
    private errorHandler: ErrorHandlerService,
    private toast: ToastrService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.number = { number: 0 };
    this.getUser();
    this.getReferred();
  }

  getUser() {
    this.api.getUser()
      .subscribe(user => {
        this.user = user;
        this.url = this.url + this.user.key;
      }, error => {
        this.errorHandler.handleError(error);
      })
  }

  getReferred() {
    this.api.getReferred()
      .subscribe(data => {
        this.number = data;
        this.cd.detectChanges();
      }, error => {
        this.errorHandler.handleError(error);
      })
  }

  copyURL() {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toast.success('Copiato negli appunti!')
  }

}
