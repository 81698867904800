import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service'
import { User } from '../../models/user';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ConfirmDialogComponent } from '../../core/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';

import { ErrorHandlerService } from '../../services/error-handler.service';

@Component({
  selector: 'vr-user-statistics',
  templateUrl: './user-statistics.component.html',
  styleUrls: ['./user-statistics.component.scss']
})
export class UserStatisticsComponent implements OnInit {

  users: User[]; // the user list
  perPage = 50; // pp conf
  pageNumber = 0; // pn conf

  showNext = false; // true if we can go to next page
  showPrev = false; // true if we can go to the previous one
  query = '';
  order = 'asc';
  sort = 'account';
  dateStart  = new Date();
  dateEnd  = new Date();

  loading: boolean = true; // true if the script loading something, set to true to show spinner
  params: any;

  constructor(
    private api: ApiService,
    private ref: ChangeDetectorRef,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private toastr: ToastrService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    var date = new Date();
    this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);

    this.params = {
      perPage: this.perPage,
      pageNumber: this.pageNumber,
      query: this.query,
      sort: this.sort,
      order: this.order,
      dateStart: new Date(this.dateStart).toLocaleDateString("it-IT").replace(/[\/]/ig, '-'),
      dateEnd: new Date(this.dateEnd).toLocaleDateString("it-IT").replace(/[\/]/ig, '-'),
    }
    this.users = [];
    this.search(this.params);
  }

  /**
   * search for users
   * @param params the pagination params
   */
  search(params: any): void {
    this.loading = true; // we are loading
    this.ref.detectChanges();
    this.api.getUsersStatistics(params).subscribe(
      data => {
        this.users = data as User[];
        this.onAfterSearch();
      },
      error => {
        this.errorHandler.handleError(error);
        this.onAfterSearch();
      });
  }

  filter(){
    this.params = {
      perPage: this.perPage,
      pageNumber: this.pageNumber,
      query: this.query,
      sort: this.sort,
      order: this.order,
      dateStart: new Date(this.dateStart).toLocaleDateString("it-IT").replace(/[\/]/ig, '-'),
      dateEnd: new Date(this.dateEnd).toLocaleDateString("it-IT").replace(/[\/]/ig, '-'),
    }
    this.search(this.params);
  }

  /**
   * handle after search behaviours
   */
  onAfterSearch(): void {
    this.loading = false; // stop loading

    // if no users is returned
    if (!this.users || !this.users.length) {
      return;
    }

    // TODO: this behaviour have some bugs (e.g. user.length % perPage = 0)
    if (this.users.length < this.perPage) {
      this.showNext = false;
    } else {
      this.showNext = true;
    }

    // cannot go prev
    if (this.params.pageNumber === 0) {
      this.showPrev = false;
    } else {
      this.showPrev = true;
    }
    // let the graphic detect changes
    this.ref.detectChanges();
  }


 
  /**
   * Perform an action binded to another component
   * @param action the action to perform
   */
  getAction(action) {
    switch (action) {
      case "nextPage":
        if (this.loading) {
          return;
        }
        this.params.pageNumber++;
        this.search(this.params);
        break;

      case "prevPage":
        if (this.loading) {
          return;
        }
        this.params.pageNumber--;
        this.search(this.params);
        break;

      default:
        break;
    }

  }


}
