import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../../services/api.service'
import { ErrorHandlerService } from '../../../services/error-handler.service'
@Component({
  selector: 'vr-nlt-user-statistics',
  templateUrl: './nlt-user-statistics.component.html',
  styleUrls: ['./nlt-user-statistics.component.scss']
})
export class NltUserStatisticsComponent implements OnInit {

  users: any = [];
  total: any = {
    other: 0,
    sold: 0,
    amount: 0
  };
  perPage = 50; // pp conf
  pageNumber = 0; // pn conf

  showNext = false; // true if we can go to next page
  showPrev = false; // true if we can go to the previous one
  query = '';
  order = 'asc';
  sort = 'account';
  dateStart  = new Date();
  dateEnd  = new Date();

  loading: boolean = true; // true if the script loading something, set to true to show spinner
  params: any;
  constructor(
    private api: ApiService,
    private errorHanlder: ErrorHandlerService,
    private cd : ChangeDetectorRef
  ) { }

  ngOnInit() {
    var date = new Date();
    this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);

    this.params = {
      perPage: this.perPage,
      pageNumber: this.pageNumber,
      query: this.query,
      sort: this.sort,
      order: this.order,
      dateStart: new Date(this.dateStart).toLocaleDateString("it-IT").replace(/[\/]/ig, '-'),
      dateEnd: new Date(this.dateEnd).toLocaleDateString("it-IT").replace(/[\/]/ig, '-'),
    }
    this.users = [];
    this.search(this.params);
  }

  search(params) {
    this.total = {
      other: 0,
      formVerify: 0,
      sold: 0,
      amount: 0
    };
    
    this.api.getUsersStatisticsNLT(this.params)
    .subscribe(data => {
      this.users = data;
      this.loading = false;
      this.users.map(user => {
        this.total.sold += user.sold;
        this.total.formVerify += user.formVerify;
        this.total.other += user.other;
        this.total.amount += user.amount;
      })
      this.cd.detectChanges();

    }, error => {
      this.errorHanlder.handleError(error);
    });
  }


  filter(){
    this.params = {
      perPage: this.perPage,
      pageNumber: this.pageNumber,
      query: this.query,
      sort: this.sort,
      order: this.order,
      dateStart: new Date(this.dateStart).toLocaleDateString("it-IT").replace(/[\/]/ig, '-'),
      dateEnd: new Date(this.dateEnd).toLocaleDateString("it-IT").replace(/[\/]/ig, '-'),
    }
    this.search(this.params);
  }

  /**
   * handle after search behaviours
   */
  onAfterSearch(): void {
    this.loading = false; // stop loading

    // if no users is returned
    if (!this.users || !this.users.length) {
      return;
    }

    // TODO: this behaviour have some bugs (e.g. user.length % perPage = 0)
    if (this.users.length < this.perPage) {
      this.showNext = false;
    } else {
      this.showNext = true;
    }

    // cannot go prev
    if (this.params.pageNumber === 0) {
      this.showPrev = false;
    } else {
      this.showPrev = true;
    }
    // let the graphic detect changes
    this.cd.detectChanges();
  }


 
  /**
   * Perform an action binded to another component
   * @param action the action to perform
   */
  getAction(action) {
    switch (action) {
      case "nextPage":
        if (this.loading) {
          return;
        }
        this.params.pageNumber++;
        this.search(this.params);
        break;

      case "prevPage":
        if (this.loading) {
          return;
        }
        this.params.pageNumber--;
        this.search(this.params);
        break;

      default:
        break;
    }

    
  }

}
