import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { ApiService } from '../../services/api.service'
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { Lead } from '../../models/lead';

@Component({
  selector: 'vr-bought-leads',
  templateUrl: './bought-leads.component.html',
  styleUrls: ['./bought-leads.component.scss']
})
export class BoughtLeadsComponent implements OnInit {

  @Input() embed: boolean = false;
  @Input() maxItems: number;

  leads: any;
  sort: string = "time_sold";
  order: string = "desc";
  query: string;
  perPage: number = 30;
  pageNumber: number = 0;
  loading: boolean = false;

  params: any;

  constructor(
    private api: ApiService,
    private toastr: ToastrService,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.params = {
      perPage: this.maxItems ? this.maxItems : this.perPage,
      pageNumber: this.pageNumber,
      query: this.query,
      sort: this.sort,
      order: this.order
    }
    this.search(this.params);
  }

  filter() {
    this.params = {
      perPage: this.maxItems ? this.maxItems : this.perPage,
      pageNumber: this.pageNumber,
      query: this.query,
      sort: this.sort,
      order: this.order
    }
    this.search(this.params);
  }

  search(leadsParams) {

    this.loading = true;
    this.api.getBoughtLeads(leadsParams).subscribe(
      data => {
        this.leads = data;

        this.loading = false;
        this.ref.detectChanges();
      },
      error => {
        this.loading = false;
        switch (error.status) {
          case 500:
            this.toastr.error('<i>Se il problema persiste contattare il supporto.</i>', 'C\'è stato qualche problema');
            break;

          case 403:
            this.toastr.error('<i>Sembra che il tuo account non disponga dei permessi per effettuare questa operazione.</i>', 'Permessi insuffucenti');
            break;

          case 400:
            this.toastr.error('<i>I dati inseriti non sono validi.</i>', 'Dati errati');
            break;

          default:
            break;
        }
      });

  }

  getAction(action) {
    switch (action) {
      case "nextPage":
        this.params.pageNumber++;
        this.search(this.params);
        break;

      case "prevPage":
        this.params.pageNumber--;
        this.search(this.params);
        break;

      case 'update':
        this.search(this.params);
        break;

      default:
        break;
    }
  }

  downloadCSV() {
    this.api.getBoughtLeadsCSV().subscribe(
      data => {
        var blob = new Blob([data], { type: 'text/csv' });
        saveAs(blob, "leads.csv");
      })
  }

}
