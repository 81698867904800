import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ConfirmDialogComponent } from '../../core/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { UserService } from '../../services/user.service';
import { ChargeCreditCardComponent } from '../../pages/charge-credit-card/charge-credit-card.component';
import { ModalContractComponent } from '../../core/modal-contract/modal-contract.component';

@Component({
  selector: 'vr-buy-lead',
  templateUrl: './buy-lead.component.html',
  styleUrls: ['./buy-lead.component.scss']
})
export class BuyLeadComponent implements OnInit {

  @Input() lead: any;
  @Input() big: boolean;
  @Input() car: boolean = false;
  @Input() carPreferences: any = {};
  @Input() showDetail: boolean;
  @Output() action = new EventEmitter<string>();
  user: any;

  constructor(
    private router: Router,
    private api: ApiService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private ref: ChangeDetectorRef,
    private errorHandler: ErrorHandlerService,
    private userService: UserService
  ) { }

  ngOnInit() {

  }

  go() {
    this.router.navigate(['home', 'lead', this.lead.key]);
  }

  buy() {
    this.api.getUser()
      .subscribe(
        data => {
          this.user = data;

          if (this.user.contract.accepted) {

            this.checkWalletAndBuy()
          } else {
            this.openContractDialog();
          }


        }, error => {
          this.errorHandler.handleError(error);
        });
  }

  checkWalletAndBuy() {
    if (!this.user.wallet) {
      this.user.wallet = 0;
    }

    var price = (this.lead.discount) ? (this.lead.discountedPrice) : (this.lead.price);
    if (this.user.wallet >= price) {
      this.buyBackend();
    } else {
      this.openCreditCardDialog();
    }
  }

  openCreditCardDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    var minimum = (this.lead.discount) ? (this.lead.discountedPrice - this.user.wallet) : (this.lead.price - this.user.wallet);
    dialogConfig.data = { minimum: minimum };
    const dialogRef = this.dialog.open(ChargeCreditCardComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      // notify user service than something has changed
      if (data && data !== this.user.wallet) {
        this.user.wallet = this.user.wallet ? this.user.wallet + data : data;
        this.buyBackend();
      }

      this.userService.onChange(this.user);
    });
  }




  openBuyDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '33%';

    if (!this.car) {
      dialogConfig.data = { text: "", title: "Vuoi acquistare questo lead?", showClose: true };
    } else {
      dialogConfig.data = { text: "", title: "Vuoi acquistare questo pacchetto di leads?", showClose: true };
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.buy();
        }
      }
    );
  }

  openContractDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '80%';
    dialogConfig.data = this.user;
    const dialogRef = this.dialog.open(ModalContractComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.checkWalletAndBuy();
        }
      }
    );
  }

  buyBackend() {

    if (!this.car) {
      this.api.buyLead(this.lead.key)
        .subscribe(
          res => {
            // lead is bought      
            this.lead.status = "sold";
            this.action.emit("Success");
            this.toastr.success('Le informazioni verranno aggiornate', 'Leads acquistato correttamente');
            this.user.wallet -= (this.lead.discount) ? this.lead.discountedPrice : this.lead.price;
            this.userService.onChange(this.user);
            this.ref.detectChanges();
          }, error => {
            this.errorHandler.handleError(error, 'lead');
            this.action.emit("Error");
          })
    } else {

      this.api.buyLeadsCar({ list: this.carPreferences })
        .subscribe(
          data => {
            // lead is bought 
            this.action.emit("Success");
            this.toastr.success('I leads sono stati aggiunti alla sezione "Leads Acquistati"', 'Pacchetto acquistato correttamente');
            this.user.wallet -= data.total;
            this.userService.onChange(this.user);
            this.ref.detectChanges();
          }, error => {
            this.errorHandler.handleError(error, 'lead');
            this.action.emit("Error");
          })
    }

  }

}
