import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../services/api.service'
import { ErrorHandlerService } from '../../services/error-handler.service'


@Component({
  selector: 'vr-lead-auto-bought',
  templateUrl: './lead-auto-bought.component.html',
  styleUrls: ['./lead-auto-bought.component.scss']
})
export class LeadAutoBoughtComponent implements OnInit {
  packages: any;

  constructor(
    private api: ApiService,
    private ref: ChangeDetectorRef,
    private errorHandler: ErrorHandlerService,
    ) { }

  ngOnInit() {
    this.getPackages();
  }

  
  getPackages() {
    this.api.getPackages().subscribe(
      data => {
        this.packages = data;
        this.ref.detectChanges();
      }, error => {
        this.errorHandler.handleError(error);
      })
  }

}
