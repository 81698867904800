import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Component
import { LoginComponent } from './pages/auth/login/login.component';
import { SignUpComponent } from './pages/auth/sign-up/sign-up.component';
import { LayoutComponent } from '../../../client/src/app/core/layout/layout.component';
import { HomeComponent } from './pages/dashboard/home/home.component';
import { LeadComponent } from './pages/lead/lead.component';
import { LeadSearchComponent } from './pages/lead-search/lead-search.component';
import { AdvancedSearchComponent } from './pages/advanced-search/advanced-search.component';
import { QuickSearchComponent } from './pages/quick-search/quick-search.component';
import { NewUserComponent } from './pages/new-user/new-user.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { UserListComponent } from './pages/user-list/user-list.component';
import { UserPreferencesComponent } from './pages/user-preferences/user-preferences.component';
import { SysadminSearchComponent } from './pages/sysadmin-search/sysadmin-search.component';
import { BoughtLeadsComponent } from './pages/bought-leads/bought-leads.component';
import { NotBoughtListComponent } from './pages/not-bought-list/not-bought-list.component';
import { RefundListComponent } from './pages/refund-list/refund-list.component';
import { InvalidLeadsListComponent } from './pages/invalid-leads-list/invalid-leads-list.component';
import { SoldLeadsListComponent } from './pages/sold-leads-list/sold-leads-list.component';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { PayoutComponent } from './pages/payout/payout.component'
import { AffiliateStatisticsComponent } from './pages/affiliate-statistics/affiliate-statistics.component'
import { PreferencesStatisticsComponent } from './pages/preferences-statistics/preferences-statistics.component'
import { UserStatisticsComponent } from './pages/user-statistics/user-statistics.component'
import { EditSnippetComponent } from './pages/edit-snippet/edit-snippet.component';
import { DeletedLeadsComponent } from './pages/deleted-leads/deleted-leads.component';
import { SellingLeadsComponent } from './pages/selling-leads/selling-leads.component';
import { CouponListComponent } from './pages/coupon-list/coupon-list.component'
import { PromotionsComponent } from './pages/promotions/promotions.component'
import { PricingTableComponent } from './pages/pricing-table/pricing-table.component'
import { DiscountsComponent } from './pages/discounts/discounts.component'
import { AffiliateUnsoldComponent } from './pages/affiliate-unsold/affiliate-unsold.component'
import { VerifyLeadListComponent } from './pages/verify-lead-list/verify-lead-list.component'
import { NotVerifiedLeadListComponent } from './pages/not-verified-lead-list/not-verified-lead-list.component'
import { ReferralComponent } from './pages/referral/referral.component'
import { HistoryComponent } from './pages/history/history.component'

import { LeadAutoBoughtComponent } from './pages/lead-auto-bought/lead-auto-bought.component'

import { AllLeadsComponent } from  './pages/finance/all-leads/all-leads.component'
import { FinanceBoughtLeadsComponent } from  './pages/finance/finance-bought-leads/finance-bought-leads.component'
import { FinancePrivacyLeadsComponent } from  './pages/finance/finance-privacy-leads/finance-privacy-leads.component'
import { InvalidLeadsComponent } from  './pages/finance/invalid-leads/invalid-leads.component'
import { SmsLeadsComponent } from  './pages/finance/sms-leads/sms-leads.component'
import { FinaceUsserStatisticsComponent } from './pages/finance/finace-usser-statistics/finace-usser-statistics.component'
import { BuyersComponent } from './pages/finance/buyers/buyers.component';


import { NltAllLeadsComponent } from  './pages/nlt/nlt-all-leads/nlt-all-leads.component'
import { NltBoughtLeadsComponent } from  './pages/nlt/nlt-bought-leads/nlt-bought-leads.component'
import { NltInvalidLeadsComponent } from  './pages/nlt/nlt-invalid-leads/nlt-invalid-leads.component'
import { NltSmsLeadsComponent } from  './pages/nlt/nlt-sms-leads/nlt-sms-leads.component'
import { NltUserStatisticsComponent } from './pages/nlt/nlt-user-statistics/nlt-user-statistics.component'
import { NltBuyersComponent } from './pages/nlt/nlt-buyers/nlt-buyers.component';

import { LoanAllLeadsComponent } from './pages/loan/loan-all-leads/loan-all-leads.component';
import { LoanSmsLeadsComponent } from './pages/loan/loan-sms-leads/loan-sms-leads.component';
import { LoanBoughtLeadsComponent } from './pages/loan/loan-bought-leads/loan-bought-leads.component';
import { LoanUserStatisticsComponent } from './pages/loan/loan-user-statistics/loan-user-statistics.component';
import { LoanInvalidLeadsComponent } from './pages/loan/loan-invalid-leads/loan-invalid-leads.component';
import { LoanBuyersComponent } from './pages/loan/loan-buyers/loan-buyers.component';
import { LoanModalBuyerComponent } from './pages/loan/loan-modal-buyer/loan-modal-buyer.component';
import { OtherUserStatisticsComponent } from './pages/other/other-user-statistics/other-user-statistics.component';
import { OtherAllLeadsComponent } from './pages/other/all-leads/all-leads.component';

// Services
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { from } from 'rxjs';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'sign-up',
    component: SignUpComponent,
  },
  {
    path: 'home',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'other/all-leads',
        component: OtherAllLeadsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'other/statistics',
        component: OtherUserStatisticsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'finance/all-leads',
        component: AllLeadsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'finance/users',
        component: BuyersComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'finance/bought-leads',
        component: FinanceBoughtLeadsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'finance/privacy-leads',
        component: FinancePrivacyLeadsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'finance/invalid-leads',
        component: InvalidLeadsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'finance/SMS-leads',
        component: SmsLeadsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'finance/statistics',
        component: FinaceUsserStatisticsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'loan/all-leads',
        component: LoanAllLeadsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'loan/users',
        component:LoanBuyersComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'loan/bought-leads',
        component: LoanBoughtLeadsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'loan/invalid-leads',
        component: LoanInvalidLeadsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'loan/SMS-leads',
        component: LoanSmsLeadsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'loan/statistics',
        component: LoanUserStatisticsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'nlt/all-leads',
        component: NltAllLeadsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'nlt/users',
        component: NltBuyersComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'nlt/bought-leads',
        component: NltBoughtLeadsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'nlt/invalid-leads',
        component: NltInvalidLeadsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'nlt/SMS-leads',
        component: NltSmsLeadsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'nlt/statistics',
        component: NltUserStatisticsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'referral',
        component: ReferralComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'pricing-table',
        component: PricingTableComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'history',
        component: HistoryComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'affiliate-unsold',
        component: AffiliateUnsoldComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'discount',
        component: DiscountsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'lead/:id',
        component: LeadComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'affiliate-statistics',
        component: AffiliateStatisticsComponent,
        canActivate: [AuthGuard]
      }, 
      {
        path: 'coupon',
        component: CouponListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'promotion',
        component: PromotionsComponent,
        canActivate: [AuthGuard]
      }, 
      {
        path: 'preferences-statistics',
        component: PreferencesStatisticsComponent,
        canActivate: [AuthGuard]
      }, 
      {
        path: 'broker-statistics',
        component: UserStatisticsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'edit-snippet',
        component: EditSnippetComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'feedback',
        component: FeedbackComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'lead-search',
        component: LeadSearchComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'advanced-search',
        component: AdvancedSearchComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'quick-search',
        component: QuickSearchComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'new-user',
        component: NewUserComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'user-profile',
        component: UserProfileComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'user-profile/:id',
        component: UserProfileComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'users',
        component: UserListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'invoices',
        component: InvoicesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'user-preferences',
        component: UserPreferencesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'all-leads',
        component: SysadminSearchComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'car-leads-bought',
        component: LeadAutoBoughtComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'invalid-leads',
        component: InvalidLeadsListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'deleted-leads',
        component: DeletedLeadsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'selling-leads',
        component: SellingLeadsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'verify-leads',
        component: VerifyLeadListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'not-verified-leads',
        component: NotVerifiedLeadListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'sold-leads',
        component: SoldLeadsListComponent
      },
      {
        path: 'refunding-leads',
        component: RefundListComponent
      },
      {
        path: 'bought-leads',
        component: BoughtLeadsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'refund-leads',
        component: RefundListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'not-bought-leads',
        component: NotBoughtListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'payout',
        component: PayoutComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  { path: '**', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }