import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { ApiService } from '../../services/api.service';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { CreditCardComponent } from '../credit-card/credit-card.component';
import { environment } from '../../../environments/environment';
import { UserService } from '../../services/user.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ModalRedeemCouponComponent } from '../modal-redeem-coupon/modal-redeem-coupon.component';

declare var Stripe: any;

@Component({
  selector: 'vr-charge-credit-card',
  templateUrl: './charge-credit-card.component.html',
  styleUrls: ['./charge-credit-card.component.scss'],
})
export class ChargeCreditCardComponent implements OnInit {
  ccdata: any = {};
  confirm: boolean = false;
  HasCreditCard: boolean = true;
  HasClicked: boolean = false;
  ShowMinimum: boolean = false;
  Minimum: number = 10;
  promotion: any = {};
  stripe: any;
  paymentData = {
    amount: 10,
    error: ''
  }

  constructor(
    private userService: UserService,
    private api: ApiService,
    private dialogRef: MatDialogRef<ChargeCreditCardComponent>,
    private errorHandler: ErrorHandlerService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    if (data.minimum) {
      this.ShowMinimum = true;
      this.Minimum = data.minimum;
      this.paymentData.amount = data.minimum;
    }

    this.stripe = Stripe(environment.stripeToken); // use your test publishable key
  }


  ngOnInit() {
    this.checkPromotions();
  }

  /**
   * show confirm modal, if amount is correct
   */
  confirmPayment(): void {
    if (this.paymentData.amount < this.Minimum) {
      this.paymentData.error = 'Il valore deve essere superiore o uguale a ' + this.Minimum + '€';
      return;
    } else if (this.paymentData.amount >= 10000) {
      this.paymentData.error = 'Il valore deve essere inferiore a 10000€';
      return;
    }
    this.confirm = true;
    this.cd.detectChanges();
  }

  /**
   * go back from confirm modal to buy modal
   */
  back(): void {
    this.confirm = false;
    this.paymentData.amount = 10;
    this.cd.detectChanges();
  }

  /**
   * charge credit card if amount is correct
   */

  pay(): void {

    this.HasClicked = true;
    this.paymentData.error = '';


    var promotion = 0;
    if (this.promotion) {
      var date = Date.now();
      if (this.promotion.dateStart <= date && this.promotion.dateEnd >= date && this.paymentData.amount >= this.promotion.minimum) {
        promotion += this.promotion.amount;
      }
    }

    this.api.paymentIntent({ amount: this.paymentData.amount }).subscribe(
      (data: any) => {
        this.stripe.handleCardPayment(
          data.client_secret,
          {
            payment_method: data.payment_method,
          }
        ).then((result) => {
          
          if (result.error) {
            this.toastr.error('La transazione non è andata a buon fine. Controlla che il credito disponibile sia sufficente.');
            this.HasClicked = false;
            this.dialogRef.close();
          } else {
            this.api.updateWallet({ intent: result.paymentIntent.id }).subscribe(
              data => {
                this.toastr.success('Grazie! Pagamento avvenuto con successo!');
                this.HasClicked = false;
                this.dialogRef.close(this.paymentData.amount + promotion);
              },
              error => {
                // this.errorHandler.handleError(error);
                this.toastr.error('La transazione non è andata a buon fine. Controlla che il credito disponibile sia sufficente.');
                this.HasClicked = false;
                this.dialogRef.close();
              });
          }
        });
      },
      error => {
        // this.errorHandler.handleError(error);
        this.toastr.error('La transazione non è andata a buon fine. Controlla che il credito disponibile sia sufficente.');
        this.HasClicked = false;
        this.dialogRef.close();
      });

    /* this.api.updateWallet({ amount: this.paymentData.amount }).subscribe(
      data => {
        this.toastr.success('Grazie! Pagamento avvenuto con successo!');
        this.HasClicked = false;
        this.dialogRef.close(this.paymentData.amount + promotion);
      },
      error => {
       // this.errorHandler.handleError(error);
        this.toastr.error('La transazione non è andata a buon fine. Controlla che il credito disponibile sia sufficente.');
        this.HasClicked = false;
        this.dialogRef.close();
      }); */
  }

  HasCC(event): void {
    this.HasCreditCard = event;
  }

  close() {
    this.dialogRef.close(false);
  }

  redeemCoupon(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '30%';

    dialogConfig.data = {};
    const dialogRef = this.dialog.open(ModalRedeemCouponComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      // notify user service than something has changed

      this.dialogRef.close(data)


    });
  }

  checkPromotions() {
    this.api.activePromotion()
      .subscribe(data => {
        this.promotion = data[0];
      })
  }



}
