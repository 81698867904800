import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_TRANSITION } from '../../../app.animation';
import { AuthService } from "../../../services/auth.service";
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ForgotPasswordComponent } from '../../forgot-password/forgot-password.component';



@Component({
  selector: 'vr-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [...ROUTE_TRANSITION],
  host: { '[@routeTransition]': '' }
})
export class LoginComponent implements OnInit {

  username: string;
  password: string;
  hide: boolean = true;
  
  constructor(
    private auth: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
  }

  login(): void {

    this.auth.login(this.username,this.password)
      .then((result) => {
        localStorage.setItem("User",JSON.stringify(result));
        this.auth.getToken()
          .then((token) =>{
            this.toastr.success('','Accesso eseguito con successo');
            localStorage.setItem("JWT_TOKEN_ELEADS",String(token));
            this.router.navigate(['/home']);
          });
      })
      .catch((error) => {
        this.toastr.error('<i>Controlla i dati inseriti</i>','Email o password invalidi');
      });

  }

  goTo(destination){
    this.router.navigate(['/'+destination]);
  }

  /**
   * shows a modal to handle forgot pwd behaviours
   */

  onForgotPassword() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '25%';

    dialogConfig.data = { };
    const dialogRef = this.dialog.open(ForgotPasswordComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(success => {
      if(typeof success !== 'undefined' && success) {
        this.toastr.success('','Un email con la procedura per il reset della password è stata inviata all\'indirizzo email specificato.');
      } 
    });
  }

}
