import { Injectable } from '@angular/core';

@Injectable()
export class LeadDecodeService {
  insurances = {
    'rimborso-spese-sanitarie': 'Polizza rimborso spese sanitarie',
    'infortuni': 'Polizza infortuni',
    'vita': 'Polizza sulla vita (TCM)',
    'casa': 'Polizza casa',
    'dentistica': 'Polizza dentistica',
    'responsabilita-civile': 'Polizza responsabilità capo famiglia',
    'pensione-integrativa': 'Pensione integrativa',
    'rc-professionale': 'Polizza rc professionale',
    'tutela-legale': 'Polizza tutela legale',
    'viaggio': 'Polizza viaggio',
    'key-man': 'Uomo chiave',
    'ltc': 'Polizza LTC',
    'cani-gatti': 'Polizza cani e gatti',
    'caso-vita': 'Polizza caso vita',
    'rc-auto': 'Polizza RC Auto',
  }

  ages = {
    '20': 'Meno di 20',
    '20-25': 'Da 20 a 24',
    '25-30': 'Da 25 a 29',
    '30-35': 'Da 30 a 34',
    '35-40': 'Da 35 a 39',
    '40-45': 'Da 40 a 45',
    '45-50': 'Da 45 a 49',
    '50-55': 'Da 50 a 54',
    '55-60': 'Da 55 a 59',
    '60-65': 'Da 60 a 64',
    '65-70': 'Da 65 a 69',
    '70-75': 'Da 70 a 74',
    '75-80': 'Da 75 a 80',
    '80': 'Più di 80'
  }


  hours = {
    '8-9': '8:00 - 9:00',
    '9-10': '9:00 - 10:00',
    '10-11': '10:00 - 11:00',
    '11-12': '11:00 - 12:00',
    '12-13': '12:00 - 13:00',
    '13-14': '13:00 - 14:00',
    '14-15': '14:00 - 15:00',
    '15-16': '15:00 - 16:00',
    '16-17': '16:00 - 17:00',
    '17-18': '17:00 - 18:00',
    '18-19': '18:00 - 19:00',
    '19-20': '19:00 - 20:00'
  }



  jobs = {
    'mum': "Mamma",
    'medico': "Medico",
    'cooperativa': "Cooperativa",
    'srl': "Dipendente SRL",
    'altro': "Dipendente Altro",
    'spa': "Dipendente SPA",
    'statale': "Dipendente Statale",
    'parastatale': "Dipendente Parastatale",
    'pubblico': "Dipendente Pubblico",
    'militare': "Militare",
    'casalinga': "Casalinga/o",
    'avvocato': "Avvocato",
    'architetto': "Architetto",
    'commercialista': "Commercialista",
    'geometra': "Geometra",
    'agente-immobiliare': "Agente immobiliare",
    'ingegnere': "Ingegnere",
    'agricoltore': 'Agricoltore',
    'artigiano': 'Artigiano',
    'commerciante': 'Commerciante',
    'dirigente-azienda': 'Dirigente d\'azienda',
    'disoccupato': 'In cerca di occupazione',
    'dipendente-pubblico': 'Lavoratore dipendente (settore pubblico)',
    'dipendente-privato': 'Lavoratore dipendente (settore privato)',
    'libero-professionista': 'Libero professionista',
    'pensionato': 'Pensionato',
    'studente': 'Studente',
    'titolare': 'Titolare d\'Azienda',
    'autonomo': 'Lavoratore autonomo',    
    "ditta": "Ditta individuale con meno di 2 anni di attività",
    "dittameno2": "Ditta individuale con meno di 2 anni di attività",
    "dittapiu2": "Ditta individuale con più di 2 anni di attività",
    "professionista": "Professionista con meno di 2 anni di attività",
    "professionistameno2": "Professionista con meno di 2 anni di attività",
    "professionistapiu2": "Professionista con più di 2 anni di attività",
    'cooperativameno50': "Cooperativa meno 50 dip.",
    'srlmeno15': "Dipendente SRL meno 15 dip.",
    'spameno15': "Dipendente SPA meno 15 dip.",
    'cooperativapiu50': "Cooperativa più 50 dip.",
    'srlpiu15': "Dipendente SRL più 15 dip.",
    'spapiu15': "Dipendente SPA più 15 dip.",
  }

  jobsFinance = {
    'cooperativa': "Cooperativa",
    'srl': "Dipendente SRL",
    'spa': "Dipendente SPA",
    'statale': "Dipendente Statale",
    'parastatale': "Dipendente Parastatale",
    'pubblico': "Dipendente Pubblico",
    'militare': "Militare",
    'pensionato': 'Pensionato',
    'autonomo': 'Lavoratore autonomo',
    'altro': "Dipendente Altro",
    'disoccupato': 'In cerca di occupazione',
    "ditta": "Ditta individuale",
    "professionista": "Professionista",
  }

  jobsFinanceInfo = {
    'cooperativa': "Cooperativa",
    'altro': "Altro",
    'snc': "Dipendente SAS/SNC",
    'disoccupato': 'In cerca di occupazione',
    'srl': "Dipendente SRL",
    'spa': "Dipendente SPA",
    'casalinga': "casalinga",
    "ditta": "Ditta individuale",
    "dittameno2": "Ditta individuale",
    "dittapiu2": "Ditta individuale con più di 2 anni di attività",
    "professionista": "Professionista con meno di 2 anni di attività",
    "professionistameno2": "Professionista con meno di 2 anni di attività",
    "professionistapiu2": "Professionista con più di 2 anni di attività",
    'cooperativameno50': "Cooperativa meno 50 dip.",
    'srlmeno15': "Dipendente SRL meno 15 dip.",
    'spameno15': "Dipendente SPA meno 15 dip.",
    'cooperativapiu50': "Cooperativa più 50 dip.",
    'srlpiu15': "Dipendente SRL più 15 dip.",
    'spapiu15': "Dipendente SPA più 15 dip.",
    'statale': "Dipendente Statale",
    'parastatale': "Dipendente Parastatale",
    'pubblico': "Dipendente Pubblico",
    'militare': "Militare",
    'pensionato': 'Pensionato',
    'interinale': 'Dipendente agenzia interinale',
    'pensionatovecchiaia': 'Pensionato (vecchiaia)',
    'pensionatosociale': 'Pensionato (sociale)',
    'pensionatoinvalidita': 'Pensionato (invalidià)',
    'pensionatoanzianita': 'Pensionato (anzianità)',
    'autonomo': 'Lavoratore autonomo',
  }

  status = {
    'refunding': 'In rimborso',
    'selling': 'In vendita',
    'sold': 'Venduto',
    'invalid': 'In fase di verifica',
    'delete': 'Invalido',
    'unsold': 'Invenduto',
    'formVerify': 'Verifica SMS',
    'privacyVerify': 'Privacy da accettare',
    'formNotVerify': 'Non verificato'
  }

  motivation = {
    'double': 'Doppio',
    'wrong': 'Errato',
    'not-intrested': 'Non interessato',
    'unreachable': 'Irraggiungibile',
  }


  deal = {
    'to-meet': 'Da contattare',
    'meet': 'Contattato',
    'working': 'In trattativa',
    'success': 'Contratto sottoscritto',
    'fail': 'Trattativa fallita'
  }

  constructor() { }

  getJobs() {
    var array = [];
    Object.keys(this.jobs).map(key => {
      array.push({
        code: key,
        description: this.jobs[key]
      })
    })
    return array;
  }

  getJobsFinance() {
    var array = [];
    Object.keys(this.jobsFinance).map(key => {
      array.push({
        code: key,
        description: this.jobsFinance[key]
      })
    })
    return array;
  }

  getJobsFinanceInfo() {
    var array = [];
    Object.keys(this.jobsFinanceInfo).map(key => {
      array.push({
        code: key,
        description: this.jobsFinanceInfo[key]
      })
    })
    return array;
  }

  getAges() {
    var array = [];
    Object.keys(this.ages).map(key => {
      array.push({
        code: key,
        description: this.ages[key]
      })
    })
    return array;
  }

  getInsurances() {
    var array = [];
    Object.keys(this.insurances).map(key => {
      array.push({
        code: key,
        description: this.insurances[key]
      })
    })
    return array;
  }

  getCodeAge(age) {
    var code;
    Object.keys(this.ages).map(key => {
      if (age == this.ages[key]) {
        code = key
      }
    })
    return code;
  }

  getCodeJob(job) {
    var code;
    Object.keys(this.jobs).map(key => {
      if (job == this.jobs[key]) {
        code = key
      }
    })
    return code;
  }

  getCodeInsurance(insurance) {
    var code;
    Object.keys(this.insurances).map(key => {
      if (insurance == this.insurances[key]) {
        code = key
      }
    })
    return code;
  }


  decodeAge(age) {
    if (this.ages[age]) {
      return this.ages[age];
    } else {
      return age
    }
  }

  decodeJob(job) {
    if (this.jobs[job]) {
      return this.jobs[job];
    } else {
      return job;
    }
  }

  decodeInsurance(insurance) {
    if (this.insurances[insurance]) {
      return this.insurances[insurance];
    } else {
      return insurance
    }
  }

  decodeTime(time) {
    if (this.hours[time]) {
      return this.hours[time];
    } else {
      return time;
    }
  }

  decodeStatus(status) {
    if (this.status[status]) {
      return this.status[status];
    } else {
      return status
    }
  }

  decodeMotivation(motivation) {
    if (this.motivation[motivation]) {
      return this.motivation[motivation];
    } else {
      return motivation
    }
  }

  decodeDeal(deal) {
    return (deal) ? this.deal[deal.status] : "Da contattare";
  }

  decodeExtraData(data: Object) {

    var extraData = [];
    var dataLabels = {
      'amount': 'Importo copertura',
      'smoker': 'Fumatore?',
      'family': 'Polizza per',
      'length': 'Anni durata polizza',
      'microchip': 'Ha il microchip',
      'race': 'Razza',
      'age': 'Età',
      'sick': 'Ha avuto problemi di salute',
      'specie': 'Tipo',
      'type': 'Tipo di copertura',
      'health': 'Problemi di salute',
      'notes': 'Annotazioni dell\'utente',
      'ALIMENTAZIONE': 'Alimentazione',
      'ANNO_ACQUISTO': 'Anno di acquisto',
      'ANNO_IMMATRICOLAZIONE': 'Anno immatricolazione',
      'CILINDRATA': 'Cilindrata',
      'CODICE_FISCALE': 'Codice fiscale',
      'COMPAGNIA_ATTUALE': 'Compagnia assicurativa attuale',
      'COMUNE_NASCITA': 'Comune di nascita',
      'DATA_NASCITA': 'Data di nascita',
      'DATA_DECORRENZA_INSERITA': 'Data decorrenza polizza',
      'MARCA': 'Marca del veicolo',
      'MODALITA_PAGAMENTO': 'Modalità di pagamento',
      'MODELLO': 'Modello del veicolo',
      'NAZIONE_NASCITA': 'Nazione di nascita',
      'PERCORRENZA': 'Percorrenza annua',
      'POSTO_AUTO': 'Posto auto',
      'POTENZA_FISCALE': 'Potenza fiscale',
      'PRODOTTO': 'Tipo di prodotto',
      'SITUAZIONE_ASSICURATIVA': 'Situazione assicurativa',
      'STATO_CIVILE': 'Stato civile',
      'TARGA': 'Targa veicolo',
      'TIPO_CARROZZERIA': 'Tipo di varrozzeria veicolo',
      'TIPO_SEGMENTO': 'Segmento veicolo',
      'TITOLO_STUDIO': 'Titolo di studio',
      'VEICOLO_UTILIZZO': 'Utilizzo veicolo',
      'INDIRIZZO': 'Indirizzo',
    }

    if (data) {
      Object.keys(data).map(key => {
        var label = {
          label: '',
          code: '',
          value: ''
        };
        var value = data[key];
        if (dataLabels[key]) {

          switch (key) {
            case 'age':
              label.label = dataLabels[key];
              label.code = key;

              if (label.value == '0') {
                label.value = 'meno di 1 anno';
              } else {
                label.value = value + ' anni';
              }



              break;

            case 'sick':
              label.label = dataLabels[key];
              label.code = key;

              if (value == true) {
                label.value = 'Sì';
              } else {
                label.value = 'No';
              }
              break;

            case 'microchip':
              label.label = dataLabels[key];
              label.code = key;

              if (value == true) {
                label.value = 'Sì';
              } else {
                label.value = 'No';
              }
              break;

            case 'type':
              var coperture = {
                'sanitaria': "solo Polizza sanitaria",
                'sanitaria_rc': "Polizza sanitaria con responsabilità civile",
              }
              label.label = dataLabels[key];
              label.code = key;
              label.value = coperture[value];

              break;

            case 'specie':
              var species = {
                'dog': "Cane",
                'cat': "Gatto",
              }
              label.label = dataLabels[key];
              label.code = key;
              label.value = species[value];

              break;

            default:
              label.label = dataLabels[key];
              label.code = key;
              label.value = value;
              break;
          }


        } else {
          label.label = key;
          label.code = key;
          label.value = data[key];
        }

        extraData.push(label);

      })
    }
    return extraData;
  }

}
