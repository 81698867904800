import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../services/api.service'
import { ErrorHandlerService } from '../../services/error-handler.service'
import { DomSanitizer, SafeResourceUrl, } from '@angular/platform-browser';

@Component({
  selector: 'vr-edit-snippet',
  templateUrl: './edit-snippet.component.html',
  styleUrls: ['./edit-snippet.component.scss']
})
export class EditSnippetComponent implements OnInit {
  script: string;
  settings: any = {
    affiliateRef: '',
    backgroundColor: '',
    buttonColor: '',
    insurance: '',
    orientation: 'v'
  };

  url: string;
  show=true;

  color: string = '#00EF9B';
  colorBg: string = '#eeeeee';

  insuranceOptions: any = [
    { id: 0, description: 'Nessuna', value: '' },
    
    { id: 14, description: 'Mutui', value: 'mutuo' }, 
    { id: 15, description: 'Noleggio a lungo termine privati', value: 'nlt' }, 
    { id: 16, description: 'Noleggio a lungo termine aziende/p.iva', value: 'nlt-piva"' }, 
    /*{ id: 14, description: 'Finanziamenti', value: 'finanziamento' }, */
    { id: 15, description: 'Cessione del quinto', value: 'cessione' },
    { id: 1, description: 'Prestiti per autonomi', value: 'finance-professionisti' },
    { id: 1, description: 'Polizza rimborso spese sanitarie', value: 'rimborso-spese-sanitarie' },
    { id: 2, description: 'Polizza infortuni', value: 'infortuni' },
    { id: 3, description: 'Polizza sulla vita', value: 'vita' },
    { id: 4, description: 'Polizza casa', value: 'casa' },
    { id: 11, description: 'Polizza dentistica', value: 'dentistica' },
    { id: 5, description: 'Polizza responsabilità capo famiglia', value: 'responsabilita-civile' },
    { id: 6, description: 'Pensione integrativa', value: 'pensione-integrativa' },
    { id: 7, description: 'Polizza rc professionale', value: 'rc-professionale' },
    { id: 8, description: 'Polizza tutela legale', value: 'tutela-legale' },
    { id: 9, description: 'Polizza viaggio', value: 'viaggio' },
    { id: 10, description: 'Polizza Key man', value: 'key-man' },
    { id: 12, description: 'Polizza LTC', value: 'ltc' },
    { id: 13, description: 'Polizza caso vita', value: 'caso-vita' }
  ];

  constructor(
    private api: ApiService,
    private errorHandler: ErrorHandlerService,
    private cd: ChangeDetectorRef,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.api.getUser()
      .subscribe(data => {
        this.settings.affiliateId = data.key;
        this.update();
      }, error => {
        this.errorHandler.handleError(error);
      });

    this.url = 'https://form.e-leads.it/preview.html?' +
      'bg_color=' + this.settings.backgroundColor +
      '&btn_color=' + this.settings.buttonColor +
      '&orientation=' + this.settings.orientation +
      '&type=' + this.settings.insurance;
  }


  update() {
    this.show=false;
    this.settings.backgroundColor = this.colorBg;
    this.settings.buttonColor = this.color;

    this.script = `
    <script id='eleads-root' type='text/javascript'>
      (function() {
        function async_load(){
          window.el_p= {aff_id: '`+ this.settings.affiliateId + `', aff_ref:"` + this.settings.affiliateRef + `", bg_color: '` + this.settings.backgroundColor + `', btn_color: '` + this.settings.buttonColor + `', type:'` + this.settings.insurance + `', orientation: '` + this.settings.orientation + `'};
          var s = document.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          var u = 'https://form.e-leads.it/eleads-lib.js';
          s.src = u; 
          var e = document.getElementById('eleads-root');
          e.parentNode.insertBefore(s, e);
        }
        if (window.attachEvent)
          window.attachEvent('onload', async_load);
        else
          window.addEventListener('load', async_load, false);
        })();
    </script>
    
    INSERIRE QUESTO DIV DOVE SI VUOLE CHE VENGA RENDERIZZATO IL FORM
    <div class="eleads-form"></div>
    `;
    this.url = 'https://form.e-leads.it/preview.html?' +
      'bg_color=' + this.settings.backgroundColor +
      '&btn_color=' + this.settings.buttonColor +
      '&orientation=' + this.settings.orientation +
      '&type=' + this.settings.insurance;
    this.cd.detectChanges();
    this.show=true;
  }
}
