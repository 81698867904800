import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';


@Component({
  selector: 'vr-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  title: any;
  text: any;
  timer: any;
  loading: boolean = true;
  showClose: boolean;

  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {

    this.text = data.text;
    this.title = data.title;

    if (data.showClose != undefined) {
      this.showClose = data.showClose;
    } else {
      this.showClose = true;
    }

    this.timer = setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  save() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(false);
  }

  ngOnInit() {
  }
}
