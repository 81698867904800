import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../services/api.service'
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ConfirmDialogComponent } from '../../core/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { ModalPromotionsComponent } from '../modal-promotions/modal-promotions.component'

@Component({
  selector: 'vr-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent implements OnInit {

  
  loading: boolean = true;
  promotions: any = [];

  constructor(
    private api: ApiService,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit() {
    this.loadPromotions();
  }

  loadPromotions() {
    this.api.getPromotion().subscribe(
      data => {
        this.promotions = data;
        this.loading = false;
        this.ref.detectChanges();
      },
      error => {
        this.errorHandler.handleError(error);
        this.loading = false;
        this.ref.detectChanges();
      }
    )
  }

  deletePromotion(promotion): void {
    if (!promotion.key) {
      console.error('No user key defined!');
      return;
    }
    this.loading = true;
    // if user key exists then delete it
    this.api.deletePromotion(promotion.key)
      .subscribe(
        data => {
          this.toastr.success('', 'Promozione eliminato correttamente');
          // let the graphic detect changes
          this.loadPromotions()
        },
        error => {
          this.errorHandler.handleError(error);
          this.loadPromotions();
        })
  }

  //this function will prompt a modal with the modal 
  //to edit the coupon if exists, otherwise it will
  //prompt a create ne coupon modal
  singlePromotion(promotion?:any){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '75%';
    dialogConfig.data = (promotion)?{ id: promotion.key }:{};

    const dialogRef = this.dialog.open(ModalPromotionsComponent, dialogConfig)
      .afterClosed()
      .subscribe(data => {
        if (data) {
          // perform action
          this.loadPromotions();
        } 
      });

  }




  openConfirmDialog($event: Event, promotion): void {
    const dialogConfig = new MatDialogConfig();
    let target: HTMLElement = $event.target as HTMLElement || new HTMLElement(); // the dialog target



    const title: string = target.getAttribute('modal-title') || 'Conferma';
    const text: string = target.getAttribute('modal-text') || 'Si desidera procedere con l\' operazione?';

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    dialogConfig.data = { title, text };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          // perform action
          this.deletePromotion(promotion);
        } 
      });
  }

  createPromotion($event: Event, promotion): void {
    const dialogConfig = new MatDialogConfig();


    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe(data => {
        if (data) {
          // perform action
         
        } else {
          // do nothing
        }
      });
  }


}
