import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatCard, MatCardTitle } from '@angular/material'
import { ModalLeadComponent } from 'app/pages/modal-lead/modal-lead.component';
import { CarLeadsCartService } from '../../services/car-leads-cart.service';
import { Lead } from '../../models/lead';


@Component({
  selector: 'vr-lead-card',
  templateUrl: './lead-card.component.html',
  styleUrls: ['./lead-card.component.scss']
})
export class LeadCardComponent implements OnInit {

  @Input() lead: Lead;
  @Input() sold: boolean;
  @Input() admin: boolean;
  @Input() index: number;
  @Output() action = new EventEmitter<string>();

  selected: boolean = false;

  constructor(
    private dialog: MatDialog,
    private cart: CarLeadsCartService
  ) { }

  ngOnInit() {
    if (this.lead.insurance == 'rc-auto') {
      this.selected = this.cart.includedInChart(this.lead.key);
    }
  }

  addToCart() {
    this.cart.addLead(this.lead.key);
    this.selected = true;
  }

  removeFormCart() {
    this.cart.removeLead(this.lead.key);
    this.selected = false;
  }

  singleLead(key): void {
    //this.router.navigate(['home', 'lead', key]);

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '75%';
    dialogConfig.data = { id: key };

    const dialogRef = this.dialog.open(ModalLeadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      this.updateParent();
    });
  }

  updateParent() {
    this.action.emit('update');
  }


}
