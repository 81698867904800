import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../services/api.service'
import { ToastrService } from 'ngx-toastr';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ConfirmDialogComponent } from '../../core/confirm-dialog/confirm-dialog.component';
@Component({
  selector: 'vr-user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.scss']
})
export class UserPreferencesComponent implements OnInit {

  preferences: any;
  loading: boolean = true;
  subscriptions: Subscription[] = [];
  isFirstTime: boolean = false; // check if is first time user set prefs

  constructor(
    private api: ApiService,
    private toastr: ToastrService,
    private cd: ChangeDetectorRef,
    private errorHandler: ErrorHandlerService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    // get query params from activated route
    this.subscriptions.push(
      this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.isFirstTime = params['ft'] || false;
      })
    );

    this.getPreferences();
  }

  save(data) {
    this.loading = true;
    this.cd.detectChanges();

    this.api.putPreferences(data)
      .subscribe(
        _ => {
          this.toastr.success('', 'Dati salvati correttamente');

          if(this.isFirstTime) {
            this.showFirstTimeModal();
          }

          this.loading = false;
          this.cd.detectChanges();
        },
        error => {
          this.errorHandler.handleError(error);
          this.loading = false;
          this.cd.detectChanges();
        });
  }

  showFirstTimeModal(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '33%';
    dialogConfig.data = { text: "Grazie per aver impostato le tue preferenze, ora controlla i leads che soddisfano i criteri da te impostati.", title: "Vai a lead da acquistare" };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.router.navigate(['home','quick-search']);
        }
      }
    );
  }

  getPreferences() {
    this.api.getPreferences()
      .subscribe(
        data => {
          if (data == null) {
            data = {
              area: [{}]
            };
          }
          this.loading = false;
          this.cd.detectChanges();
          this.preferences = data;
        },
        error => {
          this.loading = false;
          this.cd.detectChanges();
          this.errorHandler.handleError(error);
        });
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
