import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaReplayService } from './utils/media-replay.service';
import { LayoutModule } from './layout/layout.module';
import { AgmCoreModule } from '@agm/core';

@NgModule({
  imports: [
    CommonModule,
    LayoutModule,
    AgmCoreModule,
  ],
  providers: [
    MediaReplayService
  ],
  declarations: []
})
export class CoreModule { }
