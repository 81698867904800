import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthModule } from './auth/auth.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ParamFormComponent } from '../core/param-form/param-form.component';
import { ModalContractComponent } from '../core/modal-contract/modal-contract.component';
import { SearchResultsComponent } from '../core/search-results/search-results.component';
import { RefunLeadComponent } from '../core/refun-lead/refun-lead.component';
import { EditLeadComponent } from '../core/edit-lead/edit-lead.component';
import { ConfirmDialogComponent } from '../core/confirm-dialog/confirm-dialog.component';
import { UserSubscriptionComponent } from '../core/user-subscription/user-subscription.component';
import { BuyLeadComponent } from '../core/buy-lead/buy-lead.component';
import { ListNavigatorComponent } from './list-navigator/list-navigator.component';
import { HeaderComponent } from '../core/header/header.component';
import { AffiliateLeadsComponent } from '../core/affiliate-leads/affiliate-leads.component';
import { PreferencesMapComponent } from '../core/preferences-map/preferences-map.component';
import { LeadPackageComponent } from '../core/lead-package/lead-package.component';
import {
  MatCardModule,
  MatButtonModule,
  MatProgressSpinnerModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatIconModule,
  MatInputModule,
  MatFormFieldModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatRadioModule,
  MatSelectModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatTableModule,
  MatTabsModule,
  MatDialogModule,
  MatTooltipModule,
  MatAutocompleteModule,
  MatProgressBarModule,
  MatChipsModule,
  MatExpansionModule
} from '@angular/material';
import { HomeComponent } from './dashboard/home/home.component';
import { LeadComponent } from './lead/lead.component';
import { LeadSearchComponent } from './lead-search/lead-search.component';
import { NewUserComponent } from './new-user/new-user.component';
import { FormsModule } from '@angular/forms';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { UserPreferencesComponent } from './user-preferences/user-preferences.component';
import { QuickSearchComponent } from './quick-search/quick-search.component';
import { SysadminSearchComponent } from './sysadmin-search/sysadmin-search.component';
import { BoughtLeadsComponent } from './bought-leads/bought-leads.component';
import { RefundListComponent } from './refund-list/refund-list.component';
import { NotBoughtListComponent } from './not-bought-list/not-bought-list.component';
import { InvalidLeadsListComponent } from './invalid-leads-list/invalid-leads-list.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { UserListComponent } from './user-list/user-list.component';
import { CreditCardComponent } from './credit-card/credit-card.component';
import { ChargeCreditCardComponent } from './charge-credit-card/charge-credit-card.component';
import { SoldLeadsListComponent } from './sold-leads-list/sold-leads-list.component';
import { LeadFiltersComponent } from './lead-filters/lead-filters.component';
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { DateAdapterIT, MY_DATE_FORMATS } from '../core/adapters/date-adapter-it';
import { FirstTimeGuideComponent } from './first-time-guide/first-time-guide.component';
import { UserService } from '../services/user.service';
import { CarLeadsCartService } from '../services/car-leads-cart.service';
import { ErrorHandlerService } from '../services/error-handler.service';
import { LoaderComponent } from './loader/loader.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { PayoutComponent } from './payout/payout.component';
import { EditPayoutComponent } from './edit-payout/edit-payout.component';
import { AffiliateStatisticsComponent } from './affiliate-statistics/affiliate-statistics.component'
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { EditSnippetComponent } from './edit-snippet/edit-snippet.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { SafePipe } from './safe.pipe';
import { UserStatisticsComponent } from './user-statistics/user-statistics.component';
import { AgmCoreModule } from '@agm/core';
import { PreferencesStatisticsComponent } from './preferences-statistics/preferences-statistics.component';
import { SellingLeadsComponent } from './selling-leads/selling-leads.component';
import { DeletedLeadsComponent } from './deleted-leads/deleted-leads.component';
import { ModalLeadComponent } from './modal-lead/modal-lead.component';
import { CouponListComponent } from './coupon-list/coupon-list.component';
import { ModalCouponComponent } from './modal-coupon/modal-coupon.component';
import { ModalRedeemCouponComponent } from './modal-redeem-coupon/modal-redeem-coupon.component';
import { PricingTableComponent } from './pricing-table/pricing-table.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { ModalPromotionsComponent } from './modal-promotions/modal-promotions.component';
import { DiscountsComponent } from './discounts/discounts.component';
import { ModalDiscountComponent } from './modal-discount/modal-discount.component';
import { AffiliateUnsoldComponent } from './affiliate-unsold/affiliate-unsold.component';
import { VerifyLeadListComponent } from './verify-lead-list/verify-lead-list.component';
import { HistoryComponent } from './history/history.component';
import { NotVerifiedLeadListComponent } from './not-verified-lead-list/not-verified-lead-list.component';
import { StaticVariablesComponent } from '../core/static-variables/static-variables.component';
import { ReferralComponent } from './referral/referral.component';
import { AllLeadsComponent } from './finance/all-leads/all-leads.component';
import { SmsLeadsComponent } from './finance/sms-leads/sms-leads.component';
import { FinanceBoughtLeadsComponent } from './finance/finance-bought-leads/finance-bought-leads.component';
import { FinaceUsserStatisticsComponent } from './finance/finace-usser-statistics/finace-usser-statistics.component';
import { LeadAutoComponent } from './lead-auto/lead-auto.component';
import { InvalidLeadsComponent } from './finance/invalid-leads/invalid-leads.component';
import { BuyersComponent } from './finance/buyers/buyers.component';
import { LeadCardComponent } from '../core/lead-card/lead-card.component';
import { FileUploadComponent } from '../core/file-upload/file-upload.component';
import { ModalBuyerComponent } from './finance/modal-buyer/modal-buyer.component';
import { FinancePrivacyLeadsComponent } from './finance/finance-privacy-leads/finance-privacy-leads.component';
import { LeadAutoBoughtComponent } from './lead-auto-bought/lead-auto-bought.component';


import { NltAllLeadsComponent } from './nlt/nlt-all-leads/nlt-all-leads.component';
import { NltSmsLeadsComponent } from './nlt/nlt-sms-leads/nlt-sms-leads.component';
import { NltBoughtLeadsComponent } from './nlt/nlt-bought-leads/nlt-bought-leads.component';
import { NltUserStatisticsComponent } from './nlt/nlt-user-statistics/nlt-user-statistics.component';
import { NltInvalidLeadsComponent } from './nlt/nlt-invalid-leads/nlt-invalid-leads.component';
import { NltBuyersComponent } from './nlt/nlt-buyers/nlt-buyers.component';
import { NltModalBuyerComponent } from './nlt/nlt-modal-buyer/nlt-modal-buyer.component';

import { LoanAllLeadsComponent } from './loan/loan-all-leads/loan-all-leads.component';
import { LoanSmsLeadsComponent } from './loan/loan-sms-leads/loan-sms-leads.component';
import { LoanBoughtLeadsComponent } from './loan/loan-bought-leads/loan-bought-leads.component';
import { LoanUserStatisticsComponent } from './loan/loan-user-statistics/loan-user-statistics.component';
import { LoanInvalidLeadsComponent } from './loan/loan-invalid-leads/loan-invalid-leads.component';
import { LoanBuyersComponent } from './loan/loan-buyers/loan-buyers.component';
import { LoanModalBuyerComponent } from './loan/loan-modal-buyer/loan-modal-buyer.component';


import { OtherAllLeadsComponent } from './other/all-leads/all-leads.component';
import { OtherUserStatisticsComponent } from './other/other-user-statistics/other-user-statistics.component';

@NgModule({
  imports: [
    MatCardModule,
    MatChipsModule,
    AgmCoreModule,
    MatFormFieldModule,
    ColorPickerModule,
    NgxChartsModule,
    CommonModule,
    MatProgressSpinnerModule,
    AuthModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    FlexLayoutModule,
    MatDialogModule,
    FormsModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatExpansionModule,
  ],
  declarations: [
    OtherAllLeadsComponent,
    OtherUserStatisticsComponent,
    LoanModalBuyerComponent,
    LoanAllLeadsComponent,
    LoanSmsLeadsComponent,
    LoanBuyersComponent,
    LoanInvalidLeadsComponent,
    LoanUserStatisticsComponent,
    LoanBoughtLeadsComponent,
    NltModalBuyerComponent,
    NltAllLeadsComponent,
    NltSmsLeadsComponent,
    NltBuyersComponent,
    NltInvalidLeadsComponent,
    NltUserStatisticsComponent,
    NltBoughtLeadsComponent,
    FileUploadComponent,
    LeadCardComponent,
    LeadPackageComponent,
    UserSubscriptionComponent,
    HeaderComponent,
    BuyLeadComponent,
    ConfirmDialogComponent,
    RefunLeadComponent,
    EditLeadComponent,
    ParamFormComponent,
    SearchResultsComponent,
    ListNavigatorComponent,
    HomeComponent,
    LeadComponent,
    LeadSearchComponent,
    NewUserComponent,
    UserProfileComponent,
    AdvancedSearchComponent,
    UserPreferencesComponent,
    QuickSearchComponent,
    SysadminSearchComponent,
    BoughtLeadsComponent,
    RefundListComponent,
    NotBoughtListComponent,
    InvalidLeadsListComponent,
    InvoicesComponent,
    UserListComponent,
    CreditCardComponent,
    ChargeCreditCardComponent,
    SoldLeadsListComponent,
    LeadFiltersComponent,
    FirstTimeGuideComponent,
    LoaderComponent,
    ForgotPasswordComponent,
    FeedbackComponent,
    PayoutComponent,
    EditPayoutComponent,
    AffiliateLeadsComponent,
    AffiliateStatisticsComponent,
    EditSnippetComponent,
    SafePipe,
    UserStatisticsComponent,
    PreferencesMapComponent,
    PreferencesStatisticsComponent,
    SellingLeadsComponent,
    DeletedLeadsComponent,
    ModalLeadComponent,
    ModalContractComponent,
    CouponListComponent,
    ModalCouponComponent,
    ModalRedeemCouponComponent,
    PricingTableComponent,
    PromotionsComponent,
    ModalPromotionsComponent,
    DiscountsComponent,
    ModalDiscountComponent,
    AffiliateUnsoldComponent,
    VerifyLeadListComponent,
    HistoryComponent,
    NotVerifiedLeadListComponent,
    StaticVariablesComponent,
    ReferralComponent,
    AllLeadsComponent,
    SmsLeadsComponent,
    FinanceBoughtLeadsComponent,
    FinaceUsserStatisticsComponent,
    LeadAutoComponent,
    InvalidLeadsComponent,
    BuyersComponent,
    ModalBuyerComponent,
    FinancePrivacyLeadsComponent,
    LeadAutoBoughtComponent
  ],
  entryComponents: [
    LoanModalBuyerComponent,
    NltModalBuyerComponent,
    ModalBuyerComponent,
    ConfirmDialogComponent,
    RefunLeadComponent,
    EditLeadComponent,
    ChargeCreditCardComponent,
    ForgotPasswordComponent,
    FirstTimeGuideComponent,
    EditPayoutComponent,
    ModalLeadComponent,
    ModalContractComponent,
    ModalCouponComponent,
    ModalRedeemCouponComponent,
    ModalPromotionsComponent,
    ModalDiscountComponent
  ],
  providers: [
    { provide: DateAdapter, useClass: DateAdapterIT },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    UserService,
    CarLeadsCartService,
    ErrorHandlerService
  ],
  exports: [CreditCardComponent]
})
export class PagesModule { }
