import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../services/api.service'
import { ErrorHandlerService } from '../../services/error-handler.service'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'vr-static-variables',
  templateUrl: './static-variables.component.html',
  styleUrls: ['./static-variables.component.scss']
})
export class StaticVariablesComponent implements OnInit {

  static: any;

  constructor(
    private api: ApiService,
    private errorHanlder: ErrorHandlerService,
    private toastr: ToastrService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.static = {};

    this.api.getStaticValiables().subscribe(
      data => {
        this.static = data;
        this.cd.detectChanges();
      }, error => {
        this.errorHanlder.handleError(error);
      }
    )
  }

  save() {
    this.api.putStaticValiables(this.static).subscribe(
      data => {
        this.toastr.success("Dati salvati correttamenrte");
      }, error => {
        this.errorHanlder.handleError(error);
      }
    );
  }

}
