import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ErrorHandlerService } from '../../services/error-handler.service';

@Component({
  selector: 'vr-affiliate-leads',
  templateUrl: './affiliate-leads.component.html',
  styleUrls: ['./affiliate-leads.component.scss']
})
export class AffiliateLeadsComponent implements OnInit {

  @Input() admin: boolean;
  @Input() chart: boolean = false;
  loading: boolean = true;
  more: boolean = false;

  filters: any = {
    dateStart: new Date(),
    dateEnd: new Date(),
    grouping: 'affiliate',
    property: 'age',
    id: '',
    data: 'total'
  }


  filtersFinance: any = {
    dateStart: new Date(),
    dateEnd: new Date(),
    grouping: 'affiliate',
    property: 'age',
    needToQualify: false,
    id: '',
    data: 'total'
  }

  filtersNLT: any = {
    dateStart: new Date(),
    dateEnd: new Date(),
    grouping: 'affiliate',
    property: 'age',
    id: '',
    data: 'total'
  }

  filtersOther: any = {
    dateStart: new Date(),
    dateEnd: new Date(),
    grouping: 'affiliate',
    property: 'age',
    id: '',
    data: 'total'
  }


  filtersLoan: any = {
    dateStart: new Date(),
    dateEnd: new Date(),
    grouping: 'affiliate',
    property: 'age',
    id: '',
    data: 'total'
  }


  grouping: any = [];
  forms: any = [];
  formsFinance: any = [];
  formsNLT: any = [];
  formsOther: any = [];
  formsLoan: any = [];
  affiliates: any = [];
  total: any = {};
  totalFinance: any = {};
  totalNLT: any = {};
  totalOther: any = {};
  totalLoan: any = {};
  chartData: any = [];
  barChartData: any = [];

  view: any[] = [1200, 400];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = "";
  showYAxisLabel = true;
  yAxisLabel = "";

  colorScheme = {
    domain: ["#00EF9B", "#263238", "#2196F3"]
  };

  chartText: string = '';

  constructor(
    private api: ApiService,
    private cd: ChangeDetectorRef,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit() {
    var date = new Date();
    this.filters.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
    this.filtersFinance.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
    this.filtersNLT.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
    this.filtersOther.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
    this.filtersLoan.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);

    this.reload();
  }

  reload() {
    if (this.admin) {
      this.getAffiliates()
      this.getResults()
    } else {
      this.getResults()
      this.getAffiliateGrouping()
    }

    if (this.chart) {
      this.getChartData();
      this.getBarChartData();
    }
  }

  getAffiliateGrouping(uid?) {
    this.api.getAffiliateGrouping(uid)
      .subscribe(data => {
        this.grouping = data;
        this.cd.detectChanges();
      }, error => {
        this.errorHandler.handleError(error);
      })
  }


  getChartData() {
    var filters = {
      dateStart: new Date(this.filters.dateStart).toLocaleDateString("it-IT").replace(/[\/]/ig, '-'),
      dateEnd: new Date(this.filters.dateEnd).toLocaleDateString("it-IT").replace(/[\/]/ig, '-'),
      grouping: this.filters.grouping,
      data: this.filters.data
    };



    this.api.getChartData(filters).subscribe(data => {
      this.chartData = data;
      this.loadChart(data);
    }, error => {
      this.errorHandler.handleError(error);
    });
  }

  getBarChartData() {
    var filters = {
      dateStart: new Date(this.filters.dateStart).toLocaleDateString("it-IT").replace(/[\/]/ig, '-'),
      dateEnd: new Date(this.filters.dateEnd).toLocaleDateString("it-IT").replace(/[\/]/ig, '-'),
      grouping: this.filters.grouping,
      property: this.filters.property,
      data: this.filters.data
    };

    this.api.getProperyData(filters).subscribe(data => {
      this.barChartData = data;
      this.cd.detectChanges();
    }, error => {
      this.errorHandler.handleError(error);
    });
  }

  getResults() {
    var filters = {
      dateStart: new Date(this.filters.dateStart).toLocaleDateString("it-IT").replace(/[\/]/ig, '-'),
      dateEnd: new Date(this.filters.dateEnd).toLocaleDateString("it-IT").replace(/[\/]/ig, '-'),
      grouping: this.filters.grouping
    };

    var filtersFinance = {
      dateStart: new Date(this.filtersFinance.dateStart).toLocaleDateString("it-IT").replace(/[\/]/ig, '-'),
      dateEnd: new Date(this.filtersFinance.dateEnd).toLocaleDateString("it-IT").replace(/[\/]/ig, '-'),
      grouping: this.filtersFinance.grouping,
      needToQualify: this.filtersFinance.needToQualify,
      filter_type: this.filtersFinance.filter_type,
      filter: this.filtersFinance.filter,
    };

    var filtersNLT = {
      dateStart: new Date(this.filtersNLT.dateStart).toLocaleDateString("it-IT").replace(/[\/]/ig, '-'),
      dateEnd: new Date(this.filtersNLT.dateEnd).toLocaleDateString("it-IT").replace(/[\/]/ig, '-'),
      grouping: this.filtersNLT.grouping,
      filter_type: this.filtersNLT.filter_type,
      filter: this.filtersNLT.filter,
    };

    
    var filtersOther = {
      dateStart: new Date(this.filtersOther.dateStart).toLocaleDateString("it-IT").replace(/[\/]/ig, '-'),
      dateEnd: new Date(this.filtersOther.dateEnd).toLocaleDateString("it-IT").replace(/[\/]/ig, '-'),
      grouping: this.filtersOther.grouping,
      filter_type: this.filtersOther.filter_type,
      filter: this.filtersOther.filter,
    };

    var filtersLoan = {
      dateStart: new Date(this.filtersLoan.dateStart).toLocaleDateString("it-IT").replace(/[\/]/ig, '-'),
      dateEnd: new Date(this.filtersLoan.dateEnd).toLocaleDateString("it-IT").replace(/[\/]/ig, '-'),
      grouping: this.filtersLoan.grouping,
      filter_type: this.filtersLoan.filter_type,
      filter: this.filtersLoan.filter,
    };

    this.api.getAffiliateLeads(filters, this.filters.id)
      .subscribe(data => {
        this.forms = data;
        this.calcTotal();
        this.loading = false;
        this.cd.detectChanges();
      }, error => {
        this.errorHandler.handleError(error);
      })


    this.api.getAffiliateLeadsFinance(filtersFinance, this.filtersFinance.id)
      .subscribe(data => {
        this.formsFinance = data;
        this.calcTotal();
        this.loading = false;
        this.cd.detectChanges();
      }, error => {
        this.errorHandler.handleError(error);
      })


      this.api.getAffiliateLeadsNLT(filtersNLT, this.filtersNLT.id)
      .subscribe(data => {
        this.formsNLT = data;
        this.calcTotal();
        this.loading = false;
        this.cd.detectChanges();
      }, error => {
        this.errorHandler.handleError(error);
      })

      
    this.api.getAffiliateLeadsOther(filtersOther, this.filtersOther.id)
    .subscribe(data => {
      this.formsOther= data;
      this.calcTotal();
      this.loading = false;
      this.cd.detectChanges();
    }, error => {
      this.errorHandler.handleError(error);
    })

    this.api.getAffiliateLeadsLoan(filtersLoan, this.filtersLoan.id)
      .subscribe(data => {
        this.formsLoan = data;
        this.calcTotal();
        this.loading = false;
        this.cd.detectChanges();
      }, error => {
        this.errorHandler.handleError(error);
      })
  }

  getAffiliates() {
    this.api.getAffiliates()
      .subscribe(data => {
        this.affiliates = data;
        this.loading = false;
        this.cd.detectChanges();
      }, error => {
        this.errorHandler.handleError(error);
      })
  }

  calcTotal() {
    this.total = {
      total: 0,
      wrong: 0,
      notIntrested: 0,
      double: 0,
      sold: 0,
      form: 0,
      invalid: 0,
      unsold: 0,
      refunding: 0,
      unreachable: 0,
      selling: 0,
      delete: 0,
      amount: 0
    };

    this.forms.map((value, index) => {
      this.total.total += value.total;
      this.total.selling += value.selling;
      this.total.wrong += value.wrong;
      this.total.unreachable += value.unreachable;
      this.total.notIntrested += value.notIntrested;
      this.total.double += value.double;
      this.total.sold += value.sold;
      this.total.invalid += value.invalid;
      this.total.unsold += value.unsold;
      this.total.delete += value.delete;
      this.total.refunding += value.refunding;
      this.total.amount += value.amount;
    });


    this.totalFinance = {
      total: 0,
      wrong: 0,
      notIntrested: 0,
      double: 0,
      sold: 0,
      form: 0,
      invalid: 0,
      delete: 0,
      amount: 0
    };

    this.formsFinance.map((value, index) => {
      this.totalFinance.total += value.total;
      this.totalFinance.sold += value.sold;
      this.totalFinance.delete += value.delete;
      this.totalFinance.double += value.double;
      this.totalFinance.invalid += value.invalid;
      this.totalFinance.amount += value.amount;
    });



    this.totalNLT = {
      total: 0,
      wrong: 0,
      notIntrested: 0,
      double: 0,
      sold: 0,
      form: 0,
      invalid: 0,
      delete: 0,
      amount: 0
    };

    this.formsNLT.map((value, index) => {
      this.totalNLT.total += value.total;
      this.totalNLT.sold += value.sold;
      this.totalNLT.delete += value.delete;
      this.totalNLT.double += value.double;
      this.totalNLT.invalid += value.invalid;
      this.totalNLT.amount += value.amount;
    });

    this.totalOther = {
      sold: 0,
      amount: 0
    };

    this.formsOther.map((value, index) => {
      this.totalOther.sold += value.sold;
    });


    this.totalLoan = {
      total: 0,
      wrong: 0,
      notIntrested: 0,
      double: 0,
      sold: 0,
      form: 0,
      invalid: 0,
      delete: 0,
      amount: 0
    };

    this.formsLoan.map((value, index) => {
      this.totalLoan.total += value.total;
      this.totalLoan.sold += value.sold;
      this.totalLoan.delete += value.delete;
      this.totalLoan.double += value.double;
      this.totalLoan.invalid += value.invalid;
      this.totalLoan.amount += value.amount;
    });


  }

  loadChart(data) {
    var grouping = {
      affiliate: "per form ID",
      url: "per URL"
    }

    var what = {
      sold: "Leads venduti",
      invalid: "Leads invalidi",
      total: "Leads totali",
      amount: "Guadagni"
    }

    this.chartText = what[this.filters.data] +
      ' dal ' + new Date(this.filters.dateStart).toLocaleDateString("it-IT").replace(/[\/]/ig, '-') +
      ' al ' + new Date(this.filters.dateEnd).toLocaleDateString("it-IT").replace(/[\/]/ig, '-') +
      ' ' + grouping[this.filters.grouping];


    this.cd.detectChanges();

  }

}
