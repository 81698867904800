import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api.service'
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { Lead } from '../../models/lead';
import { User } from '../../models/user';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'vr-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  User: User; // current user
  CurrentUser: User; // current user
  isCurrentUser: boolean = false; // true if current detail is for current user
  errorPassword: boolean = false; // true if password set is wrong
  errorRegEx: boolean = false; // true if password needs to match
  loading: boolean = true; // true if script is loading something
  password: any = { password: '', confirmPassword: '' };
  regEx: any;
  hide: boolean = true;
  pageNumber: number = 0;
  movement: any = [];
  params: any = {
    perPage: 100,
    pageNumber: 0,
    loading: false
  }
  userPreferences: any;
  users: any = [];
  userId: string; 
  leads: Lead[];
  pageNumberLead = 0;

  @ViewChild('creditCard') cc;

  constructor(
    private api: ApiService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private errorHandler: ErrorHandlerService,
    private userService: UserService
  ) { }

  ngOnInit() {
    // check if route has id
    this.User = new User();
    this.CurrentUser = new User();
    this.route.params.subscribe(params => {
      let id = params['id']; // (+) converts string 'id' to a number
      this.userId = params['id'];
      this.isCurrentUser = id ? false : true;
      this.api.getUser(id)
        .subscribe(user => {
          // set if user is current or not, according with route param
          if (typeof user.current !== 'undefined') {
            user.current = this.isCurrentUser;
          } else {
            user = Object.assign({ current: this.isCurrentUser }, user);
          }
          this.loading = false;
          this.User = user;

          if (this.User.smsNotifications == undefined) {
            this.User.smsNotifications = true;
          }

          if (this.User.emailNotifications == undefined) {
            this.User.emailNotifications = true;
          }


          this.api.getUser()
            .subscribe(user => {
              this.CurrentUser = user;
              this.changeDetector.detectChanges();
              if (this.CurrentUser.userType == "admin") {
                this.loadHistory();
                this.getPreferences();
                this.searchLeads(this.params)
              }
            }, error => {
              this.loading = false;
              this.errorHandler.handleError(error);
              this.changeDetector.detectChanges();
            });


          this.changeDetector.detectChanges();
        }, error => {
          this.loading = false;
          this.errorHandler.handleError(error);
          this.changeDetector.detectChanges();
        });


      this.api.getReferred(id)
        .subscribe(data => {
          this.users = data;
          this.changeDetector.detectChanges();
        }, error => {
          this.loading = false;
          this.errorHandler.handleError(error);
          this.changeDetector.detectChanges();
        });



    });




    this.regEx = {
      charNum: false,
      lowChar: false,
      hiChar: false,
      number: false
    };
  }

  /**
   * Check inserted pwd
   */
  checkPasswords() {
    this.regEx = {
      charNum: false,
      lowChar: false,
      hiChar: false,
      number: false
    };


    var regexp = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/);
    var test = regexp.test(this.password.password);

    if (!test) {
      this.errorRegEx = true;
    } else {
      this.errorRegEx = false;
    }

    //regex for charNumber
    regexp = new RegExp(/^.{8,}/);
    this.regEx.charNum = regexp.test(this.password.password);

    //regex for low character
    regexp = new RegExp(/^(.*[a-z])/);
    this.regEx.lowChar = regexp.test(this.password.password);


    //regex for high character
    regexp = new RegExp(/^(.*[A-Z])/);
    this.regEx.hiChar = regexp.test(this.password.password);


    //regex for number
    regexp = new RegExp(/^(.*[0-9])/);
    this.regEx.number = regexp.test(this.password.password);

    
  }

  /**
   * save current detail
   */
  save() {

    if (!this.User.account) {
      this.User.account = this.User.companyName;
    }

    if (this.errorPassword || this.errorRegEx) {
      return
    }

    delete this.User.confirmPassword;

    // update user, using the user key
    this.api.putUser(this.User, (!this.isCurrentUser) ? this.User.key : '').subscribe(
      data => {
        delete this.User.password;
        // if(this.cc) this.cc.save();
        if (this.isCurrentUser && this.userService) this.userService.onChange(this.User); // propagate event to update user reference everywhere
        this.toastr.success('', 'Dati salvati correttamente');
        this.changeDetector.detectChanges();
      },
      error => {
        delete this.User.password;
        this.errorHandler.handleError(error);
      });
  }


  savePassword() {
    if (this.password.password.length == 0) {
      this.errorRegEx = false;
      this.errorPassword = false;
      delete this.password.password;
    }


    // update user, using the user key
    this.api.setPassword(this.password.password).subscribe(
      data => {
        delete this.password.password;
        delete this.password.confirmPassword;
        this.toastr.success('', 'Dati salvati correttamente');
      },
      error => {
        this.errorHandler.handleError(error);
      });
  }

  //----------------------------------- ALL HISTORY FUNCTIONS

  loadHistory() {
    var data = {
      perPage: 20,
      pageNumber: this.pageNumber
    }
    this.api.getHistory(this.User.key, data)
      .subscribe(data => {
        this.movement = data;
        this.changeDetector.detectChanges();
      },
        error => {
          delete this.User.password;
          this.errorHandler.handleError(error);
        });
  }

  nextPage() {
    this.loadHistory();
    this.pageNumber++;
    this.loading = true;
  }

  prevPage() {
    this.loadHistory();
    this.pageNumber--;
    this.loading = true;
  }


  getAction(action) {
    switch (action) {
      case "nextPage":
        this.nextPage();
        break;

      case "prevPage":
        this.prevPage();
        break;

      default:
        break;
    }
  }

  savePreferences(data) {
    this.changeDetector.detectChanges();
    
    this.api.putPreferences(data, this.userId)
      .subscribe(
        _ => {
          this.toastr.success('', 'Dati salvati correttamente');

          this.loading = false;
          this.changeDetector.detectChanges();
        },
        error => {
          this.errorHandler.handleError(error);
          this.loading = false;
          this.changeDetector.detectChanges();
        });
  }

  getPreferences() {
    this.api.getPreferences(this.userId)
      .subscribe(
        data => {
          if (data == null) {
            data = {
              area: [{}]
            };
          }
          this.loading = false;
          this.changeDetector.detectChanges();
          this.userPreferences = data;
        },
        error => {
          this.loading = false;
          this.changeDetector.detectChanges();
          this.errorHandler.handleError(error);
        });
  }




  //-------------------------------------- GET LEADS BY USER


  searchLeads(leadsParams) {
    
    this.params.loading = true;
    this.api.getBoughtLeads(leadsParams, this.User.key).subscribe(
      data => {
        this.leads = data as Lead[];
        this.changeDetector.detectChanges();
        this.params.loading = false;
      },
      error => {
        this.errorHandler.handleError(error);
        this.params.loading = false;
      });

  }

  /**
   * Filter on filter passed
   */
  filter(action): void {
    this.params = {
      perPage: this.params.perPage,
      pageNumber: this.params.pageNumber
    }
    Object.assign(this.params,action.filters);
    this.searchLeads(this.params);
  }

  getActionLead(action) {
    
    switch (action) {
      case 'nextPage':
        this.params.pageNumber++;
        this.searchLeads(this.params);
        break;

      case 'prevPage':
        this.params.pageNumber--;
        this.searchLeads(this.params);
        break;
      case "showResults":
        this.searchLeads(this.params);
        break;
      default:
        break;
    }

  }

}