import { Component, OnInit, ChangeDetectorRef, Input, Output } from '@angular/core';
import { ApiService } from '../../services/api.service'
import { ErrorHandlerService } from '../../services/error-handler.service'
import { saveAs } from 'file-saver';

@Component({
  selector: 'vr-lead-package',
  templateUrl: './lead-package.component.html',
  styleUrls: ['./lead-package.component.scss']
})
export class LeadPackageComponent implements OnInit {
  leads: any;
  expand: boolean = false;
  @Input() leadPackage: any;

  constructor(
    private api: ApiService,
    private ref: ChangeDetectorRef,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit() {
  }

  show() {
    this.expand = true;
    if (!this.leads) {
      this.getPackageDetail(this.leadPackage.key);
    }
  }

  hide() {
    this.expand = false;
  }

  getPackageDetail(key) {
    this.api.getPackage(key).subscribe(
      data => {
        this.leads = data;
        this.ref.detectChanges();
      }, error => {
        this.errorHandler.handleError(error);
      })
  }


  downloadCSV() {
    this.api.getPackageCSV(this.leadPackage.key).subscribe(
      data => {
        var blob = new Blob([data], { type: 'text/csv' });
        saveAs(blob, "leads.csv");

      }, error => {
        this.errorHandler.handleError(error);
      })
  }

}
