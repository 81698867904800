import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../services/api.service'
import { CarLeadsCartService } from '../../services/car-leads-cart.service'
import { ErrorHandlerService } from '../../services/error-handler.service'
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { MatDialog, MatDialogConfig } from '@angular/material';
import { ChargeCreditCardComponent } from '../charge-credit-card/charge-credit-card.component';
import { ModalRedeemCouponComponent } from '../modal-redeem-coupon/modal-redeem-coupon.component';
import { ConfirmDialogComponent } from '../../core/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'vr-lead-auto',
  templateUrl: './lead-auto.component.html',
  styleUrls: ['./lead-auto.component.scss']
})
export class LeadAutoComponent implements OnInit {

  showResults: boolean = false;
  maxNumber: number = 0;
  numbers: any = [];
  carLeads: any = [];
  leads: any = [];
  params: any = {};
  data: any = {};
  showButton: boolean = false;
  packages: any = [];
  affiliates: any = [];
  affiliate: string;
  user: any = {};


  sort: string = "time_sold";
  order: string = "desc";
  query: string;
  perPage: number = 30;
  pageNumber: number = 0;
  loading: boolean = false;
  leadCart: any = [];
  searchParams: any;

  constructor(
    private toast: ToastrService,
    private router: Router,
    private api: ApiService,
    private cart: CarLeadsCartService,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit() {
    this.api.getUser().subscribe(
      data => {

        this.user = data;

        if (this.user.userType != "admin") {
          this.getPackages();
        } else {
          this.getAffiliates();
        }
      }
    )

    this.cart.cartChangeEmitter().subscribe( data => {
      this.leadCart = data;
      this.data.price = this.leadCart.length *3;
      this.data.leads = this.leadCart;
      
      console.log("CART UPDATED",this.leadCart);
    })

  }

  setSearch(params) {
    this.params.area = params.area;
    this.searchParams = params;
    this.search(this.searchParams);

  }

  search(params) {
    this.numbers = [];

    this.searchParams.perPage = this.perPage;
    this.searchParams.pageNumber = this.pageNumber;
    this.searchParams.query = this.query;
    this.searchParams.sort = this.sort;
    this.searchParams.order = this.order;

    this.api.checkLeadsCar(params)
      .subscribe(data => {
        this.showResults = true;
        this.leads = data;

        this.ref.detectChanges();
      }, error => {
        this.errorHandler.handleError(error);
      });
  }

  changePrice() {
    this.data.price = this.params.number * 3;
  }

  getActionBuy(event) {

    if (event == "Success") {
      this.search({ area: this.params.area });
      this.cart.cleanCart();
      this.showButton = true;
    }

  }

  getPackages() {
    this.api.getPackages().subscribe(
      data => {
        this.packages = data;
        this.ref.detectChanges();
      }, error => {
        this.errorHandler.handleError(error);
      })
  }

  clearCart(){
    this.cart.cleanCart();
    this.search({ area: this.params.area });
  }


  goTo(destination) {
    this.router.navigate(['/' + destination]);
  }

  changeListener($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
    myReader.onloadend = () => {
      //myReader.result is a String of the uploaded file
      this.carLeads = this.csvToJSON(myReader.result)
      //fileString = myReader.result would not work, 
      //because it is not in the scope of the callback
    }

    myReader.readAsText(file);
  }

  csvToJSON(csv) {
    var lines = csv.split("\r\n");
    var result = [];
    var headers = lines[0].split(";");
    for (var i = 1; i < lines.length - 1; i++) {
      var obj = {};
      var currentline = lines[i].split(";");
      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }
      result.push(obj);
    }

    return result;
  }



  getAffiliates() {
    this.api.getAffiliates()
      .subscribe(data => {
        this.affiliates = data;
        this.ref.detectChanges();
      }, error => {
        this.errorHandler.handleError(error);
      })
  }

  openModal($event: Event): void {
    const dialogConfig = new MatDialogConfig();
    let target: HTMLElement = $event.target as HTMLElement || new HTMLElement(); // the dialog target



    const title: string = target.getAttribute('modal-title') || 'Campi leads RC-Auto';
    const text: string = target.getAttribute('modal-text') || 'ALIMENTAZIONE / ANNO_ACQUISTO / ANNO_IMMATRICOLAZIONE / CAP_RESIDENZA / CELLULARE / CILINDRATA / CODICE_FISCALE / COGNOME / COMPAGNIA_ATTUALE / COMUNE_NASCITA / COMUNE_RESIDENZA / DATA_DECORRENZA_INSERITA / DATA_INSERIMENTO / DATA_NASCITA / EMAIL / ETA’ / MARCA / MODALITA_PAGAMENTO / MODELLO / NAZIONE_NASCITA / NOME / NUMERO_CIVICO / PERCORRENZA / POSTO_AUTO / POTENZA_FISCALE /     PRODOTTO / PROFESSIONE / PROVINCIA_NASCITA /     PROVINCIA_RESIDENZA / REGIONE_RESIDENZA / SESSO / SITUAZIONE_ASSICURATIVA / STATO_CIVILE / TARGA / TIPO_CARROZZERIA / TIPO_SEGMENTO / TITOLO_STUDIO / TOPONIMO / VEICOLO_UTILIZZO';

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    dialogConfig.data = { title, text };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe(isConfirmed => {

      });
  }

  uploadCarLeads() {


    this.carLeads.map(lead => {
      var upLead = {
        name: lead.NOME,
        surname: lead.COGNOME,
        email: lead.EMAIL,
        phone: lead.CELLULARE,
        job: lead.PROFESSIONE,
        age: lead.ETA,
        gender: (lead.SESSO == "Femmina") ? 'F' : 'M',
        insurance: 'rc-auto',
        aff_ref: "form rc-auto",
        affiliate: this.affiliate,
        hour: '',
        url: '',
        host: 'https://www.6sicuro.it/',
        cap: lead.CAP_RESIDENZA,
        city: lead.COMUNE_RESIDENZA,
        extraData: {}
      }


      var excludedKeys = ['MODALITA_PAGAMENTO', 'CALCOLO_ID', 'NOME', 'COGNOME', 'EMAIL', 'CELLULARE', 'PROFESSIONE', 'ETA', 'SESSO', 'CAP_RESIDENZA', 'COMUNE_RESIDENZA',
        'PROVINCIA_RESIDENZA', 'PROVINCIA_NASCITA', 'NAZIONE_RESIDENZA', 'REGIONE_RESIDENZA', 'TOPONIMO', , 'NUMERO_CIVICO', 'DATA_INSERIMENTO'];

      upLead.extraData['INDIRIZZO'] = lead['VIA'] + " " + lead["TOPONIMO"];
      Object.keys(lead).map(key => {
        if (!excludedKeys.includes(key)) {
          upLead.extraData[key] = lead[key];
        }
      });




      this.api.postLead(upLead).subscribe(
        data => {
          this.toast.success("Caricamento effettuato");
        },
        error => { this.errorHandler.handleError(error) }
      )

    })

  }
}
