import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/user.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ApiService } from '../../services/api.service'

@Component({
  selector: 'vr-modal-redeem-coupon',
  templateUrl: './modal-redeem-coupon.component.html',
  styleUrls: ['./modal-redeem-coupon.component.scss']
})
export class ModalRedeemCouponComponent implements OnInit {

  key: string;
  loading: boolean = true;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ModalRedeemCouponComponent>,
    private toastr: ToastrService,
    private errorHandler: ErrorHandlerService,
    private api: ApiService,
    private user: UserService) { }

  ngOnInit() {
  }

  redeem() {
    this.loading = false;
    this.api.redeemCoupon(this.key).subscribe(
      data => {
        var value = data.value;
        this.toastr.success('', 'Coupon da ' + value + '€ riscattato!');
        this.dialogRef.close(value);

      }, error => {
        this.errorHandler.handleError(error, 'coupon');
        this.dialogRef.close(false);
      }
    )
  }


  close() {
    this.dialogRef.close(false);
  }

}
