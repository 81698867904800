import { Component, OnInit, EventEmitter, Input, Output, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Lead } from '../../models/lead';
import { LeadDecodeService } from '../../services/lead-decode.service';
import { ApiService } from '../../services/api.service';
import { MatTableDataSource } from '@angular/material';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ModalLeadComponent } from '../../pages/modal-lead/modal-lead.component';
import { ConfirmDialogComponent } from '../../core/confirm-dialog/confirm-dialog.component';
import { EditLeadComponent } from '../../core/edit-lead/edit-lead.component';

@Component({
  selector: 'vr-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit {
  @Input() leadList: Lead[];
  @Input() perPage: number;
  @Input() backButton = false;
  @Input() showReduce = false;
  @Input() admin = false;
  @Input() sold = false;
  @Input() pageNumber: number;
  @Input() embed?: boolean = false;
  @Input() car?: boolean = false;
  @Input() loading?: boolean = false;
  @Input() finance?: boolean = false;
  @Input() nlt?: boolean = false;
  @Input() other?: boolean = false;
  @Input() loan?: boolean = false;
  @Input() affiliate?: boolean = false;
  @Input() showFilters?: boolean;

  @Output() selectedLead = new EventEmitter<object>();
  @Output() action = new EventEmitter<string>();
  @Output() searchAction = new EventEmitter<any>();

  showNext = true;
  showPrev = false;
  reduced = false;

  dataSource: MatTableDataSource<any>;

  timer: any;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private leadDecode: LeadDecodeService,
    private router: Router,
    private dialog: MatDialog,
    private api: ApiService
  ) { }

  ngOnInit() {

    this.loading = true;
    if (!this.leadList.length) {
      this.leadList = [];
      this.showNext = false;
      this.showPrev = false;
    }
    if (this.admin) {
      this.reduced = true;
    }
  }

  ngOnChanges() {
    if (!this.leadList || !this.leadList.length) {
      this.leadList = [];
      return;
    }
    
    if (this.leadList.length < this.perPage) {
      this.showNext = false;
    } else {
      this.showNext = true;
    }

    if (this.pageNumber === 0) {
      this.showPrev = false;
    } else {
      this.showPrev = true;
    }
    this.dataSource = new MatTableDataSource(this.leadList);

    this.leadList.map((lead) => {
      lead.age = this.leadDecode.decodeAge(lead.age);
      lead.insuranceVisual = this.leadDecode.decodeInsurance(lead.insurance);
      lead.job_visual = this.leadDecode.decodeJob(lead.job);

      if (lead.motivation) {
        lead.visualStatus = this.leadDecode.decodeStatus(lead.status) + " (" + this.leadDecode.decodeMotivation(lead.motivation) + ")";
      } else {
        lead.visualStatus = this.leadDecode.decodeStatus(lead.status)
      }

      lead.visualDeal = this.leadDecode.decodeDeal(lead.deal);

      return lead;
    })
    this.loading = false;
    this.changeDetector.detectChanges();
  }

  nextPage() {
    this.action.emit('nextPage');
    this.pageNumber++;
    this.loading = true;
  }

  prevPage() {
    this.action.emit('prevPage');
    this.pageNumber--;
    this.loading = true;
  }

  showResults() {
    this.action.emit('showResults');
    this.loading = true;
  }

  singleLead(key): void {
    //this.router.navigate(['home', 'lead', key]);

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '75%';
    dialogConfig.data = { id: key };

    const dialogRef = this.dialog.open(ModalLeadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      this.action.emit('update');
    });
  }


  editLeadFinance(lead) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '70%';
    dialogConfig.data = {lead, type:'finance'};
    const dialogRef = this.dialog.open(EditLeadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      this.action.emit('showResults');
     });
  }



  
  editLeadNLT(lead) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '70%';
    dialogConfig.data = {lead, type:'NLT'};
    const dialogRef = this.dialog.open(EditLeadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      this.action.emit('showResults');
     });
  }


  
  
  editLeadOther(lead) {
    
    lead.needToSend = true;
    this.api.putLeadOther(lead).subscribe(data => {
      this.action.emit('showResults');
     });
  }

  
  
  editLeadLoan(lead) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '70%';
    dialogConfig.data = {lead, type:'Loan'};
    const dialogRef = this.dialog.open(EditLeadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      this.action.emit('showResults');
     });
  }






  openDialog(): void {
    //this.router.navigate(['home', 'lead', key]);

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '45%';
    dialogConfig.data = { text: "I leads presenti sulla piattaforma da più di 72 ore vengono proposti con uno sconto pari ad 1/3 del loro prezzo originale.  Questo non vuol dire che abbiano meno valore, perchè l'esigenza assicurativa dell'utente può essere ancora presente.", title: "Sconto" };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {

    });
  }



  search(event): void {
    if (this.searchAction) {
      this.searchAction.emit(event);
    } else {
      console.warn('No search action set, cannot proceed with filter search');
    }
  }

  getAction(action) {
    switch (action) {
      case "nextPage":
        this.nextPage();
        break;

      case "prevPage":
        this.prevPage();
        break;

      case "showResults":
        this.showResults();
        break;

      default:
        break;
    }
  }

  getActionBuy(action) {
    switch (action) {
      case "Success":
        this.action.emit('update');
        break;

      default:
        break;
    }
  }

}

