import { Component, Input, Output, EventEmitter, OnInit, NgModule } from '@angular/core';

@Component({
  selector: 'vr-list-navigator',
  templateUrl: './list-navigator.component.html',
  styleUrls: ['./list-navigator.component.scss']
})

export class ListNavigatorComponent implements OnInit {
  @Input() data: any[];
  @Input() loading: boolean;
  @Input() backButton = false;
  @Input() showPrev = false;
  @Input() showNext = false;
  @Input() pageNumber: number;
  @Input() perPage?: number;
  @Input() total?: number;
  @Input() reversed?: boolean = false; // true if layout is inverted
  @Output() action = new EventEmitter<string>();
  finalNumber: number = 0;
  constructor(

  ) { }

  ngOnInit() {
    if (this.total) {
      if (this.total >= this.perPage) {
        this.finalNumber = (this.pageNumber + 1) * this.perPage
      } else {
        this.finalNumber = (this.pageNumber) * this.perPage + this.total;
      }

    }
    
  }

  nextPage() {
    this.action.emit('nextPage');
  }

  prevPage() {
    this.action.emit('prevPage');
  }

  showResults() {
    this.action.emit('showResults');
  }
}
