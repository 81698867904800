import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ApiService } from '../../services/api.service'

@Component({
  selector: 'vr-modal-promotions',
  templateUrl: './modal-promotions.component.html',
  styleUrls: ['./modal-promotions.component.scss']
})
export class ModalPromotionsComponent implements OnInit {
  id: string;
  promotion: any;
  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ModalPromotionsComponent>,
    private toastr: ToastrService,
    private errorHandler: ErrorHandlerService,
    private api: ApiService,
    @Inject(MAT_DIALOG_DATA) data) {
    if (data.id) {
      this.id = data.id;
    }
  }

  ngOnInit() {
    this.promotion = {};
    if (this.id) {
      this.getPromotion();
    }
  }

  getPromotion() {
    this.api.getPromotion(this.id).subscribe(
      data => {
        this.promotion = data;

        this.promotion.dateStart = new Date(this.promotion.dateStart);
        this.promotion.dateEnd = new Date(this.promotion.dateEnd)
      }, error => {
        this.errorHandler.handleError(error);
      }
    )
  }

  save() {
    if (this.id) {
      this.updatePromotion();
    } else {
      this.savePromotion();
    }
  }

  savePromotion() {
    this.promotion.dateStart = new Date(this.promotion.dateStart).toLocaleDateString("it-IT").replace(/[\/]/ig, '-');
    this.promotion.dateEnd = new Date(this.promotion.dateEnd).toLocaleDateString("it-IT").replace(/[\/]/ig, '-');
    this.api.postPromotion(this.promotion).subscribe(
      data => {
        this.toastr.success("", "Dati salvati correttamente");
        this.dialogRef.close(true);
      }, error => {
        this.errorHandler.handleError(error);
      }
    )
  }

  updatePromotion() {
    this.promotion.dateStart = new Date(this.promotion.dateStart).toLocaleDateString("it-IT").replace(/[\/]/ig, '-');
    this.promotion.dateEnd = new Date(this.promotion.dateEnd).toLocaleDateString("it-IT").replace(/[\/]/ig, '-');
    this.api.putPromotion(this.promotion, this.id).subscribe(
      data => {
        this.toastr.success("", "Dati salvati correttamente");
        this.dialogRef.close(true);
      }, error => {
        this.errorHandler.handleError(error);
      }
    )
  }

  close() {
    this.dialogRef.close(false);
  }


}
