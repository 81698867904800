import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { EditPayoutComponent } from '../edit-payout/edit-payout.component'
import { ErrorHandlerService } from '../../services/error-handler.service'

@Component({
  selector: 'vr-payout',
  templateUrl: './payout.component.html',
  styleUrls: ['./payout.component.scss']
})
export class PayoutComponent implements OnInit {

  payouts: any = [];
  pageNumber: number = 0;
  perPage: number = 12;
  loading: boolean = true;
  showNext = true;
  showPrev = false;
  admin = false;
  params: any;

  generateMonth : any = {
    month: 0,
    year: 2019
  }

  months: any = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre"
  ]


  constructor(
    private changeDetector: ChangeDetectorRef,
    private dialog: MatDialog,
    private api: ApiService,
    private errorHandler: ErrorHandlerService
  ) {
    this.params = {
      perPage: this.perPage,
      pageNumber: this.pageNumber
    }
    var date = new Date();

    this.generateMonth.month = date.getMonth();
    this.generateMonth.year = date.getFullYear();

  }

  ngOnInit() {
    this.getPayouts(this.params);
    this.api.getUser().subscribe(data => {
      if (data.userType == 'admin') {
        this.admin = true;
        this.changeDetector.detectChanges();
      }
    });
  }

  getAction(action) {
    
    switch (action) {
      case 'nextPage':
        this.params.pageNumber++;
        this.getPayouts(this.params);
        break;

      case 'prevPage':
        this.params.pageNumber--;
        this.getPayouts(this.params);
        break;

      default:
        
        break;
    }

  }

  getPayouts(params) {
    this.api.getPayouts(params).subscribe(
      data => {
        this.payouts = data;
        this.loading = false;

        if (this.params.pageNumber == 0) {
          this.showPrev = false;
        } else {
          this.showPrev = true;
        }

        if (this.payouts.length < this.perPage) {
          this.showNext = false;
        } else {
          this.showNext = true;
        }


        this.changeDetector.detectChanges();
      }
    )
  }

  edit(payout){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    dialogConfig.data = payout;

    const dialogRef = this.dialog.open(EditPayoutComponent, dialogConfig);
  }

  generatePayouts(){
    this.api.generatePayouts(this.generateMonth).subscribe(
      success => {
        this.getPayouts(this.params);
      }, error => {
        this.errorHandler.handleError(error);
      }
    )
  }

}
