import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vr-affiliate-statistics',
  templateUrl: './affiliate-statistics.component.html',
  styleUrls: ['./affiliate-statistics.component.scss']
})
export class AffiliateStatisticsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
