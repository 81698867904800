export class Lead {
  age?: string;
  insurance?: string;
  name?: string;
  first_name?: string;
  last_name?: string;
  full_name?: string;
  phone_number?: string;
  surname?: string;
  gender?: string;
  job?: string;
  job_visual?: string;
  city?: string;
  district?: string;
  region?: string;
  zipcode?: string;
  key?: string;
  motivation?: string;
  status?: string;
  visualStatus?: string;
  visualDeal?: string;
  email?: string;
  phone?: string;
  time?: boolean;
  adminComment?: string;
  manualVerify?: boolean;
  latitude?: number;
  longitude?: number;
  sendNotification?: boolean;
  insuranceVisual?: string;
  hometown?: any;
  deal?: {
    success: string;
    comments: string;
  };
}
