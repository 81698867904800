import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { UserService } from '../../services/user.service';
import { LeadDecodeService } from '../../services/lead-decode.service';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { EditLeadComponent } from '../../core/edit-lead/edit-lead.component';
import { RefunLeadComponent } from '../../core/refun-lead/refun-lead.component';
import { ConfirmDialogComponent } from '../../core/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';

@Component({
  selector: 'vr-app-lead',
  templateUrl: './lead.component.html',
  styleUrls: ['./lead.component.scss']
})
export class LeadComponent implements OnInit {

  leadId: string;
  lead: any = {};
  brokers: any = [];
  rowLead: any;
  user: any;
  loading: boolean = true;
  owner: any;
  modal: boolean = false;
  soldDays: number = 0;

  constructor(
    private userService: UserService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private api: ApiService,
    private leadDecode: LeadDecodeService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private errorHandler: ErrorHandlerService
  ) {
  }

  ngOnInit() {
    this.lead = {};
    this.user = {};

    this.route.params.subscribe(params => {
      this.leadId = params['id']; // (+) converts string 'id' to a number
      this.getLead();
    });


    this.api.getUser().subscribe(user => {
      this.user = user;
    }, error => {
      this.errorHandler.handleError(error);
    });
  }

  getUser(id?: string) {
    this.api.getUser(id)
      .subscribe(user => {
        this.owner = user;
        this.changeDetector.detectChanges();
      }, error => {
        this.errorHandler.handleError(error);
      })
  }

  downloadCSV() {
    this.api.getSingleLeadsCSV(this.leadId).subscribe(
      data => {
        var blob = new Blob([data], { type: 'text/csv' });
        saveAs(blob, this.lead.name + '_' + this.lead.surname + ".csv");
      })
  }

  getLead() {
    this.loading = true;
    this.api.getLead(this.leadId)
      .subscribe(
        data => {
          this.rowLead = data;
          data.age = this.leadDecode.decodeAge(data.age);
          data.insurance = this.leadDecode.decodeInsurance(data.insurance);
          data.job = this.leadDecode.decodeJob(data.job);
          data.hour = (data.hour) ? this.leadDecode.decodeTime(data.hour) : 'Non specificato';
          this.lead = data;

          if (this.lead.time_sold) {
            var time = Date.now();
            this.soldDays = (time - this.lead.time_sold) / (1000 * 60 * 60 * 24)
          }

          if (this.user.userType == 'admin' && (this.lead.status === 'sold' || this.lead.status === 'refunding')) {
            this.getUser(this.lead.owner);
          }

          this.lead.extra = this.leadDecode.decodeExtraData(this.lead.extraData);

          this.loading = false;
          this.changeDetector.detectChanges();
        }, error => {
          this.errorHandler.handleError(error);
        })
  }

  editDeal() {
    this.api.editDeal(this.lead.deal, this.leadId)
      .subscribe(data => {
        this.userService.updateWallet();
      }, error => {
        this.errorHandler.handleError(error);
      })
  }

  getMatch(id) {
    this.api.getMatches(id)
      .subscribe(brokers => {
        this.brokers = brokers;
        this.changeDetector.detectChanges();
      }, error => {
        this.errorHandler.handleError(error);
      })
  }

  buyButton(result) {
    switch (result) {
      case 'Success':
        this.getLead();
        break;

      default:
        break;
    }
  }

  openRefundDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    const dialogRef = this.dialog.open(RefunLeadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.api.refundLead(this.lead.key, data)
            .subscribe(
              success => {
                this.getLead();
                this.toastr.success('Verrai notificato non appena la tua richiesta verrà elaborata', 'Richiesta inoltrata');
              }, error => {
                this.errorHandler.handleError(error);
              }
            );
        }
      }
    );
  }

  openEditDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    dialogConfig.data = { lead: this.rowLead, type: 'insurance' };
    const dialogRef = this.dialog.open(EditLeadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => { });
  }


  /**
   * Open an arbitrary confirm dialog according with button action data
   * @param $event the event triggered
   */
  openConfirmDialog($event: Event): void {
    const dialogConfig = new MatDialogConfig();
    let target: HTMLElement = $event.target as HTMLElement || new HTMLElement(); // the dialog target

    if (target.getAttribute('type') !== 'button' && target.parentElement.getAttribute('type') === 'button') {
      // if current target is not a button check for parent, the target MUST be a button
      target = target.parentElement;
    }

    const title: string = target.getAttribute('modal-title') || 'Conferma';
    const text: string = target.getAttribute('modal-text') || 'Si desidera procedere con l\' operazione?';
    const action: any = target.getAttribute('modal-action') || null;

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    dialogConfig.data = Object.assign({ title, text, action }, this.rowLead);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          // perform action
          if (this[action]) {
            this[action]();
          }
        } else {
          // do nothing
        }
      });
  }

  moveLead(): void {
    // set lead status to selling
    this.rowLead.status = 'selling';
    this.api.editLead(this.rowLead)
      .subscribe(
        success => {
          this.getLead();
          this.toastr.success('Operazione effettuata con successo', 'Stato aggiornato');
        }, error => {
          this.errorHandler.handleError(error);
        }
      );
  }

  deleteLead(): void {
    // set status to delete
    this.rowLead.status = 'delete';
    this.api.editLead(this.rowLead)
      .subscribe(
        success => {
          this.getLead();
          this.toastr.success('Operazione effettuata con successo', 'Stato aggiornato');
        }, error => {
          this.errorHandler.handleError(error);
        }
      );
  }

  go(key) {
    this.router.navigate(['home', 'user-profile', key]);
  }

  downloadPDF(): void {
    this.api.getLeadPDF(this.leadId).subscribe(
      data => {
        var blob = new Blob([data], { type: 'application/pdf' });
        saveAs(blob, `eleads_${this.lead.name}_${this.lead.surname}.pdf`);
      })
  }

}
