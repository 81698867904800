import { Component, OnInit } from '@angular/core';
import { SidenavItem } from '../sidenav/sidenav-item/sidenav-item.model';
import * as fromRoot from '../../reducers/index';
import * as fromSidenav from '../sidenav/shared/sidenav.action';
import { Store } from '@ngrx/store';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized, } from '@angular/router';
import { SetCurrentlyOpenByRouteAction } from '../sidenav/shared/sidenav.action';
import { SelectLayoutAction, SetCardElevationAction } from '../layout/shared/layout.action';
import { ApiService } from '../../services/api.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
    selector: 'vr-route-handler',
    templateUrl: './route-handler.component.html',
    styleUrls: ['./route-handler.component.scss']
})
export class RouteHandlerComponent implements OnInit {

    initFailed: boolean = false;

    constructor(
        private store: Store<fromRoot.State>,
        private router: Router,
        private route: ActivatedRoute,
        private api: ApiService,
        private firebaseAuth: AngularFireAuth
    ) { }

    ngOnInit() {
        const routesArray = [];
        // Set Sidenav Currently Open on Page load
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.store.dispatch(new SetCurrentlyOpenByRouteAction(event.urlAfterRedirects));
            }
        });

        this.store.dispatch(new SelectLayoutAction('beta'));
        // Define Menu Items here

        // Top Level Item (The item to click on so the dropdown opens)
        routesArray['dashboard'] = new SidenavItem({
            name: 'Dashboard',
            icon: 'dashboard',
            route: '/home',
            position: 1
        });

        routesArray['preferences'] = new SidenavItem({
            name: 'Preferenze',
            icon: 'star',
            route: '/home/user-preferences',
            position: 1
        });

        routesArray['snippet'] = new SidenavItem({
            name: 'Il tuo snippet',
            icon: 'code',
            route: '/home/edit-snippet',
            position: 1
        });

        routesArray['unsold'] = new SidenavItem({
            name: 'Lead invenduti',
            icon: 'person',
            route: '/home/affiliate-unsold',
            position: 1
        });

        routesArray['feedback'] = new SidenavItem({
            name: 'Contattaci',
            icon: 'feedback',
            route: '/home/feedback',
            position: 1
        });

        routesArray['payout'] = new SidenavItem({
            name: 'Pagamenti',
            icon: 'monetization_on',
            route: '/home/payout',
            position: 1
        });


        routesArray['statistics'] = new SidenavItem({
            name: 'Statistiche',
            icon: 'insert_chart_outlined',
            subItems: [],
            position: 1
        });

        const statisticsSubItems = [
            new SidenavItem({
                name: 'Statistiche affiliati',
                icon: 'bar_chart',
                route: '/home/affiliate-statistics',
                parent: routesArray['statistics'],
                position: 1
            }),
            new SidenavItem({
                name: 'Statistiche consulenti',
                icon: 'show_chart',
                route: '/home/broker-statistics',
                parent: routesArray['statistics'],
                position: 1
            }),
            new SidenavItem({
                name: 'Statistiche preferenze',
                icon: 'map',
                route: '/home/preferences-statistics',
                parent: routesArray['statistics'],
                position: 1
            }),
            new SidenavItem({
                name: 'Statistiche finanziari',
                icon: 'euro_symbol',
                route: '/home/finance/statistics',
                parent: routesArray['statistics'],
                position: 1
            }),
            new SidenavItem({
                name: 'Statistiche Utilities',
                icon: 'euro_symbol',
                route: '/home/other/statistics',
                parent: routesArray['statistics'],
                position: 1
            }),
        
            new SidenavItem({
                name: 'Statistiche NLT',
                icon: 'euro_symbol',
                route: '/home/nlt/statistics',
                parent: routesArray['statistics'],
                position: 1
            }),
        
            new SidenavItem({
                name: 'Statistiche Mutui',
                icon: 'euro_symbol',
                route: '/home/loan/statistics',
                parent: routesArray['statistics'],
                position: 1
            })
        ];

        routesArray['statistics'].subItems.push(...statisticsSubItems);

        routesArray['search'] = new SidenavItem({
            name: 'Ricerca Libera',
            route: '/home/advanced-search',
            icon: 'zoom_in',
            parent: routesArray['search'],
            position: 1
        })

        /* const searchSubItems = [
          new SidenavItem({
            name: 'Veloce',
            route: '/home/quick-search',
            icon: 'timer',
            parent: routesArray['search'],
            position: 1
          }),
          new SidenavItem({
            name: 'Avanzata',
            route: '/home/advanced-search',
            icon: 'zoom_in',
            parent: routesArray['search'],
            position: 1
          })
        ];
    
        routesArray['search'].subItems.push(...searchSubItems);
     */
        routesArray['sysadmin-leads'] = new SidenavItem({
            name: 'Gestisci i leads insurance',
            route: '/home/all-leads',
            icon: 'person',
            subItems: [],
            position: 1
        });

        const sysLeadsSubItems = [
            new SidenavItem({
                name: 'Tutti i leads',
                route: '/home/all-leads',
                icon: 'list',
                parent: routesArray['sysadmin-leads'],
                position: 1
            }),
            new SidenavItem({
                name: 'Leads venduti',
                route: '/home/sold-leads',
                icon: 'attach_money',
                parent: routesArray['sysadmin-leads'],
                position: 2
            }),
            new SidenavItem({
                name: 'Leads in vendita',
                route: '/home/selling-leads',
                icon: 'face',
                parent: routesArray['sysadmin-leads'],
                position: 2
            }),
            new SidenavItem({
                name: 'Leads invenduti',
                route: '/home/not-bought-leads',
                icon: 'remove_shopping_cart',
                parent: routesArray['sysadmin-leads'],
                position: 3
            }),
            new SidenavItem({
                name: 'Leads in fase di verifica',
                route: '/home/invalid-leads',
                icon: 'report_problem',
                parent: routesArray['sysadmin-leads'],
                position: 4
            }),
            new SidenavItem({
                name: 'Leads invalidi',
                route: '/home/deleted-leads',
                icon: 'money_off',
                parent: routesArray['sysadmin-leads'],
                position: 4
            }),
            new SidenavItem({
                name: 'Richieste di rimborso',
                route: '/home/refunding-leads',
                icon: 'feedback',
                parent: routesArray['sysadmin-leads'],
                position: 5
            }),
            new SidenavItem({
                name: 'In verifica SMS',
                route: '/home/verify-leads',
                icon: 'textsms',
                parent: routesArray['sysadmin-leads'],
                position: 6
            }),
            new SidenavItem({
                name: 'Non verificati',
                route: '/home/not-verified-leads',
                icon: 'speaker_notes_off',
                parent: routesArray['sysadmin-leads'],
                position: 7
            })
        ];


        routesArray['sysadmin-leads'].subItems.push(...sysLeadsSubItems);

        routesArray['sysadmin-leads-finance'] = new SidenavItem({
            name: 'Gestisci i leads finanziari',
            route: '/home/all-leads',
            icon: 'person',
            subItems: [],
            position: 1
        });

        const sysLeadsFinanceSubItems = [
            new SidenavItem({
                name: 'Tutti i leads',
                route: '/home/finance/all-leads',
                icon: 'list',
                parent: routesArray['sysadmin-leads-finance'],
                position: 1
            }),
            new SidenavItem({
                name: 'Leads venduti',
                route: '/home/finance/bought-leads',
                icon: 'attach_money',
                parent: routesArray['sysadmin-leads-finance'],
                position: 2
            }),
            new SidenavItem({
                name: 'In verifica SMS',
                route: '/home/finance/SMS-leads',
                icon: 'textsms',
                parent: routesArray['sysadmin-leads-finance'],
                position: 6
            }), 
            new SidenavItem({
                name: 'Leads invalidi',
                route: '/home/finance/invalid-leads',
                icon: 'money_off',
                parent: routesArray['sysadmin-leads-finance'],
                position: 7
            }),
            new SidenavItem({
                name: 'Privacy da accettare',
                route: '/home/finance/privacy-leads',
                icon: 'remove_red_eye',
                parent: routesArray['sysadmin-leads-finance'],
                position: 8
            }),
        ];

        routesArray['sysadmin-leads-finance'].subItems.push(...sysLeadsFinanceSubItems);

        
        routesArray['sysadmin-leads-other'] = new SidenavItem({
            name: 'Gestisci i leads utilities',
            route: '/home/other/all-leads',
            icon: 'person',
            subItems: [],
            position: 1
        });
        
        const sysLeadsOtherSubItems = [
            new SidenavItem({
                name: 'Tutti i leads',
                route: '/home/other/all-leads',
                icon: 'list',
                parent: routesArray['sysadmin-leads-other'],
                position: 1
            })
        ];

        routesArray['sysadmin-leads-other'].subItems.push(...sysLeadsOtherSubItems);

        routesArray['sysadmin-leads-nlt'] = new SidenavItem({
            name: 'Gestisci i leads NLT',
            route: '/home/all-leads',
            icon: 'person',
            subItems: [],
            position: 1
        });

        const sysLeadsNLTSubItems = [
            new SidenavItem({
                name: 'Tutti i leads',
                route: '/home/nlt/all-leads',
                icon: 'list',
                parent: routesArray['sysadmin-leads-nlt'],
                position: 1
            }),
            new SidenavItem({
                name: 'Leads venduti',
                route: '/home/nlt/bought-leads',
                icon: 'attach_money',
                parent: routesArray['sysadmin-leads-nlt'],
                position: 2
            }),
            new SidenavItem({
                name: 'In verifica SMS',
                route: '/home/nlt/SMS-leads',
                icon: 'textsms',
                parent: routesArray['sysadmin-leads-nlt'],
                position: 6
            }), 
            new SidenavItem({
                name: 'Leads invalidi',
                route: '/home/nlt/invalid-leads',
                icon: 'money_off',
                parent: routesArray['sysadmin-leads-nlt'],
                position: 7
            })
        ];

        routesArray['sysadmin-leads-nlt'].subItems.push(...sysLeadsNLTSubItems);


        routesArray['sysadmin-leads-loan'] = new SidenavItem({
            name: 'Gestisci i leads mutui',
            route: '/home/all-leads',
            icon: 'person',
            subItems: [],
            position: 1
        });

        const sysLeadsLoanSubItems = [
            new SidenavItem({
                name: 'Tutti i leads',
                route: '/home/loan/all-leads',
                icon: 'list',
                parent: routesArray['sysadmin-leads-loan'],
                position: 1
            }),
            new SidenavItem({
                name: 'Leads venduti',
                route: '/home/loan/bought-leads',
                icon: 'attach_money',
                parent: routesArray['sysadmin-leads-loan'],
                position: 2
            }),
            new SidenavItem({
                name: 'In verifica SMS',
                route: '/home/loan/SMS-leads',
                icon: 'textsms',
                parent: routesArray['sysadmin-leads-loan'],
                position: 6
            }), 
            new SidenavItem({
                name: 'Leads invalidi',
                route: '/home/loan/invalid-leads',
                icon: 'money_off',
                parent: routesArray['sysadmin-leads-loan'],
                position: 7
            })
        ];

        routesArray['sysadmin-leads-loan'].subItems.push(...sysLeadsLoanSubItems);

        routesArray['leads'] = new SidenavItem({
            name: 'Leads',
            icon: 'person',
            subItems: [],
            position: 1
        });

        const leadsSubItems = [
            new SidenavItem({
                name: 'Leads da acquistare',
                route: '/home/quick-search',
                icon: 'person_add',
                parent: routesArray['leads'],
                position: 1
            }),
            new SidenavItem({
                name: 'Leads acquistati',
                route: '/home/bought-leads',
                icon: 'face',
                parent: routesArray['leads'],
                position: 1
            }),
             new SidenavItem({
                name: 'Leads rc auto acquistati',
                route: '/home/car-leads-bought',
                icon: 'directions_car',
                parent: routesArray['leads'],
                position: 1
            }),
            new SidenavItem({
                name: 'Richieste di rimborso in corso',
                route: '/home/refund-leads',
                icon: 'feedback',
                parent: routesArray['leads'],
                position: 1
            })
        ];

        routesArray['leads'].subItems.push(...leadsSubItems);

        routesArray['users'] = new SidenavItem({
            name: 'Gestisci utenti',
            icon: 'group',
            subItems: [],
            position: 1
        });

        const userSubItems = [
            new SidenavItem({
                name: 'Nuovo utente',
                route: '/home/new-user',
                icon: 'person_add',
                parent: routesArray['users'],
                position: 1
            }),
            new SidenavItem({
                name: 'Lista utenti',
                route: '/home/users',
                icon: 'person',
                parent: routesArray['users'],
                position: 1
            }),
            new SidenavItem({
                name: 'Lista utenti finanziari',
                route: '/home/finance/users',
                icon: 'perm_identity',
                parent: routesArray['users'],
                position: 1
            }),
            new SidenavItem({
                name: 'Lista utenti NLT',
                route: '/home/nlt/users',
                icon: 'perm_identity',
                parent: routesArray['users'],
                position: 1
            }),
            new SidenavItem({
                name: 'Lista utenti Mutui',
                route: '/home/loan/users',
                icon: 'perm_identity',
                parent: routesArray['users'],
                position: 1
            })
        ];

        routesArray['users'].subItems.push(...userSubItems);


        // Top Level Item (The item to click on so the dropdown opens)


        routesArray['promo'] = new SidenavItem({
            name: 'Sconti e promozioni',
            icon: 'euro_symbol',
            subItems: [],
            position: 1
        });

        const promoSubItems = [
            new SidenavItem({
                name: 'Promozioni',
                icon: 'local_atm',
                route: '/home/promotion',
                parent: routesArray['promo'],
                position: 1
            }),
            new SidenavItem({
                name: 'Coupon',
                icon: 'redeem',
                route: '/home/coupon',
                parent: routesArray['promo'],
                position: 1
            }),
            new SidenavItem({
                name: 'Sconti',
                icon: 'loyalty',
                route: '/home/discount',
                parent: routesArray['promo'],
                position: 1
            })
        ];

        routesArray['promo'].subItems.push(...promoSubItems);

        routesArray['pricing-table'] = new SidenavItem({
            name: 'Scheda tariffaria',
            icon: 'assignment',
            route: '/home/pricing-table',
            position: 1
        });

        routesArray['history'] = new SidenavItem({
            name: 'Lista movimenti',
            icon: 'access_time',
            route: '/home/history',
            position: 1
        });

        routesArray['referral'] = new SidenavItem({
            name: 'Invita un collega',
            icon: 'favorite',
            route: '/home/referral',
            position: 1
        });

        this.InitRoutes(routesArray);


        this.router.events.subscribe((val) => {
            if (val instanceof RoutesRecognized && this.initFailed) {
                this.InitRoutes(routesArray);
            }

        });

    }

    InitRoutes(routes) {
        var userType = localStorage.getItem('UserType');
        var route = location.href;


        if (userType) {
            this.PutElements(userType, routes);
        } else {
            this.firebaseAuth.authState.subscribe(res => {
                if (res && res.uid) {
                    this.api.getUser()
                        .subscribe(user => {
                            this.initFailed = false;

                            localStorage.setItem('UserType', user.userType);
                            this.PutElements(user.userType, routes);
                        },
                            error => {
                                this.initFailed = true;
                            });
                } else {
                    this.initFailed = true;
                }
            });

        }


    }

    PutElements(userType, routes) {
        switch (userType) {
            case 'admin':
                // Send the created Menu structure to Redux/ngrx (you only need to send the Top Level Item, all dropdown items will be added automatically)
                /* 
                 this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['preferences']));
                 this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['search']));
                 this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['leads'])); */
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['dashboard']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['statistics']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['sysadmin-leads-finance']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['sysadmin-leads-other']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['sysadmin-leads']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['sysadmin-leads-loan']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['sysadmin-leads-nlt']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['users']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['payout']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['promo']));

                break;

            case 'user':
                // Send the created Menu structure to Redux/ngrx (you only need to send the Top Level Item, all dropdown items will be added automatically)
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['dashboard']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['preferences']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['leads']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['search']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['feedback']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['history']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['pricing-table']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['referral']));

                break;

            case 'affiliate':
                // Send the created Menu structure to Redux/ngrx (you only need to send the Top Level Item, all dropdown items will be added automatically)
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['dashboard']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['snippet']));
                //this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['unsold']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['payout']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['feedback']));

                break;

            default:
                // Send the created Menu structure to Redux/ngrx (you only need to send the Top Level Item, all dropdown items will be added automatically)
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['dashboard']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['preferences']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['leads']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['search']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['feedback']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['history']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['pricing-table']));
                this.store.dispatch(new fromSidenav.AddSidenavItemAction(routes['referral']));

                break;
        }
    }

}
