import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../../services/api.service'
import { ErrorHandlerService } from '../../../services/error-handler.service'
import { MatDialog, MatDialogConfig } from '@angular/material';
import { LoanModalBuyerComponent } from '../loan-modal-buyer/loan-modal-buyer.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'vr-loan-buyers',
  templateUrl: './loan-buyers.component.html',
  styleUrls: ['./loan-buyers.component.scss']
})
export class LoanBuyersComponent implements OnInit {

  buyers: any = [];
  loading: boolean = false;

  constructor(
    private api: ApiService,
    private errorHandler: ErrorHandlerService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private toaster: ToastrService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.getBuyers();
  }

  getBuyers() {
    this.api.getLoanBuyer()
      .subscribe(data => {
        this.buyers = data;
        this.loading = false;
        this.cd.detectChanges();
      }, error => {
        this.loading = false;
        this.errorHandler.handleError(error);
        this.cd.detectChanges();
      })
  }

  changeStatus(buyer, status) {
    switch (status) {
      case 'active':
        buyer.pause = false;
        break;

      case 'pause':
        buyer.pause = true;
        break;

      default:
        break;
    }

    this.api.putLoanBuyer(buyer.key, buyer)
      .subscribe(data => {
        this.toaster.success("Stato Buyer aggiornato");
      }, error => {
        this.errorHandler.handleError(error)
      });
  }


  /**
   * Open an arbitrary confirm dialog according with button action data
   * @param $event the event triggered
   */
  openBuyer(buyer?): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '75%';
    dialogConfig.data = (buyer)?buyer:{};

    const dialogRef = this.dialog.open(LoanModalBuyerComponent, dialogConfig)
      .afterClosed()
      .subscribe(data => {
        if (data) {
          this.getBuyers();
        }

      });
  }


}
