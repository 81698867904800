// src/app/auth/auth-guard.service.ts
import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild } from '@angular/router';
import { AuthService } from './auth.service';
@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {

  constructor(public auth: AuthService, public router: Router) { }
  
  canActivate(): any {
    return this.checkActivate(false);
  }
  canActivateChild(): any {
    return this.checkActivate(true);
  }

  checkActivate(isChild: boolean): Promise<boolean> {
    return new Promise((resolve, _) => {
      this.auth.isAuthenticated()
        .then(() => {
          this.auth.getToken()
            .then((token) => {
              localStorage.setItem("JWT_TOKEN_ELEADS", String(token));
              resolve(true);
            })
        })
        .catch(() => {
          this.auth.logout().then(
            success => {
              localStorage.removeItem('JWT_TOKEN_ELEADS');
              this.router.navigate(['/']);
              //window.location.reload();
            }
          )
          resolve(false);
        });
    })
  }
}