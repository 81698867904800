import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service'
import { ErrorHandlerService } from '../../services/error-handler.service';
import { PreferencesMapComponent } from '../../core/preferences-map/preferences-map.component';

@Component({
  selector: 'vr-preferences-statistics',
  templateUrl: './preferences-statistics.component.html',
  styleUrls: ['./preferences-statistics.component.scss']
})
export class PreferencesStatisticsComponent implements OnInit {
  loading:boolean;
  users: any = []; // the user list
  params: any; // the search params
  perPage = 50; // pp conf
  pageNumber = 0; // pn conf

  showNext = false; // true if we can go to next page
  showPrev = false; // true if we can go to the previous one
  query = '';

  constructor(
    private api: ApiService,
    private ref: ChangeDetectorRef,
    private errorHandler: ErrorHandlerService,
    private router: Router
  ) { }

  ngOnInit() {
    this.params = {
      perPage: this.perPage,
      pageNumber: this.pageNumber,
      query: this.query
    }

    this.search(this.params);
  }

  search(params: any): void {
    this.loading = true; // we are loading
    this.ref.detectChanges();
    this.api.getDistrictPreferences(params).subscribe(
      data => {
        this.users = data ;
        
        this.onAfterSearch();
      },
      error => {
        this.errorHandler.handleError(error);
        this.onAfterSearch();
      });
  }

  filter(){
    this.params = {
      perPage: this.perPage,
      pageNumber: this.pageNumber,
      query: this.query
    }

    this.search(this.params);
  }

    /**
   * Perform an action binded to another component
   * @param action the action to perform
   */
  getAction(action) {
    switch (action) {
      case "nextPage":
        if (this.loading) {
          return;
        }
        this.params.pageNumber++;
        this.search(this.params);
        break;

      case "prevPage":
        if (this.loading) {
          return;
        }
        this.params.pageNumber--;
        this.search(this.params);
        break;

      default:
        break;
    }

    
  }

  /**
   * handle after search behaviours
   */
  onAfterSearch(): void {
    this.loading = false; // stop loading

    // if no users is returned
    if (!this.users || !this.users.length) {
      return;
    }

    // TODO: this behaviour have some bugs (e.g. user.length % perPage = 0)
    if (this.users.length < this.perPage) {
      this.showNext = false;
    } else {
      this.showNext = true;
    }

    // cannot go prev
    if (this.params.pageNumber === 0) {
      this.showPrev = false;
    } else {
      this.showPrev = true;
    }
    // let the graphic detect changes
    this.ref.detectChanges();
  }

  
  go(key) {
    this.router.navigate(['home', 'user-profile', key]);
  }

}
