
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs'
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);
  notSupported: boolean = false;
  constructor(
    private toastr: ToastrService,
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging) {
    try {
      this.angularFireMessaging.messaging.subscribe(
        (_messaging) => {
          _messaging.onMessage = _messaging.onMessage.bind(_messaging);
          _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        }, error => {
          this.notSupported = true;
        }
      )
    } catch(err){
      this.notSupported = true;

    }
  }

  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        this.angularFireDB.list('fcmTokens/' + userId).push(token);
        this.toastr.success('<i>Notifiche attivate su questo dispositivo!</i>', 'Notifiche attive');
      })
  }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission(userId) {
    if(this.notSupported){
      this.toastr.error('<i>Questo dispositivo sembra non supportare le notifiche push da browser</i>', 'Errore');
      return;
    }


    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.updateToken(userId, token);
        localStorage.setItem('notifications', 'true');
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
        this.toastr.error('<i>Qualcosa è andato storto</i>', 'Errore');
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        this.currentMessage.next(payload);
      })
  }
}
