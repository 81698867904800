import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../services/api.service'
import { ToastrService } from 'ngx-toastr';
import { Lead } from '../../models/lead';
import { ErrorHandlerService } from '../../services/error-handler.service';
@Component({
  selector: 'vr-not-bought-list',
  templateUrl: './not-bought-list.component.html',
  styleUrls: ['./not-bought-list.component.scss']
})
export class NotBoughtListComponent implements OnInit {

  leads: Lead[];
  perPage: number = 30;
  pageNumber: number = 0;
  loading:boolean = false;
  params: any;

  constructor(
    private api: ApiService,
    private toastr: ToastrService,
    private ref: ChangeDetectorRef,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit() {
    this.params = {
      perPage: this.perPage,
      pageNumber: this.pageNumber,
      status: "unsold"
    }
    this.search(this.params);
  }

  search(leadsParams) {
    this.loading = true;
    this.api.getAllLeads(leadsParams).subscribe(
      data => {
        this.leads = data as Lead[];
        this.loading = false;
        this.ref.detectChanges();
      },
      error => {
        this.loading = false;
        this.errorHandler.handleError(error);
      });
  }

  /**
   * Filter on filter passed
   */
  filter(action): void {
    this.params = {
      perPage: this.params.perPage,
      pageNumber: this.params.pageNumber
    }
    Object.assign(this.params,action.filters);
    this.search(this.params);
  }

  getAction(action) {
    
    switch (action) {
      case "nextPage":
        this.params.pageNumber++;
        this.search(this.params);
        break;

      case "prevPage":
        this.params.pageNumber--;
        this.search(this.params);
        break;

      case 'update':
        this.search(this.params);
        break;

      default:
        
        break;
    }

    
  }

}
