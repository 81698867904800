import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { ApiService } from '../../services/api.service'
import { ToastrService } from 'ngx-toastr';
import { Lead } from '../../models/lead';
import { Router, NavigationExtras } from '@angular/router';
import { ErrorHandlerService } from '../../services/error-handler.service';

@Component({
  selector: 'vr-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.scss'],
})
export class QuickSearchComponent implements OnInit {

  @Input() embed?: boolean = false;
  @Input() maxItems?: number;

  leads: Lead[];
  perPage: number = 30;
  pageNumber: number = 0;
  hasPreferences: boolean = true;
  preferences: any;
  loading:boolean = false;
  constructor(
    private router: Router,
    private api: ApiService,
    private toastr: ToastrService,
    private ref: ChangeDetectorRef,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit() {
    this.getPreferences();
    this.leads = [];
  }

  getPreferences() {
    this.api.getPreferences()
      .subscribe(
        data => {
          this.preferences = data;

          this.setSearch(this.preferences);

        },
        error => {
          this.errorHandler.handleError(error);
        });
  }

  setSearch(params) {
    if (!params) {
      this.leads = [];
      this.hasPreferences = false;
      this.ref.detectChanges();
      return;
    }
    params.perPage = this.maxItems ? this.maxItems : this.perPage;
    params.pageNumber = this.pageNumber;

    this.search(params);
  }

  search(leadsParams) {
    this.loading = true;
    this.api.setLeadSearch(leadsParams).subscribe(
      data => {

        if (Object.keys(data).length == 0) {
          this.leads = null;
        } else {
          this.leads = data as Lead[];
        }

        this.loading = false;
        this.ref.detectChanges();
      },
      error => {
        this.errorHandler.handleError(error);
        this.loading = false;
      });

  }

  getAction(action) {
    
    switch (action) {
      case "nextPage":
        this.preferences.pageNumber++;
        this.search(this.preferences);
        break;

      case "prevPage":
        this.preferences.pageNumber--;
        this.search(this.preferences);
        break;

      case 'update':
        this.search(this.preferences);
        break;

      default:
        
        break;
    }

  }

  go(to: Array<string>, queryParams?: NavigationExtras) {
    this.router.navigate(to, queryParams || {});
  }

}
