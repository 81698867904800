import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorHandlerService {

  constructor(
    private toastr: ToastrService
  ) { }

  /**
   * Handle error
   * @param error the HTTPError response
   */
  handleError(error: HttpErrorResponse, type: string = '') {

    switch (type) {
      case 'lead':
        switch (error.status) {
          case 500:
            this.toastr.error('<i>Se il problema persiste contattare il supporto.</i>', 'C\'è stato qualche problema');
            break;

          case 403:
            this.toastr.error('<i>Sembra che il lead sia stato venduto.</i>', 'Lead venduto');
            break;

          case 400:
            this.toastr.error('<i>I dati inseriti non sono validi.</i>', 'Dati errati');
            break;

          default:
            this.toastr.error('<i>Si è verificato un errore durante l\'operazione.</i>', 'Errore');
            break;
        }
        break;

      case 'coupon':
        switch (error.status) {
          case 500:
            this.toastr.error('<i>Se il problema persiste contattare il supporto.</i>', 'C\'è stato qualche problema');
            break;

          case 403:
            this.toastr.error('<i>Sembra che il lead sia stato venduto.</i>', 'Lead venduto');
            break;

            case 400:
            this.toastr.error('<i>I dati inseriti non sono validi.</i>', 'Dati errati');
            break;

            case 401:
            this.toastr.error('<i>Puoi riscattare questo coupon una volta sola</i>', 'Coupon già riscattato');
            break;

            case 402:
            this.toastr.error('<i>Mi spiace, questo coupon sembra essere scaduto</i>', 'Coupon scaduto');
            break;

          default:
            this.toastr.error('<i>Si è verificato un errore durante l\'operazione.</i>', 'Errore');
            break;
        }
        break;


      default:
        switch (error.status) {
          case 500:
            this.toastr.error('<i>Se il problema persiste contattare il supporto.</i>', 'C\'è stato qualche problema');
            break;

          case 403:
            this.toastr.error('<i>Sembra che il tuo account non disponga dei permessi per effettuare questa operazione.</i>', 'Permessi insuffucenti');
            break;

          case 400:
            this.toastr.error('<i>I dati inseriti non sono validi.</i>', 'Dati errati');
            break;

          default:
            this.toastr.error('<i>Si è verificato un errore durante l\'operazione.</i>', 'Errore');
            break;
        }
        break;
    }

  }
}
