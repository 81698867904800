import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { ApiService } from '../../services/api.service'
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { Lead } from '../../models/lead';

@Component({
  selector: 'vr-affiliate-unsold',
  templateUrl: './affiliate-unsold.component.html',
  styleUrls: ['./affiliate-unsold.component.scss']
})
export class AffiliateUnsoldComponent implements OnInit {

  @Input() embed: boolean = false;
  @Input() maxItems: number;

  leads: any;
  sort: string = "time_selling";
  order: string = "asc";
  query: string;
  perPage: number = 30;
  pageNumber: number = 0;

  params: any;

  constructor(
    private api: ApiService,
    private toastr: ToastrService,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.params = {
      perPage: this.maxItems ? this.maxItems : this.perPage,
      pageNumber: this.pageNumber,
      query: this.query,
      sort: this.sort,
      order: this.order
    }
    this.search(this.params);
  }

  filter() {
    this.params = {
      perPage: this.maxItems ? this.maxItems : this.perPage,
      pageNumber: this.pageNumber,
      query: this.query,
      sort: this.sort,
      order: this.order
    }
    this.search(this.params);
  }

  search(leadsParams) {
    this.api.getAffiliateUnsold(leadsParams).subscribe(
      data => {
        this.leads = data;
        this.ref.detectChanges();
      },
      error => {
        switch (error.status) {
          case 500:
            this.toastr.error('<i>Se il problema persiste contattare il supporto.</i>', 'C\'è stato qualche problema');
            break;

          case 403:
            this.toastr.error('<i>Sembra che il tuo account non disponga dei permessi per effettuare questa operazione.</i>', 'Permessi insuffucenti');
            break;

          case 400:
            this.toastr.error('<i>I dati inseriti non sono validi.</i>', 'Dati errati');
            break;

          default:
            break;
        }
      });

  }

  getAction(action) {
    switch (action) {
      case "nextPage":
        this.params.pageNumber++;
        this.search(this.params);
        break;

      case "prevPage":
        this.params.pageNumber--;
        this.search(this.params);
        break;

      case 'update':
        this.search(this.params);
        break;

      default:
        break;
    }
  }

  downloadCSV() {
    this.api.getAffiliateUnsoldCSV().subscribe(
      data => {
        var blob = new Blob([data], { type: 'text/csv' });
        saveAs(blob, "leads.csv");
      })
  }
}
