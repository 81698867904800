import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'vr-refun-lead',
  templateUrl: './refun-lead.component.html',
  styleUrls: ['./refun-lead.component.scss']
})
export class RefunLeadComponent implements OnInit {

  public comment: string;
  public motivation: string;

  constructor(
    private dialogRef: MatDialogRef<RefunLeadComponent>,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
  }

  save() {
    if (this.motivation && this.comment) {

      this.dialogRef.close({
        comments: this.comment,
        motivation: this.motivation
      });

    }
    else{
      this.toastr.error('<i>Motivazione e commento sono obbligatori</i>', 'Inserire i dati richiesti');
    }
  }

  close() {
    this.dialogRef.close();
  }

}
