import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../../services/api.service'
import { Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ChargeCreditCardComponent } from '../../../pages/charge-credit-card/charge-credit-card.component';
import { ModalRedeemCouponComponent } from '../../../pages/modal-redeem-coupon/modal-redeem-coupon.component';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { Subscription } from 'rxjs';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators'
import { User } from '../../../models/user'

@Component({
  selector: 'vr-toolbar-beta',
  templateUrl: './toolbar-beta.component.html',
  styleUrls: ['./toolbar-beta.component.scss']
})
export class ToolbarBetaComponent implements OnInit {

  @Input() quickpanelOpen: boolean;

  @Output() toggledSidenav = new EventEmitter();
  @Output() toggledQuickpanel = new EventEmitter();

  user: any;
  subscriptions: Subscription[] = [];
  promotion: any;
  discount: any;
  diff: any = {};
  diff_discount: any = {};
  onChargePlus: boolean = false;
  onChargeMinus: boolean = false;
  waitForToggleSideNav: boolean = false; // wait for re toggle

  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
    private dialog: MatDialog,
    private api: ApiService,
    private userService: UserService
  ) { }


  ngOnInit() {
    this.api.getUser()
      .subscribe(data => {
        this.user = data;
        if (!this.user.wallet) {
          this.user.wallet = 0;
        }

        if(this.user.userType=='user'){
          this.checkPromotions();
          this.checkDiscounts();
        }

      });

    this.subscriptions.push(this.userService.change.subscribe(_user => {
      this.onAfterChargeCreditCard(_user as User);
    }));

    this.subscriptions.push(this.toggledSidenav.subscribe(
      _ => {
        setTimeout(
          () => {
            this.waitForToggleSideNav = false;
          }, 1000
        )
      },
      error => { debugger; }
    ));
  }

  /**
   * Handle credit card charge, update frontend el
   * ements
   * @param _user the new user data
   */
  onAfterChargeCreditCard(_user: User) {
    this.onChargePlus = _user.wallet > this.user.wallet;
    this.onChargeMinus = _user.wallet < this.user.wallet;
    this.user = Object.assign({}, _user);
    this.cd.detectChanges();
    this.checkDiscounts();
    setTimeout(() => {
      this.onChargePlus = false;
      this.onChargeMinus = false;
      this.cd.detectChanges();
    }, 500);
  }

  checkPromotions() {
    this.api.activePromotion()
      .subscribe(data => {
        this.promotion = data[0];

        if (data[0] && !this.promotion.end) {

          interval(1000).pipe(
            map((x) => {
              var seconds = Math.floor((new Date(this.promotion.dateEnd).getTime() - new Date().getTime()) / 1000);
              this.diff.days = Math.trunc(seconds / 60 / 60 / 24);
              this.diff.hours = Math.trunc(seconds / 60 / 60) - this.diff.days * 24;
              this.diff.minutes = Math.trunc(seconds / 60) - (this.diff.days * 24 * 60 + this.diff.hours * 60);
              this.diff.seconds = seconds - (this.diff.days * 24 * 60 * 60 + this.diff.hours * 60 * 60 + this.diff.minutes * 60);

              this.diff.time =  this.diff.days+'g '+ this.diff.hours+'h '+ this.diff.minutes+'m '+ this.diff.seconds+'s ';
              this.cd.detectChanges();
              return x;
            })).subscribe();

        }
      })
  }


  checkDiscounts() {
    this.api.activeDiscount()
      .subscribe(data => {
        this.discount = data[0];

        if (data[0]) {

          interval(1000).pipe(
            map((x) => {
              var seconds = Math.floor((new Date(this.discount.dateEnd).getTime() - new Date().getTime()) / 1000);
              this.diff_discount.days = Math.trunc(seconds / 60 / 60 / 24);
              this.diff_discount.hours = Math.trunc(seconds / 60 / 60) - this.diff_discount.days * 24;
              this.diff_discount.minutes = Math.trunc(seconds / 60) - (this.diff_discount.days * 24 * 60 + this.diff_discount.hours * 60);
              this.diff_discount.seconds = seconds - (this.diff_discount.days * 24 * 60 * 60 + this.diff_discount.hours * 60 * 60 + this.diff_discount.minutes * 60);

              this.diff_discount.time =  this.diff_discount.days+'g '+ this.diff_discount.hours+'h '+ this.diff_discount.minutes+'m '+ this.diff_discount.seconds+'s ';
              this.cd.detectChanges();
              return x;
            })).subscribe();

        }
      })
  }


  toggleSidenav() {
    if (this.waitForToggleSideNav) {
      return;
    }
    this.waitForToggleSideNav = true;

    this.toggledSidenav.emit();
  }

  toggleQuickpanel() {
    this.toggledQuickpanel.emit();
  }

  goHome() {
    this.router.navigate(['home']);
  }

  chargeCard(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';

    dialogConfig.data = {};
    const dialogRef = this.dialog.open(ChargeCreditCardComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      // notify user service than something has changed
      if (data && data !== this.user.wallet) {
        this.user.wallet = this.user.wallet ? this.user.wallet + data : data;
      }

      this.userService.onChange(this.user);
    });
  }

  redeemCoupon(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '30%';

    dialogConfig.data = {};
    const dialogRef = this.dialog.open(ModalRedeemCouponComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      // notify user service than something has changed
      if (data && data !== this.user.wallet) {
        this.user.wallet = this.user.wallet ? this.user.wallet + data : data;
      }

      this.userService.onChange(this.user);
    });
  }

  openPromotion(promo): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';

    dialogConfig.data = {
      title: promo.title,
      text: promo.text,
      showClose: false
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
    
      this.userService.onChange(this.user);
    });
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
