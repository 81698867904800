import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../services/api.service'
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ConfirmDialogComponent } from '../../core/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { ModalCouponComponent } from '../modal-coupon/modal-coupon.component'

@Component({
  selector: 'vr-coupon-list',
  templateUrl: './coupon-list.component.html',
  styleUrls: ['./coupon-list.component.scss']
})
export class CouponListComponent implements OnInit {

  loading: boolean = true;
  coupons: any = [];

  constructor(
    private api: ApiService,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit() {
    this.loadCoupons();
  }

  loadCoupons() {
    this.api.getCoupon().subscribe(
      data => {
        this.coupons = data;
        this.loading = false;
        this.ref.detectChanges();
      },
      error => {
        this.errorHandler.handleError(error);
        this.loading = false;
        this.ref.detectChanges();
      }
    )
  }

  deleteCoupon(coupon): void {
    if (!coupon.key) {
      console.error('No user key defined!');
      return;
    }
    this.loading = true;
    // if user key exists then delete it
    this.api.delteCoupon(coupon.key)
      .subscribe(
        data => {
          this.toastr.success('', 'Coupon eliminato correttamente');
          // let the graphic detect changes
          this.loadCoupons()
        },
        error => {
          this.errorHandler.handleError(error);
          this.loadCoupons();
        })
  }

  //this function will prompt a modal with the modal 
  //to edit the coupon if exists, otherwise it will
  //prompt a create ne coupon modal
  singleCoupon(coupon?:any){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '75%';
    dialogConfig.data = (coupon)?{ id: coupon.key }:{};

    const dialogRef = this.dialog.open(ModalCouponComponent, dialogConfig)
      .afterClosed()
      .subscribe(data => {
        if (data) {
          // perform action
          this.loadCoupons();
        } 
      });

  }




  openConfirmDialog($event: Event, coupon): void {
    const dialogConfig = new MatDialogConfig();
    let target: HTMLElement = $event.target as HTMLElement || new HTMLElement(); // the dialog target



    const title: string = target.getAttribute('modal-title') || 'Conferma';
    const text: string = target.getAttribute('modal-text') || 'Si desidera procedere con l\' operazione?';

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    dialogConfig.data = { title, text };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          // perform action
          this.deleteCoupon(coupon);
        } 
      });
  }

  createCoupon($event: Event, coupon): void {
    const dialogConfig = new MatDialogConfig();


    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe(data => {
        if (data) {
          // perform action
         
        } else {
          // do nothing
        }
      });
  }

}
