import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'vr-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  message:any = {};
  disable: boolean = false;

  constructor(
    private api: ApiService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
  }

  save() {
    this.disable = true;
    this.api.sendFeedback(this.message)
    .subscribe(data => {
      this.disable = false;
      this.toastr.success('<i>Ti ricontatteremo a breve</i>', 'Messaggio inviato');
    }, error => {
      this.disable = false;
      this.toastr.error('<i>C\'è stato qualche problema</i>', 'Errore');
    })   
  }

}
