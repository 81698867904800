import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../services/api.service'
import { Lead } from '../../models/lead';
import { ErrorHandlerService } from '../../services/error-handler.service';

@Component({
  selector: 'vr-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit {

  showResults: boolean = false;
  leads: Lead[];
  perPage: number = 30;
  pageNumber: number = 0;
  loading: boolean;

  searchParams: any;

  constructor(
    private api: ApiService,
    private ref: ChangeDetectorRef,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit() {
  }

  setSearch(params) {
    params.perPage = this.perPage;
    params.pageNumber = this.pageNumber;

    this.searchParams = params;
    this.search(this.searchParams);

  }

  search(leadsParams) {
    this.loading = true;
    this.api.setLeadSearch(leadsParams).subscribe(
      data => {

        if (Object.keys(data).length == 0) {
          this.leads = null;
        } else {
          this.leads = data as Lead[];
        }
        this.loading = false;

        this.showResults = true;
        this.ref.detectChanges();
      },
      error => {
        this.errorHandler.handleError(error);
        this.loading = false;
      });

  }

  getAction(action) {
    switch (action) {
      case "nextPage":
        this.searchParams.pageNumber++;
        this.search(this.searchParams);
        break;

      case "prevPage":
        this.searchParams.pageNumber--;
        this.search(this.searchParams);
        break;

      case "showResults":
        this.showResults = false;
        break;

      case 'update':
        this.search(this.searchParams);
        break;

      default:
        break;
    }

  }

}
