import { Component, OnInit, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'vr-modal-contract',
  templateUrl: './modal-contract.component.html',
  styleUrls: ['./modal-contract.component.scss']
})
export class ModalContractComponent implements OnInit {

  accept: any = {};
  disabled: boolean = true;
  User: any = {};
  Errors: any = [];
  constructor(
    private api: ApiService,
    private errorHandler: ErrorHandlerService,
    private dialogRef: MatDialogRef<ModalContractComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.User = data;
  }

  ngOnInit() {
  }

  checkData() {


    if (this.accept.privacy && this.accept.terms) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }

    var regex = RegExp(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i)
    var regex1 = RegExp(/([0-9]{11})/g)
    if (!regex.test(this.User.fiscalCode) && !regex1.test(this.User.fiscalCode)) {
      this.Errors['CF'] = true;
      this.disabled = true;
    } else {
      this.Errors['CF'] = false;
    }

  }

  save() {
    if (!this.disabled) {
      this.api.putUser(this.User).subscribe(success => {
        this.api.acceptContract().subscribe(success => {
          this.dialogRef.close(true);
        }, error => {
          this.errorHandler.handleError(error);
        });
      }, error => {
        this.errorHandler.handleError(error);
      });
    }

  }


  close() {
    this.dialogRef.close(false);
  }

}
