import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';

import { ApiService } from '../../services/api.service'
import { Invoice } from '../../models/invoice';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'vr-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements OnInit {

  @Input() userId: string;
  invoices: Invoice[];
  showNext = true;
  showPrev = false;
  perPage = 50;
  pageNumber = 0;


  params: any;

  loading: boolean = true;

  constructor(
    private api: ApiService,
    private ref: ChangeDetectorRef,
    private errorHandler: ErrorHandlerService
  ) {

    this.params = {
      perPage: this.perPage,
      pageNumber: this.pageNumber
    }
    
    
  }


  ngOnInit() {

    if (this.userId) {
      this.params.uid = this.userId;
    }
    this.search(this.params);
  }

  search(params) {
    this.api.getInvoices(params).subscribe(
      data => {
        this.invoices = data as Invoice[];
        this.loading = false;

        if (this.params.pageNumber == 0) {
          this.showPrev = false;
        } else {
          this.showPrev = true;
        }

        if (this.invoices.length < this.perPage) {
          this.showNext = false;
        } else {
          this.showNext = true;
        }

        this.ref.detectChanges();
      },
      error => {
        if (error.status == 0) {
          this.invoices = [];
          this.loading = false;
          this.ref.detectChanges();
        }
        this.errorHandler.handleError(error);
      });
  }



  getAction(action) {
    
    switch (action) {
      case 'nextPage':
        this.params.pageNumber++;
        this.search(this.params);
        break;

      case 'prevPage':
        this.params.pageNumber--;
        this.search(this.params);
        break;

      default:
        
        break;
    }

    
  }

  downloadPDF(invoice: Invoice): void {
    this.api.getInvoicePDF(invoice.id).subscribe(
      data => {
        var blob = new Blob([data], { type: 'application/pdf' });
        saveAs(blob, `eleads_${invoice.number}.pdf`);
      })
  }

  downloadXML(invoice: Invoice): void {
    this.api.getInvoiceXML(invoice.id).subscribe(
      data => {
        var blob = new Blob([data], { type: 'application/xml' });
        saveAs(blob, `IT02668420025_${invoice.number}.xml`);
      })
  }
}
