export class User {
  key?:string;
  referente?:string;
  account?:string;
  email?: string;
  companyName?: string;
  vatNumber?: string;
  pec?: string;
  fiscalCode?: string;
  address?: string;
  city?: string;
  zip?: string;
  phone?: string;
  userType?: string;
  rui?: string;
  password?: string;
  confirmPassword?: string;
  current?: boolean;
  emailNotifications: boolean = true;
  smsNotifications: boolean = true;
  wallet?: number;
  district?: string;
  referenteLastname?: string;
  referenteFirstname?: string;
  SDINumber?: string;
  contract?: {
    accepted: boolean;
    ip?: string;
    timestamp?: Date;
  };
}