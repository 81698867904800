export const environment = {
  production: false,
  googleMapsApiKey: 'AIzaSyCqhadmQ75-upzI4bb8CymHWgPPcEY80yE',
  apiUrl: 'http://localhost:3000/api/',
  formUrl: 'http://localhost:8080/api/',
  firebase: {
    apiKey: 'AIzaSyAtfA_aeCHmC8vqnOitMZGx1CY1p41kzJg',
    authDomain: 'e-leads-sandbox.firebaseapp.com',
    databaseURL: 'https://e-leads-sandbox.firebaseio.com',
    projectId: 'e-leads-sandbox',
    storageBucket: 'e-leads-sandbox.appspot.com',
    messagingSenderId: '437076071125'
  },
  stripeToken: 'pk_test_idGKbdYOXk3xJo8X5cNYiuXA'
};
