import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'vr-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {
  newUser: User;

  constructor(
    private api: ApiService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.newUser = new User();
  }

  save() {
    if(!this.newUser.account){
      this.newUser.account= this.newUser.companyName;
    }


    this.api.addUser(this.newUser).subscribe(
      data => {
        this.newUser = new User();
        this.toastr.success('', 'Dati salvati correttamente');
      },
      error => {
        switch (error.status) {
          case 500:
            this.toastr.error('<i>Se il problema persiste contattare il supporto.</i>', 'C\'è stato qualche problema');
            break;

          case 403:
            this.toastr.error('<i>Sembra che il tuo account non disponga dei permessi per effettuare questa operazione.</i>', 'Permessi insuffucenti');
            break;

          case 400:
            if(error.error.message=="email-already-exists"){

              this.toastr.error('<i>Indirizzo email appartiene ad un altro utente.</i>', 'Dati errati');
            } else {
              this.toastr.error('<i>I dati inseriti non sono validi.</i>', 'Dati errati');
            }
            break;

          default:
            break;
        }
      });

  }

}

export class User {
  account: string;
  email: string;
  companyName: string;
  vatNumber: string;
  fiscalCode: string;
  address: string;
  city: string;
  zip: string;
  phone: string;
  rui?: string;
  pec?: string;
  SDINumber?: string;
  userType: string;
}
