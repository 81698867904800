import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vr-first-time-guide',
  templateUrl: './first-time-guide.component.html',
  styleUrls: ['./first-time-guide.component.scss']
})
export class FirstTimeGuideComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
